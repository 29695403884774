import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import * as permissions from 'constants/permissions';
import {isAllowed} from 'utilities/authorization';
import objectUtilities from 'utilities/objectUtilities';
// import {bindActionCreators} from 'redux';
import Resources from 'utilities/Resources';
import routes from 'routes';
import 'styles/_navigation.scss';

class AdminNavigation extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.selectedClassName = 'is-selected';

    if (!this.homeRoute) {
      this.homeRoute = routes.home.replace('/:vehicleCode?', '');
    }
  }

  isHomeActive = () => {
    return this.props.match.path === routes.home;
  }

  render() {
    const resources = Resources.localizedString;
    return (
      !objectUtilities.isEmpty(this.props.user) &&
      <div className="navigation-gradient">
        <ul className="primary-navigation" role="navigation">
          {isAllowed(this.props.user, [permissions.RDD_CAN_READ]) &&
            <li className="primary-navigation__navigation-item">
              <NavLink exact={true} isActive={this.isHomeActive} className="primary-navigation__navigation-link" to={this.homeRoute} activeClassName={this.selectedClassName}>{resources.channelData}</NavLink>
            </li>
          }
          {isAllowed(this.props.user, [permissions.RDD_CAN_CONFIGURE]) &&
            <li className="primary-navigation__navigation-item">
              <NavLink className="primary-navigation__navigation-link" to={routes.admin.triggers.index} activeClassName={this.selectedClassName}>{resources.triggers}</NavLink>
            </li>
          }
          {isAllowed(this.props.user, [permissions.RDD_CAN_READ]) &&
            <li className="primary-navigation__navigation-item">
              <NavLink className="primary-navigation__navigation-link" to={routes.admin.presets.index} activeClassName={this.selectedClassName}>{resources.presets}</NavLink>
            </li>
          }
          {isAllowed(this.props.user, [permissions.RDD_CAN_CONFIGURE]) &&
            <li className="primary-navigation__navigation-item">
              <NavLink className="primary-navigation__navigation-link" to={routes.admin.vehicleGroups.index} activeClassName={this.selectedClassName}>{resources.vehicles}</NavLink>
            </li>
          }
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export {AdminNavigation as AdminNavigationNoRedux};

const ConnectedAdminNavigation = connect(mapStateToProps, mapDispatchToProps)(AdminNavigation);
export default withRouter(ConnectedAdminNavigation);
