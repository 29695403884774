import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Resources from 'utilities/Resources';
import * as wheelDiameterActions from 'actions/wheelDiameterActions';
import 'styles/_wheel-diameter.scss';

class WheelDiameter extends React.Component {
  static propTypes = {
    wheelDiameter: PropTypes.number,
    // Unfortunately, I preferred to just change this to a string, even though it is a number, as is was throwing up a warning when the user cleared the
    // text box. The warning wasn't a problem as the user would not be able to save the value but it looked untidy.
    userAdjustedWheelDiameterMillimetres: PropTypes.string,
    wheelDiameterActions: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showWheelDiameterPanel: false,
      updatedWheelDiameter: this.props.wheelDiameter,
      valid: true
    };
  }

  validate = () => {
    const valid = this.state.updatedWheelDiameter && this.state.updatedWheelDiameter > 0 ? true : false;
    this.setState({
      valid
    });
    return valid;
  }

  updateGraphs = () => {
    const valid = this.validate();
    if (valid) {
      this.setState({
        showWheelDiameterPanel: false
      });

      // NOTE(DC): The != (as opposed to !==) is deliberately used here as we are only interested in the numeric values,
      //           not object references.
      if (this.state.updatedWheelDiameter != this.props.wheelDiameter) {
        this.props.wheelDiameterActions.updateUserAdjustedWheelDiameter(this.state.updatedWheelDiameter);
        // action needs to now go and update the redux store with the latest values
        // also need to go and ask for distance values again with the diameter passed in
      } else {
        this.props.wheelDiameterActions.resetUserAdjustedWheelDiameter();
      }
    }
  }

  resetWheelDiameter = () => {
    this.setState({
      showWheelDiameterPanel: false,
      updatedWheelDiameter: this.props.wheelDiameter,
      valid: true
    });
    this.props.wheelDiameterActions.resetUserAdjustedWheelDiameter();
  }

  wheelDiameterChange = (e) => {
    // Using >= as > will not allow the user to delete every digit, e.g. if they press back spaces when contents are 1016, it is realistic
    // that they might want to clear the contents in order to input 995. The validate function will cater for attempted update to 0.
    if (e.target.value >= 0) {
      this.setState({
        updatedWheelDiameter: e.target.value
        // Got a parse warning if user cleared the text box.
        // updatedWheelDiameter: parseFloat(e.target.value)
      });
    }
  }

  openWheelDiameterPanel = () => {
    this.setState({
      showWheelDiameterPanel: true
    });
  }

  cancelChangesAndCloseWheelDiameterPanel = () => {
    this.setState({
      updatedWheelDiameter: this.props.userAdjustedWheelDiameterMillimetres || this.props.wheelDiameter,
      showWheelDiameterPanel: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props) != JSON.stringify(prevProps)) {
      if (this.props.wheelDiameter !== prevProps.wheelDiameter) {
        this.setState({
          updatedWheelDiameter: this.props.wheelDiameter,
          valid: true
        });
      }
    }
  }

  render() {
    let currentWheelDiameter = this.props.wheelDiameter;
    let diameterClass = 'graph-summary__item-value';
    if (this.props.userAdjustedWheelDiameterMillimetres) {
      currentWheelDiameter = this.props.userAdjustedWheelDiameterMillimetres;
      diameterClass += ' graph-summary__item-value--italic';
    }

    const resources = Resources.localizedString;
    let inputClasses = 'inline-block wheel-diameter__input vertical-middle';
    if (!this.state.valid) {
      inputClasses += ' error';
    }
    return (
      <React.Fragment>
        <div className="graph-summary__item graph-summary__item--timestamp clearfix">
          <span className="graph-summary__item-label">{resources.wheelDiameterAtLogStart}</span>
          <span className={diameterClass}>{`${currentWheelDiameter} ${resources.millimeters}`}</span>
        </div>
        <div className="graph-summary__item--overflow-visible graph-summary__item text-center relative clearfix">
          <button className="link-button" onClick={this.openWheelDiameterPanel}>{resources.enterWheelDiameterValue}</button>
          {this.state.showWheelDiameterPanel ?
            <React.Fragment>
              <div onClick={this.cancelChangesAndCloseWheelDiameterPanel} className="cover" />
              <div className="wheel-diameter">
                <span className="block wheel-diameter__title">{resources.enterWheelDiameterValue}</span>
                <input className={inputClasses} type="number" value={this.state.updatedWheelDiameter} onChange={this.wheelDiameterChange} autoFocus />
                <span className="inline-block vertical-middle margin-left">{resources.millimeters}</span>
                <span className="block wheel-diameter__permanent-text margin-bottom half-margin-top">{resources.wheelDiamaterManualChangeNote}</span>
                <button className="button button__full-width margin-vertical" onClick={this.updateGraphs}>{resources.updateGraphs}</button>
                <button className="wheel-diameter__reset link-button margin-bottom" onClick={this.resetWheelDiameter}>{resources.useRecordedWheelDiameterValue}</button>
                <div className="separator--top padded-top text-right">
                  <button className="cancel-button" onClick={this.cancelChangesAndCloseWheelDiameterPanel}>{resources.cancel}</button>
                </div>
              </div>
            </React.Fragment>
            : null
          }
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userAdjustedWheelDiameterMillimetres: state.graphSettings.userAdjustedWheelDiameterMillimetres,
    wheelDiameter: state.graphSettings.wheelDiameter
  };
}

function mapDispatchToProps(dispatch) {
  return {
    wheelDiameterActions: bindActionCreators(wheelDiameterActions, dispatch)
  };
}

export {WheelDiameter as WheelDiameterNoRedux};

export default connect(mapStateToProps, mapDispatchToProps)(WheelDiameter);
