import * as types from 'constants/actionTypes';
import journeysApi from 'api/journeysApi';
import moment from 'moment';
import {getAvailableResolutionForRange} from 'utilities/graphZoom.js';

const longestJourneyInHours = 24;

function getJourneyByIdSuccess(journey) {
  const currentTime = moment();
  const timeStarted = moment.utc(journey.timeStarted);
  if (!journey.timeEnded) {
    if (timeStarted.add(longestJourneyInHours, 'hours').valueOf() >= currentTime.valueOf()) {
      journey.timeEnded = currentTime;
    } else {
      journey.timeEnded = timeStarted;
    }
  }

  const rangeStart = new moment.utc(journey.timeStarted).local().add(-1, "second");
  const rangeEnd = new moment.utc(journey.timeEnded).local();

  return {
    type: types.UPDATE_GRAPH_SETTINGS,
    newSettings: {
      journeyId: journey.journeyId,
      selectedVehicleCode: journey.vehicleCode,
      rangeStart,
      rangeEnd,
      detailRangeStart: rangeStart,
      detailRangeEnd: rangeEnd,
      detailResolution: getAvailableResolutionForRange(rangeStart, rangeEnd),
      detailQuery: false,
    }
  };
}

export function getById(id) {
  return async function (dispatch) {
    try {
      const journey = await journeysApi.getById(id);
      return dispatch(getJourneyByIdSuccess(journey));
    }
    catch (error) {
        // OBSERVATION: At one point the Redux store seemed to disappear. I can't see why an exception occurring here should affect that though.
        throw error;
    }
  };
}
