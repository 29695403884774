import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as vehicleGroupActions from 'actions/vehicleGroupActions';
import * as R from 'ramda';
import {copy} from 'utilities/graphSettings';
import {Link} from 'react-router-dom';
import PageLayout from 'components/PageLayout';
import routes from 'routes';
import Resources from 'utilities/Resources';
import ReactTable from "react-table";
import vehicleGroupApi from 'api/vehicleGroupsApi';
import DeleteDialogue from './DeleteDialogue';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/_toast-notifications.scss';
import {Slide} from 'utilities/reactToastifyHelpers';
import "react-table/react-table.css";
import 'styles/_table.scss';

class ManageVehicles extends React.Component {
  static propTypes = {
    vehicleGroupActions: PropTypes.object.isRequired,
    savedVehicleGroup: PropTypes.bool,
    vehicleGroups: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);

    this.resources = Resources.localizedString;

    this.state = {
      vehicleGroups: copy(this.props.vehicleGroups),
      loading: true,
      showDeleteDialogue: false,
      toBeDeletedId: null,
      deleteApiResult: null,
      additionalToBeDeletedItems: null
    }

    this.addEditVehicleGroupUrl = routes.admin.vehicleGroups.addEdit.replace('/:vehicleGroupId?', '/');
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    // Shows toast notification if preset has previously been saved
    {this.props.savedVehicleGroup && this.showVehicleGroupSavedNotification()}
  }

  async componentDidMount(){
    let apiResult = {};
    try {
      apiResult = await vehicleGroupApi.get();
    } catch (error) {
      apiResult.error = error;
    }

    if (!apiResult.error) {
      this.props.vehicleGroupActions.setVehicleGroups(apiResult);
    } else {
      this.showVehicleGroupLoadFailedNotification();
    }

    this.setState({
      loading: false
    });
  }

  componentDidUpdate(prevProps){
    if (JSON.stringify(this.props.vehicleGroups) != JSON.stringify(prevProps.vehicleGroups)) {
      this.setState({
        vehicleGroups: copy(this.props.vehicleGroups),
        loading: false
      });
    }
  }

  optionsCell = (cellInfo) => {
    const vehicleGroup = this.state.vehicleGroups[cellInfo.index];
    const editUrl = this.addEditVehicleGroupUrl + vehicleGroup.vehicleGroupId;
    return (
      <div>
        <Link to={editUrl} className="icon-button icon-button--edit margin-right">{this.resources.edit}</Link>
        <button className="icon-button icon-button--delete" onClick={(event) => {this.delete(event, vehicleGroup)}}>{this.resources.delete}</button>
      </div>
    );
  }

  countVehicles = (cellInfo) => {
    const vehicleGroup = this.state.vehicleGroups[cellInfo.index];
    return (
      <div>{vehicleGroup.vehicles.length}</div>
    );
  }

  delete = (e, vehicleGroup) => {
    this.setState({
      showDeleteDialogue: true,
      toBeDeletedId: vehicleGroup.vehicleGroupId,
      additionalToBeDeletedItems: R.sortBy(R.compose(R.toLower, R.prop('name')))(vehicleGroup.triggers)
    });
  }

  onDeleteConfirm  = async function() {
    let api = {};
    try {
      api = await vehicleGroupApi.delete(this.state.toBeDeletedId);
    } catch (error) {
      // Set error to be used to show notification of success/failure
      api.error = error;
    }

    this.setState({
      showDeleteDialogue: false,
      toBeDeleted: null,
      deleteApiResult: api
    }, () => {
      if (!this.state.deleteApiResult.error) {
        this.setState({
          loading: true
        });
        this.props.vehicleGroupActions.getAllVehicleGroups();
      }
      this.showDeleteSavedNotification();
    });
  }

  onDeleteCancel = () =>{
    this.setState({
      showDeleteDialogue: false
    })
  }

  showDeleteSavedNotification = () => {
    if (!this.state.deleteApiResult.error) {
      toast(this.resources.vehicleGroupSuccessfullyDeleted, {
        type: toast.TYPE.SUCCESS,
        onClose: this.setState({deleteApiResult: null})
      });
    } else {
      toast(this.resources.vehicleGroupFailedToDelete, {
        type: toast.TYPE.ERROR,
        onClose: this.setState({deleteApiResult: null})
      });
    }
  }

  showVehicleGroupSavedNotification = () => {
    toast(this.resources.vehicleGroupSuccessfullySaved, {
      type: toast.TYPE.SUCCESS,
      onClose: this.props.vehicleGroupActions.setSaved(false)
    });
  }

  nameCell = (cellInfo) => {
    const vehicleGroupId = this.state.vehicleGroups[cellInfo.index].vehicleGroupId;
    const editUrl = this.addEditVehicleGroupUrl + vehicleGroupId;
    return (
      <div>
        <Link to={editUrl}>{this.state.vehicleGroups[cellInfo.index].name}</Link>
      </div>
    );
  }

  showVehicleGroupLoadFailedNotification = () => {
    toast(this.resources.vehicleGroupsFailedToLoad, {
      type: toast.TYPE.ERROR
    });
  }

  render() {
    const data = this.state.vehicleGroups;
    const addEditUrl = this.addEditVehicleGroupUrl;
    let deleteDialogueText = this.resources.deleteVehicleGroupDialogText;
    if (this.state.additionalToBeDeletedItems && this.state.additionalToBeDeletedItems.length > 0) {
      deleteDialogueText += " " + this.resources.deleteVehicleGroupWithTriggersDialogText;
    }
    return (
      <div className="page-content">
        <ToastContainer autoClose={6000} hideProgressBar={true} transition={Slide} newestOnTop={true} />
        <DeleteDialogue
        showDialogue={this.state.showDeleteDialogue}
        dialogueTitle={this.resources.deleteVehicleGroup}
        dialogueText={deleteDialogueText}
        confirmButtonText={this.resources.yesDeleteVehicleGroup}
        cancelButtonText={this.resources.noKeepVehicleGroup}
        onCancel={this.onDeleteCancel}
        onConfirm={this.onDeleteConfirm}
        additionalObjectsToBeDeleted={this.state.additionalToBeDeletedItems}
         />
        <h1>
          <Link to={addEditUrl} className="button right">{this.resources.addGroup}</Link>
          {this.resources.vehicleGroups}
        </h1>

        <ReactTable
          loading={this.state.loading}
          pageSize={this.state.vehicleGroups.length < 10 ? this.state.vehicleGroups.length : 10}
          data={data}
          columns={[
            {
              Header: this.resources.groupName,
              accessor: "name",
              Cell: this.nameCell
            },
            {
              Header: this.resources.numberOfLocos,
              accessor: "vehicleGroupId",
              Cell: this.countVehicles
            },
            {
              Header: this.resources.view,
              accessor: "vehicleGroupId",
              Cell: this.optionsCell
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    vehicleGroups: state.vehicleGroup.vehicleGroups,
    savedVehicleGroup: state.vehicleGroup.saved
  };
  // If do anything expensive in here, introduce memoization using a library like Reselect.
}

function mapDispatchToProps(dispatch) {
  return {
    // dialogueActions: bindActionCreators(dialogueActions, dispatch),
    vehicleGroupActions: bindActionCreators(vehicleGroupActions, dispatch)
  };
}

export {ManageVehicles as ManageVehiclesNoRedux};

const ConnectedManageVehicles = connect(mapStateToProps, mapDispatchToProps)(ManageVehicles);
export default PageLayout(ConnectedManageVehicles);
