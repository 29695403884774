import React from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';

class SelectTriggerActions extends React.Component{
  static propTypes= {
    actionTypesUpdateCallback: PropTypes.func.isRequired,
    selectedActionTypes: PropTypes.array,
  }

  constructor(props, context){
    super(props, context);

    this.state = {
      selectedActionTypes:[]
    };
  }

  async componentDidUpdate(prevProps){
    if (JSON.stringify(this.props.selectedActionTypes) !== JSON.stringify(prevProps.selectedActionTypes))
    {
      let selectedActionTypes = this.props.selectedActionTypes;

      selectedActionTypes.forEach(element => {
        let checkBox = document.getElementById("CheckboxActionType" + element.actionTypeId);
        checkBox.checked = true;
      });

      this.setState({
        selectedActionTypes
      });
    }
  }

  setSelectedActionTypes = (e) => {
    let selectedActionTypesCopy = [...this.state.selectedActionTypes];
    const notification = {actionTypeId: 1, Reference: "Notification"};
    const driverIrregularity = {actionTypeId: 2, Reference: "DriverIrregularity"};
    const notificationIndex = selectedActionTypesCopy.map(x => x.actionTypeId).indexOf(1);
    const driverIrregularityIndex = selectedActionTypesCopy.map(x => x.actionTypeId).indexOf(2);

    if (e.target.checked)
    {
      if (e.target.id === "CheckboxActionType1")
      {
        if(notificationIndex < 0)
        {
          selectedActionTypesCopy.push(notification);
        }
      }
      else if (e.target.id === "CheckboxActionType2")
      {
        if(driverIrregularityIndex < 0)
        {
          selectedActionTypesCopy.push(driverIrregularity);
        }
      }
    } else {
      if (e.target.id === "CheckboxActionType1")
      {
        if (notificationIndex >= 0)
        {
          selectedActionTypesCopy.splice(notificationIndex, 1);
        }
      }
      else if (e.target.id === "CheckboxActionType2")
      {
        if(driverIrregularityIndex >= 0)
        {
          selectedActionTypesCopy.splice(driverIrregularityIndex, 1);
        }
      }
    }

    this.setState({
      selectedActionTypes: selectedActionTypesCopy
    }, this.updateActionList(selectedActionTypesCopy, true));
  }

  updateActionList = (newActions, changesMade) => {
    this.props.actionTypesUpdateCallback(newActions, changesMade);
  }

  render(){
    return(
      <div className="column-quarter">
        <h3>Actions</h3>
        <div className="input-field">
          <div className="field-label">
            <label htmlFor="CheckboxActionType1">Notification (email):</label>
          </div>
          <div className="field-input">
            <input type="checkbox" id="CheckboxActionType1" name="IsNotification" className="input" onChange={this.setSelectedActionTypes} />
          </div>
        </div>
        <div className="input-field">
          <div className="field-label">
            <label htmlFor="CheckboxActionType2">Driver Irregularity (RailSmart EDS)</label>
          </div>
          <div className="field-input">
            <input type="checkbox" id="CheckboxActionType2" name="IsDriverIrregularity" className="input" onChange={this.setSelectedActionTypes} />
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {  };
}

function mapDispatchToProps(dispatch) {
  return {  };
}

export {SelectTriggerActions as SelectTriggerActionsNoRedux};

const ConnectedSelectTriggerActions = connect(mapStateToProps, mapDispatchToProps)(SelectTriggerActions);
export default ConnectedSelectTriggerActions;
