import React from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import Resources from 'utilities/Resources';

class ListSelector extends React.Component{
  static propTypes = {
    listSelectorUpdateCallback: PropTypes.func.isRequired,
    listItemsToBind: PropTypes.array.isRequired,
    listTitle: PropTypes.string.isRequired,
    previouslySelectedItems: PropTypes.array
  }

  constructor(props, context){
    super(props, context);

    this.state = {
      selectedItems: [],
      unselectedItems: []
    };
    this.searchKeyUp = this.keyUpHandler.bind(this);
  }

  async componentDidMount() {
    this.setState({
      unselectedItems: [...this.props.listItemsToBind]
    });
  }

  async componentDidUpdate(prevProps) {
    if(JSON.stringify(this.props.listItemsToBind) !== JSON.stringify(prevProps.listItemsToBind))
    {
      this.setState({
        unselectedItems: [...this.props.listItemsToBind]
      });
    }
    if(JSON.stringify(this.props.previouslySelectedItems) !== JSON.stringify(prevProps.previouslySelectedItems))
    {
      let previouslySelectedItems = [...this.props.previouslySelectedItems];
      let unselectedItems = [...this.state.unselectedItems];
      let selectedItems = [];
      previouslySelectedItems.forEach((selectedItem) => {
        const item = unselectedItems.find(obj => obj.itemId == selectedItem.itemId);
        unselectedItems = this.removeFromList(unselectedItems, item);
        selectedItems = this.addToList(selectedItems, item);
      });

      this.setState({
        selectedItems,
        unselectedItems
      },this.updateParentList(selectedItems, false));
    }
  }

  keyUpHandler = (e) => {
    const keyCode = e.keyCode;
    if(keyCode === 13)
    {
      let selectedItemCopy = [...this.state.selectedItems];
      selectedItemCopy.push(e.target.value)
      this.setState({
        selectedItems: selectedItemCopy
      });
    }
  }

  onSelect = (e) => {
    let itemsCopy = [...this.state.selectedItems];
    let unselectedItemsCopy = [...this.state.unselectedItems];
    //get selected
    const index = e.target.selectedIndex - 1;
    const selectedItem = unselectedItemsCopy[index];
    //add to selected
    const newSelectedItems = this.addToList(itemsCopy, selectedItem);
    //remove from to select
    const newUnselectedItems = this.removeFromList(unselectedItemsCopy, selectedItem);
    //reset for select
    this.setState({
      selectedItems: newSelectedItems,
      unselectedItems: newUnselectedItems
    }, this.updateParentList(newSelectedItems, true) );
    e.target.selectedIndex = 0
  }

  updateParentList = (selectedItems, changesMade) => {
    this.props.listSelectorUpdateCallback(selectedItems, changesMade);
  }

  removeSelectedItem = (e, selectedItemId) => {
    let selectedItemsCopy = [...this.state.selectedItems];
    let unselectedItemsCopy = [...this.state.unselectedItems];
    //get item to remove
    const selectedItem = selectedItemsCopy[selectedItemId];
    //add to unselected
    const newUnselected = this.addToList(unselectedItemsCopy, selectedItem);
    //add to selected
    const newSelected = this.removeFromList(selectedItemsCopy, selectedItem);

    this.setState({
      selectedItems: newSelected,
      unselectedItems: newUnselected
    }, this.updateParentList(newSelected, true));
  }

  removeFromList(listInStateToRemoveFrom, itemToRemove) {
    var index = listInStateToRemoveFrom.indexOf(itemToRemove);
    listInStateToRemoveFrom.splice(index, 1);
    return listInStateToRemoveFrom;
  }

  addToList(listInStateToAddTo, itemToAdd) {
    listInStateToAddTo.push(itemToAdd);
    return listInStateToAddTo;
  }

  render(){
    const resources = Resources.localizedString;
    const items = this.state.selectedItems.map((selectedItems, index) => {
      let value = selectedItems.value;
      return (<li key={index}>
      <div className="single-added-email">
        {value}
        <button id="buttonRemove" className="remove-email-button" onClick={(event) => {this.removeSelectedItem(event, index);}}>
          x
        </button>
      </div>
      </li>)
    });

    return(
      <div className="column-third">
        <h3>{this.props.listTitle}</h3>
        <div className="full-container">
          <select className="dropdown" onChange={this.onSelect} value="">
            <option value="" disabled defaultValue>{resources.pleaseSelect}</option>
            {this.state.unselectedItems.map((unselectedItem, index) => {
            let value = unselectedItem.value;
            return(<option key={index} value={value}>{value}</option>)})}
          </select>
        </div>
        <div className="added-email-container">
          <ul className="selection-list">
            {items}
          </ul>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { };
}

function mapDispatchToProps(dispatch) {
  return { };
}

export {ListSelector as ListSelectorNoRedux};

const ConnectedListSelector = connect(mapStateToProps, mapDispatchToProps)(ListSelector);
export default ConnectedListSelector;
