import React from 'react';
import PropTypes from 'prop-types';
// import {connect} from 'react-redux';
// import {bindActionCreators} from 'redux';
// import * as actions from 'actions/fuelSavingsActions';

// DC: I have placed quite a lot of thoughts and commented-out code in this component for the moment. This should be the only component where I do so.

export default class HeaderProfile extends React.Component {
  // DC: I think this way of declaring fields is still waiting to be officially adopted but is mad available to us by the inclusion of the
  // "babel-preset-stage-1" package:
  // https://www.sitepoint.com/javascript-private-class-fields/ - ESnext Class Fields
  state = {
    open: false
  }
  
  // The above and static properties re now at Stage 3: https://github.com/tc39/proposal-static-class-features
  // NOTE: Not supplying an "isRequired" or supplying a property of the wrong type will not cause any build warnings or errors, just browser console massages.
  static propTypes = {
    userName: PropTypes.string.isRequired
  };

  // static defaultProps = {
  //   userName: 'Strange Stranger'
  // }

  // https://medium.freecodecamp.org/reactjs-pass-parameters-to-event-handlers-ca1f5c422b9
  // It is inefficient to define an arrow function directly within the onClick attribute of the markup further down as it creates a new function on every render.
  // However, not using an arrow function causes problems when "this" is referenced within the event handler.
  // One commonly used method is to call "bind()" in the constructor to ensure that "this" always refers to a specific thing.
  // It is also useful if we want to call the event with some parameter, e.g. if we were rendering a list of items and wanted to ensure that an item raising an
  // event also specified its id.

  // constructor() {
  //   super();
  //   this.toggleOpen = this.toggleOpen.bind(this, someParameterValue);
  // }

  // toggleOpen(someParameter) {
  //   this.setState(prevState => ({
  //     open: !prevState.open
  //   }))
  // }

  // If the event does not accept parameters we can define the function in this manner. 
  toggleOpen = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }))
  }

  render() {
    return (
      <div className="meta-section">
        <div className={"user-profile relative" + (this.state.open ? " expanded" : "")}>
          {/* <a className="user-profile__navigation-expander" onClick={this.toggleOpen}> */}   {/* Replace the line below with this to revive the menu. */}
          <a className="user-profile__navigation-expander--hidden">
            <span className="user-profile__image inline-block vertical-middle overflow-hidden"></span>
            <span className="inline-block vertical-middle">{this.props.userName}</span>
          </a>
          <ul className="user-profile__navigation" role="navigation">
            <li>
              <a href="" className="block padded-vertical half-padded-horizontal">Logout</a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

// We jave now defined this inside the class as a static property.
// HeaderProfile.propTypes = {
//   userName: PropTypes.string.isRequired
// };

//
// These are the actions required to integrate with Redux, which we will hopefully do at some point.
// If we do, we will still export the component itself as well but without the "default". We would still want the disconnected component for tests.
//

// function mapStateToProps(state) {
// // function mapStateToProps(state, ownProps) {
//   return {
//     fuelSavings: state.fuelSavings
//   };
// }

// // function mapDispatchToProps(dispatch) {
// function mapDispatchToProps(dispatch) {
//   return {
//     actions: bindActionCreators(actions, dispatch)
//   };
// }

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(HeaderProfile);
