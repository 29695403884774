import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from 'actions/graphSettingsActions';
import {getAvailableResolutionForRange} from 'utilities/graphZoom';
import {PropTypes} from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {findVehicleByCode} from 'utilities/graphSettings';
import functions from 'utilities/functionUtilities';
import Resources from 'utilities/Resources';

import 'styles/datepicker.scss';

class GraphDateRange extends React.Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    graphSettings: PropTypes.object.isRequired,
    vehicles: PropTypes.array
  };

  constructor(props) {
    super(props);

    // get the vehicle to exclude the dates before the retention period
    const minDate = this.getEarliestDateAvailableForVehicle();

    this.state = {
      startDate: moment(this.props.graphSettings.rangeStart),
      endDate: moment(this.props.graphSettings.rangeEnd),
      validStartDate: true,
      validEndDate: true,
      rangeValid: true,
      rangeErrorMessages: [],
      startDateErrorMessages: [],
      endDateErrorMessages: [],
      minDate
    };
  }

  getEarliestDateAvailableForVehicle = () => {
    const vehicle = findVehicleByCode(this.props.graphSettings.selectedVehicleCode, this.props.vehicles);
    if (vehicle) {
      if (vehicle.earliestRetentionDate) {
        return moment.utc(vehicle.earliestRetentionDate).local();
      }
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props) != JSON.stringify(prevProps)) {
      if (this.props.graphSettings.rangeStart.valueOf() !== prevProps.graphSettings.rangeStart.valueOf() ||
        this.props.graphSettings.rangeEnd.valueOf() !== prevProps.graphSettings.rangeEnd.valueOf()) {
          this.setState({
            startDate: moment(this.props.graphSettings.rangeStart),
            endDate: moment(this.props.graphSettings.rangeEnd),
            validStartDate: true,
            validEndDate: true,
            rangeValid: true,
            rangeErrorMessages: [],
            startDateErrorMessages: [],
            endDateErrorMessages: [],
          });
      }

      if (this.props.graphSettings.vehicleCode != prevProps.vehicleCode ||
          JSON.stringify(this.props.vehicles) != JSON.stringify(prevProps.vehicles)) {
            const minDate = this.getEarliestDateAvailableForVehicle();
            this.setState({
              minDate
            });
          }
    }
  }

  validateDates = () => {
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    const duration = moment.duration(endDate.diff(startDate));

    let errorMessagesArray = [];

    if (this.state.validStartDate && this.state.validEndDate) {
      if (endDate <= moment() && startDate <= moment()) {
        if (startDate <= endDate) {
          // The three needs to be a config value
          if (duration.asWeeks() <= 3) {
            this.setState({ rangeValid: true }, () => {
              this.props.actions.updateGraphSettings({
                rangeStart: moment(startDate),
                rangeEnd: moment(endDate),
                detailResolution: getAvailableResolutionForRange(moment(startDate), moment(endDate)),
                detailRangeStart: moment(startDate),
                detailRangeEnd: moment(endDate),
                detailQuery: false
              });
            });
          } else {
            errorMessagesArray.push(Resources.localizedString.validationDateRangeLimit);
          }
        } else {
          errorMessagesArray.push(Resources.localizedString.validationStartDateBeforeEndDate);
        }
      } else {
        errorMessagesArray.push(Resources.localizedString.validationDateRangeInPast);
      }
    } else {
      if (!this.state.validStartDate) {
        errorMessagesArray = errorMessagesArray.concat(this.state.startDateErrorMessages);
      }
      if (!this.state.validEndDate) {
        errorMessagesArray = errorMessagesArray.concat(this.state.endDateErrorMessages);
      }
    }

    this.setState({ rangeErrorMessages: errorMessagesArray, rangeValid: !(errorMessagesArray.length > 0) });
  };

  setSelectedRange = functions.debounce(this.validateDates);

  handleStartDateChange = (date) => {
    this.setState({
      startDate: moment(this.state.startDate).set({
        date: date.get('date'),
        month: date.get('month'),
        year: date.get('year')
      }),
      validStartDate: true
    });
  };

  handleRawStartDateChange = (rawDate) => {
    // Formats need to be configurable
    var momentdate = moment(rawDate, "DD/MM/YYYY", true);
    if (momentdate.isValid()) {
      this.handleStartDateChange(momentdate);
    } else {
      this.setState({
        validStartDate: false,
        startDateErrorMessages: [...this.state.startDateErrorMessages, Resources.localizedString.validationStartDateFormat]
      });
    }
  };

  handleStartTimeChange = (date) => {
    this.setState({
      startDate: moment(this.state.startDate).set({
        hour: date.get('hour'),
        minute: date.get('minute')
      }),
      validStartDate: true
    });
  };

  handleRawStartTimeChange = (rawTime) => {
    // Formats need to be configurable
    var momentdate = moment(rawTime, 'HH:mm', true);
    if (momentdate.isValid()) {
      this.handleStartTimeChange(momentdate);
    } else {
      this.setState({
        validStartDate: false,
        startDateErrorMessages: [...this.state.startDateErrorMessages, Resources.localizedString.validationStartTimeFormat]
      });
    }
  };

  handleEndDateChange = (date) => {
    this.setState({
      endDate: moment(this.state.endDate).set({
        date: date.get('date'),
        month: date.get('month'),
        year: date.get('year')
      }),
      validEndDate: true
    });
  };

  handleRawEndDateChange = (rawDate) => {
    // Formats need to be configurable
    var momentdate = moment(rawDate, "DD/MM/YYYY", true);
    if (momentdate.isValid()) {
      this.handleEndDateChange(momentdate);
    } else {
      this.setState({
        validEndDate: false,
        endDateErrorMessages: [...this.state.endDateErrorMessages, Resources.localizedString.validationEndDateFormat]
      });
    }
  };

  handleEndTimeChange = (date) => {
    this.setState({
      endDate: moment(this.state.endDate).set({
        hour: date.get('hour'),
        minute: date.get('minute')
      }),
      validEndDate: true
    });
  };

  handleRawEndTimeChange = (rawTime) => {
    // Formats need to be configurable
    var momentdate = moment(rawTime, "HH:mm", true);
    if (momentdate.isValid()) {
      this.handleEndTimeChange(momentdate);
    } else {
      this.setState({
        validEndDate: false,
        endDateErrorMessages: [...this.state.endDateErrorMessages, Resources.localizedString.validationEndTimeFormat]
      });
    }
  };

  // NOTE: If we ever rewrite this component, have the sense to make another child component which handles one data picker and one time picker and the code
  //       to handle the various situations instead of reapeating all the same code for start and end.
  render() {
    //const resources = Resources.localizedString;
    return (
      // Formats need to be configurable
      <div className="right margin-bottom range-selector-wrapper">
        <div className="left margin-right datepicker-wrapper">
          <span className="label">Start date and time</span>
          <DatePicker
            className="datepicker margin-right"
            dateFormat="DD/MM/YYYY"
            selected={this.state.startDate}
            onChange={this.handleStartDateChange}
            onChangeRaw={(event) => { this.handleRawStartDateChange(event.target.value) }}
            maxDate={moment()}
            minDate={this.state.minDate}
            locale="en-gb"
          />
          <DatePicker
            className="timepicker margin-right"
            onChange={this.handleStartTimeChange}
            onChangeRaw={(event) => { this.handleRawStartTimeChange(event.target.value) }}
            selected={this.state.startDate}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            timeCaption="Time"
            locale="en-gb"
          />
        </div>
        <div className="left datepicker-wrapper margin-horizontal">
          <span className="label">End date and time</span>
          <DatePicker
            className="datepicker margin-right"
            selected={this.state.endDate}
            onChange={this.handleEndDateChange}
            onChangeRaw={(event) => { this.handleRawEndDateChange(event.target.value) }}
            maxDate={moment()}
            minDate={this.state.minDate}
            locale="en-gb"
          />
          <DatePicker
            className="timepicker margin-right"
            selected={this.state.endDate}
            onChange={this.handleEndTimeChange}
            onChangeRaw={(event) => { this.handleRawEndTimeChange(event.target.value) }}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            timeCaption="Time"
            locale="en-gb"
          />
        </div>
        <button className="button range-search-button" onClick={this.setSelectedRange}>Show Results</button>
        <div className="clear"></div>
        <span className={"message message--error" + (!this.state.rangeValid ? ' block' : '')}>
          {this.state.rangeErrorMessages.map(function (errorMessage, i) {
            return (<span key={i} className="block">{errorMessage}</span>)
          })}
        </span>
      </div>
    );
  }
}

// function mapStateToProps(state, ownProps) {
function mapStateToProps(state) {
  return {
    // DC: This gives the component the "this.props.graphSettings" property.
    vehicles: state.referenceData.vehicles,
    graphSettings: state.graphSettings
  };
  // If do anything expensive in here, introduce memoization using a library like Reselect.
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

// DC: I think this is needed only if we want to write Jest tests for the component.
export {GraphDateRange as GraphDateRangeNoRedux};

export default connect(mapStateToProps, mapDispatchToProps)(GraphDateRange);
