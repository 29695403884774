import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import * as channelTypes from 'constants/channelTypes';
import * as R from 'ramda';
import {copy} from 'utilities/graphSettings';
import Resources from 'utilities/Resources';

const sortChannels = R.sortWith([
  // As things stand, this should always be the first item in any ordering of Channels.
  R.ascend(R.prop('priorityGroup')),
  R.ascend(R.compose(R.toLower, R.prop('name')))
]);

const sortEvaluationTypes = R.sortWith([
  R.ascend(R.prop('orderIndex'))
]);

class AddEditTriggerCondition extends React.Component{
  static propTypes = {
    needsValidating: PropTypes.bool,
    triggerActions : PropTypes.object,
    triggerCondition: PropTypes.object,
    triggerConditionsUpdateCallback: PropTypes.func.isRequired,
    setConditionsAreValid: PropTypes.func.isRequired,
    removeCondition: PropTypes.func.isRequired,
    //passed in from redux
    triggerOperators: PropTypes.array,
    channels: PropTypes.array,
    triggerEvaluationTypes: PropTypes.array,
  };

  constructor(props, context) {
    super(props, context);
    this.operators = this.props.triggerOperators;
    this.state =
    {
      operators: this.props.triggerOperators,
      channels: sortChannels(this.props.channels),
      evaluationTypes: sortEvaluationTypes(this.props.triggerEvaluationTypes),
      triggerCondition: this.props.triggerCondition,
      //controlIDs
      selectChannelId: "selectChannel_" + this.props.triggerCondition.orderIndex,
      selectOperatorId: "selectOperator_" + this.props.triggerCondition.orderIndex,
      selectEvaluationTypeId: "selectEvaluationType_" + this.props.triggerCondition.orderIndex,
      textBoxTriggerValueId: "textBoxTriggerValue_" + this.props.triggerCondition.orderIndex,
      textBoxEvaluationDurationId: "textBoxEvaluationDuration_" + this.props.triggerCondition.orderIndex,
      isDurationEvaluationType: false
    }

    this.resources = Resources.localizedString;
  }

  async componentDidMount() {
    this.bindSelectedCondition(this.state.triggerCondition);
  }

  bindSelectedCondition = (triggerCondition) => {
    if (triggerCondition.evaluationTypeId) {
      triggerCondition.selectedEvaluationType = this.state.evaluationTypes.find(obj => obj.triggerEvaluationTypeId == triggerCondition.evaluationTypeId);
      this.setIsDurationEnabled(triggerCondition.evaluationTypeId);
    }

    if (triggerCondition.channelId) {
      triggerCondition.selectedChannel = this.state.channels.find(obj => obj.channelId == triggerCondition.channelId);
    }

    this.bindOperators(triggerCondition.selectedChannel);

    if (triggerCondition.triggerOperatorId) {
      triggerCondition.selectedOperator = this.state.operators.find(obj => obj.triggerOperatorId == triggerCondition.triggerOperatorId);
    }

    if (triggerCondition.channelId && triggerCondition.triggerOperatorId && triggerCondition.evaluationTypeId) {
      triggerCondition.description = this.generateDescription(triggerCondition);
    }

    triggerCondition.conditionTypeId = 1;

    this.setControls(triggerCondition);

    this.setState({
      triggerCondition
    }, () => {
      this.updateTriggerConditions(triggerCondition, false);
    });
  }

  async componentDidUpdate(prevProps){
    if (JSON.stringify(this.props.triggerCondition) !== JSON.stringify(prevProps.triggerCondition)) {
      this.bindSelectedCondition(copy(this.props.triggerCondition));
    }

    if (JSON.stringify(this.props.triggerOperators) !== JSON.stringify(prevProps.triggerOperators)) {
      this.operators = this.props.triggerOperators;

      this.setState({
        operators: this.props.triggerOperators
      }, () => {this.bindOperators()});
    }

    if (JSON.stringify(this.props.triggerEvaluationTypes) !== JSON.stringify(prevProps.triggerEvaluationTypes)) {
      this.setState({
        evaluationTypes: sortEvaluationTypes(this.props.triggerEvaluationTypes)
      });
    }

    if (JSON.stringify(this.props.channels) !== JSON.stringify(prevProps.channels)) {
      this.setState({
        channels: sortChannels(this.props.channels)
      });
    }

    if (JSON.stringify(this.props.needsValidating) !== JSON.stringify(prevProps.needsValidating)) {
      if(this.props.needsValidating === true) {
        let triggerCondition = copy(this.state.triggerCondition);
        triggerCondition.hasBeenValidated = true;
        triggerCondition.isValid = this.validateCondition(triggerCondition);

        this.setState({
          triggerCondition
        }, this.props.setConditionsAreValid(triggerCondition));
      }
    }
  }

  bindOperators = (selectedChannel) => {
    if (selectedChannel && selectedChannel.channelType == channelTypes.DIGITAL_CHANNEL) {
      this.setState({
        operators: this.operators.filter(c => c.triggerOperatorId == 1)
      });
      return;
    }

    this.setState({
      operators: this.operators
    });
  }

  setControls = (triggerCondition) => {
    const channel = this.state.channels.find(obj => obj.channelId == triggerCondition.channelId);
    let channelSelect = document.getElementById(this.state.selectChannelId);
    if (channel) {
      const channelIndex = this.state.channels.indexOf(channel);
      channelSelect.selectedIndex = channelIndex + 1;
    } else {
      channelSelect.selectedIndex = 0;
    }

    const operator = this.state.operators.find(obj => obj.triggerOperatorId == triggerCondition.triggerOperatorId);
    let operatorSelect = document.getElementById(this.state.selectOperatorId);
    if (operator) {
      const operatorIndex = this.state.operators.indexOf(operator);
      operatorSelect.selectedIndex = operatorIndex + 1;
    } else {
      operatorSelect.selectedIndex = 0;
    }

    const evaluationType = this.state.evaluationTypes.find(obj => obj.triggerEvaluationTypeId == triggerCondition.evaluationTypeId);
    let evaluationTypeSelect = document.getElementById(this.state.selectEvaluationTypeId);
    if (evaluationType) {
      const evaluationTypeIndex = this.state.evaluationTypes.indexOf(evaluationType);
      evaluationTypeSelect.selectedIndex = evaluationTypeIndex + 1;
    } else {
      evaluationTypeSelect.selectedIndex = 0;
    }

    let textBoxTriggerValue = document.getElementById(this.state.textBoxTriggerValueId);
    textBoxTriggerValue.value = triggerCondition.conditionValue;

    let textBoxEvaluationDuration = document.getElementById(this.state.textBoxEvaluationDurationId);
    textBoxEvaluationDuration.value = triggerCondition.evaluationDurationSeconds;
  }

  onSelectOperator = (e) => {
    let triggerCondition = copy(this.state.triggerCondition);
    const selectedValue = this.getSelectedValue(e);

    if (selectedValue > 0) {
      triggerCondition.selectedOperatorId = selectedValue;
      triggerCondition.triggerOperatorId = selectedValue;
      triggerCondition.selectedOperator = this.state.operators.find(obj => obj.triggerOperatorId == triggerCondition.selectedOperatorId);
      triggerCondition.description = this.generateDescription(triggerCondition);
    }

    this.setState({
      triggerCondition
    }, this.updateTriggerConditions(triggerCondition, true));
  }

  setIsDurationEnabled = (selectedEvaluationId) => {
    let textBoxEvaluationDuration = document.getElementById(this.state.textBoxEvaluationDurationId);
    const evaluationType = this.state.evaluationTypes.find(obj => obj.triggerEvaluationTypeId == selectedEvaluationId);
    const isDurationEvaluationType = evaluationType.reference.toLowerCase().includes("duration");

    if (isDurationEvaluationType) {
      textBoxEvaluationDuration.value = "";
    }

    this.setState({
      isDurationEvaluationType
    });

    return isDurationEvaluationType;
  }

  onSelectEvaluationType = (e) =>
  {
    let triggerCondition = copy(this.state.triggerCondition);
    const selectedValue = this.getSelectedValue(e);
    let textBoxEvaluationDuration = document.getElementById(this.state.textBoxEvaluationDurationId);

    triggerCondition.evaluationDurationSeconds = textBoxEvaluationDuration.value = 0;

    if (selectedValue > 0) {
      const isDurationEvaluationType = this.setIsDurationEnabled(selectedValue);
      const evaluationType = this.state.evaluationTypes.find(obj => obj.triggerEvaluationTypeId == selectedValue);
      triggerCondition.selectedEvaluationType = evaluationType;
      triggerCondition.evaluationTypeId = evaluationType.triggerEvaluationTypeId;
      if (isDurationEvaluationType) {
        //Allow for adding a duration in textBoxEvaluationDuration
        triggerCondition.evaluationDurationSeconds = '';
      }
      this.setState({
        triggerCondition,
        isDurationEvaluationType
      }, this.updateTriggerConditions(triggerCondition, true));
    }
  }

  onSelectChannel = (e) => {
      let triggerCondition = copy(this.state.triggerCondition);
      const selectedValue = this.getSelectedValue(e);

      triggerCondition.channelId = selectedValue

      if (selectedValue > 0) {
          let selectedChannel = this.state.channels.find(obj => obj.channelId == selectedValue);
          triggerCondition.selectedChannel = selectedChannel;
          triggerCondition.description = this.generateDescription(triggerCondition);
      }

      this.setState({
          triggerCondition
      }, () => {
        this.updateTriggerConditions(triggerCondition, true);
        this.bindOperators(triggerCondition.selectedChannel)
      });
  }

  setConditionValue = (e) => {
    let triggerCondition = copy(this.state.triggerCondition);
    triggerCondition.conditionValue = e.target.value;
    triggerCondition.description = this.generateDescription(triggerCondition);
    this.setState({
      triggerCondition
    }, this.updateTriggerConditions(triggerCondition, true));
  }

  setEvaluationValue = (e) => {
    let triggerCondition = copy(this.state.triggerCondition);
    triggerCondition.evaluationDurationSeconds = e.target.value;
    triggerCondition.description = this.generateDescription(triggerCondition);
    this.setState({
      triggerCondition
    }, this.updateTriggerConditions(triggerCondition, true));
  }

  getSelectedValue = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    let selectedValue = selectedOption.value;
    if (selectedValue == this.resources.pleaseSelect) {
      selectedValue = 0;
    }
    return selectedValue;
  }

  updateTriggerConditions = (triggerCondition, changesMade) => {
    this.props.triggerConditionsUpdateCallback(triggerCondition, changesMade);
  }

  deleteCondition = () => {
    this.props.removeCondition(this.state.triggerCondition, true);
  }

  validateSelect = (dropDownId, selectedId, isValid) => {
      let selectOperator = document.getElementById(dropDownId);
      if (selectOperator.selectedIndex < 1)
      {
        isValid = false;
        selectOperator.className =  "dropdown error";
      }
      else
      {
        selectOperator.className =  "dropdown";
      }
      return isValid;
  }

  validateText = (textBoxId, isRequired = true, isNumeric = true, meetsOtherConditons = true, conditionIsValid) => {
      const numbers = /^[0-9]+$/;
      let textBox = document.getElementById(textBoxId);
      const value = textBox.value;

      if ((isRequired && value == "") || (isNumeric && (!value.match(numbers))) || !meetsOtherConditons) {
        conditionIsValid = false;
        textBox.className = "error";
      } else {
        textBox.className = "";
      }
      return conditionIsValid;
  }

  isNumber = (evt) => {
      evt = (evt) ? evt : window.event;
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
  }

  validateCondition = (condition) => {
    let isValid = true;
    //evaluation duration only required if type of duration
    const isDurationEvaluationType = this.state.isDurationEvaluationType;

    isValid = this.validateSelect(this.state.selectOperatorId, condition.selectedOperatorId, isValid);
    isValid = this.validateSelect(this.state.selectEvaluationTypeId, condition.selectedEvaluationTypeId, isValid);
    isValid = this.validateSelect(this.state.selectChannelId, condition.channelId, isValid);

    const evaluationDurationIsValid =
      !isDurationEvaluationType ||
      (isDurationEvaluationType && condition.evaluationDurationSeconds > 0 && condition.evaluationDurationSeconds <= 300);

    let textBoxTrigger = document.getElementById(this.state.textBoxTriggerValueId);
    let triggerValueIsValid = true;
    if (condition.selectedChannel.channelType) {
      triggerValueIsValid = (condition.selectedChannel.channelType == channelTypes.ANALOGUE_CHANNEL
        || (condition.selectedChannel.channelType == channelTypes.DIGITAL_CHANNEL && (textBoxTrigger.value == "1" || textBoxTrigger.value  == "0")))
    }

    isValid = this.validateText(this.state.textBoxEvaluationDurationId, isDurationEvaluationType, isDurationEvaluationType, evaluationDurationIsValid,  isValid);
    isValid = this.validateText(this.state.textBoxTriggerValueId, true, true, triggerValueIsValid, isValid);

    const channelName = condition.selectedChannel.name;
    this.setState({
      validationMessage: (!triggerValueIsValid ? channelName + " " + this.resources.triggerConditionValueValidationMessage : "")
    });

    return isValid;
  }

  // Called every time an element is updated because AddEditTrigger needs to maintain the current state of each condition.
  // Note that this means we need to be able to generate a description even if elements have only been partially completed. For eaxample, selectedEvaluationType
  // is near the end of the line and so is likely to have no value as earlier elements are filled in.
  // NOTE: It might have been better to generate this in the API. However, the work is done and it can stay in the web app for now but it needs to be passed
  //       back to the API for each condition as it is that that needs to combine them for the Trigger description because of potential reordering.
  generateDescription = (condition) => {
    if (condition.selectedEvaluationType && condition.selectedOperator && condition.selectedChannel.name) {
      let description = `${this.resources.if.toUpperCase()}: ${condition.selectedChannel.name} ${this.resources.is} ${condition.selectedOperator.symbol} ` +
                        `${condition.conditionValue} ${this.resources.for} ${condition.selectedEvaluationType.reference}`;

      // The "of" part only makes sense for the time-based evaluation types that use the property. Otherwise it looks stupid and confusing.
      if (condition.selectedEvaluationType.reference &&
          condition.selectedEvaluationType.reference.includes('Seconds')) {
        description += ` ${this.resources.of} ${condition.evaluationDurationSeconds}`;
      }

      return description;
    }
  }

  getChannelOptions = () => {
    return this.state.channels.map(x => <option key={x.channelId} value={x.channelId}>{x.name}</option>);
    // DC: I would like to sort the channels into some intelligible order, using things like the alphabet. However, this results in the wrong item being selected
    //     when editing.
    //     The problem is there is a setControls() method which looks up the index of the this.state.channels member that matches the value and then sets the
    //     selectedIndex of the <select /> element according to the result. I decided the easiest thing to do would be sort the items when setting this.state.channels.
    //     If this is React done properly I don't know how much I want to do it. Is there not an easier way to do drop downs? The PresetSelection component seemed neater.
    // NOTE: This could become really unpleasant if they order the CR for being able to add ORed conditions via the UI.
    // return sortChannels(this.state.channels).map(x => <option key={x.channelId} value={x.channelId}>{x.name}</option>);
  }

  getOperatorOptions = () => this.state.operators.map(x => <option key={x.triggerOperatorId} value={x.triggerOperatorId}>{x.symbol}</option>);

  getEvaluationTypeOptions = () => {
    return this.state.evaluationTypes.map(x => <option key={x.triggerEvaluationTypeId} value={x.triggerEvaluationTypeId}>{x.reference}</option>);
    // return sortEvaluationTypes(this.state.evaluationTypes).map(x => <option key={x.triggerEvaluationTypeId} value={x.triggerEvaluationTypeId}>{x.reference}</option>);
  }

  render() {
    return(
      <div className="condition-container input-field">
        <div className="channel-selection">
          <div className="condition-field-label">
            {this.state.triggerCondition.orderIndex == 0 ? <label className={"condition-label margin-right"}>{this.resources.if}</label> : <label className={"condition-label margin-right"}>{this.resources.andIf}</label>}
          </div>
          <div className={this.state.validationMessage ? "condition-field-input condition-field-input--error relative" : "condition-field-input"}>
            <label className="help-label">{this.resources.channel}*</label>
            <select className="dropdown" id={this.state.selectChannelId} onChange={this.onSelectChannel}>
              <option className="prompt" disabled defaultValue key="0">{this.resources.pleaseSelect}</option>
              {this.getChannelOptions()}
            </select>
            {this.state.validationMessage ?
              <span className="message message--error block">{this.state.validationMessage}</span>
            : null}
          </div>
        </div>
        <div className="operator-selection">
          <div className="condition-field-label">
            <label className="condition-label">{this.resources.is}:</label>
          </div>
          <div className="condition-field-input">
            <label className="help-label">{this.resources.operator}*</label>
            <select className="dropdown" id={this.state.selectOperatorId} onChange={this.onSelectOperator}>
            <option className="prompt" disabled defaultValue key="0">{this.resources.pleaseSelect}</option>
            {this.getOperatorOptions()}
            </select>
          </div>
          <div className="condition-field-input">
            <label className="help-label">{this.resources.triggerValue}*</label>
            <input type="text" id={this.state.textBoxTriggerValueId}  name="ConditionValue" keyboardtype='numeric' onBlur={this.setConditionValue} onKeyPress={this.isNumber} />
          </div>
        </div>
        <div className="evaluation-selection">
          <div className="condition-field-label">
            <label className="condition-label">{this.resources.for}</label>
          </div>
          <div className="condition-field-input">
            <label className="help-label">{this.resources.evaluationType}*</label>
            <select className="dropdown" id={this.state.selectEvaluationTypeId} onChange={this.onSelectEvaluationType}>
              <option className="prompt" disabled defaultValue key="0">{this.resources.pleaseSelect}</option>
              {this.getEvaluationTypeOptions()}
            </select>
          </div>
          <div className="condition-field-input">
            <label className="help-label">{this.resources.evaluationDuration + " (1-300 " + this.resources.seconds + ")"}</label>
            <input type="text"
              id={this.state.textBoxEvaluationDurationId}
              disabled = {!this.state.isDurationEvaluationType}
              name="evaluationDurationSeconds"
              keyboardtype='numeric'
              onBlur={this.setEvaluationValue}
              onKeyPress={this.isNumber}
            >
            </input>
            <button onClick={this.deleteCondition} className="trigger-condition__remove icon-button icon-button--remove right">X</button>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    triggerOperators: state.referenceData.triggerOperators,
    channels: state.referenceData.channels,
    triggerEvaluationTypes: state.referenceData.triggerEvaluationTypes
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export {AddEditTriggerCondition as AddEditTriggerConditionNoRedux};

const ConnectedAddEditTriggerCondition = connect(mapStateToProps, mapDispatchToProps)(AddEditTriggerCondition);
export default ConnectedAddEditTriggerCondition;
