import * as types from 'constants/actionTypes';
import eventsApi from 'api/eventsApi';
import moment from 'moment';
import {getAvailableResolutionForRange} from 'utilities/graphZoom.js';

const eventWindowInHours = 6;

function getEventByIdSuccess(event) {
  const rangeStart = moment.utc(event.rmdTimeStamp).local().add(-eventWindowInHours / 2, 'hours');
  const rangeEnd = moment.utc(event.rmdTimeStamp).local().add(eventWindowInHours / 2, 'hours');

  return {
    type: types.UPDATE_GRAPH_SETTINGS,
    newSettings: {
      event,
      selectedVehicleCode: event.vehicleCode,
      rangeStart,
      rangeEnd,
      detailRangeStart: rangeStart,
      detailRangeEnd: rangeEnd,
      detailResolution: getAvailableResolutionForRange(rangeStart, rangeEnd),
      detailQuery: false,
    }
  };
}

export function getById(id) {
  return async function (dispatch) {
    try {
      const event = await eventsApi.getById(id);
      return dispatch(getEventByIdSuccess(event));
    }
    catch (error) {
        // OBSERVATION: At one point the Redux store seemed to disappear. I can't see why an exception occurring here should affect that though.
        throw error;
    }
  };
}
