import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as vehicleGroupActions from 'actions/vehicleGroupActions';
import * as referenceDataActions from 'actions/referenceDataActions';
import PageLayout from 'components/PageLayout';
import * as R from 'ramda';
import EditVehicleGroupErrorDialogue from 'components/EditVehicleGroupErrorDialogue';
import routes from 'routes';
import Resources from 'utilities/Resources';
import vehicleGroupApi from 'api/vehicleGroupsApi';
import {withRouter} from "react-router";
import {Prompt, Link} from "react-router-dom";
import {copy, filterByVehicleCode} from 'utilities/graphSettings';
import ReactTable from "react-table";
import "react-table/react-table.css";
import 'styles/_table.scss';
import 'styles/_vehicle-groups.scss';

class EditVehicleGroup extends React.Component {
  static propTypes = {
    vehicleGroups: PropTypes.array,
    vehicleGroupActions: PropTypes.object.isRequired,
    referenceDataActions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    vehicles: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      vehicleGroupName: '',
      vehicleGroupId: '',
      vehicleGroupVehicles: [],
      selectedLocos: [],
      selectedTriggers: [],
      locos: [],
      locoSearchText: '',
      filteredLocos: [],
      changesMade: false,
      blocking: false,
      showUpdateVehicleGroupDialogue: false,
      nameValid: true,
      duplicateError: false,
      vehiclesValid: true,
      errorMessage: ''
    }

    this.submit = this.submit.bind(this);
    this.addEditTriggerUrl = routes.admin.triggers.addEditTrigger.replace('/:triggerId?', '/');
  }

  async componentDidMount(){
    // get from match / location to get the id
    if (this.props.match.params.vehicleGroupId) {
      let api = {};
      try {
        api = await vehicleGroupApi.getById(this.props.match.params.vehicleGroupId);

        this.setState({
          vehicleGroupName: api.name,
          vehicleGroupId: api.vehicleGroupId,
          vehicleGroupVehicles: api.vehicles,
          selectedLocos: api.vehicles,
          selectedTriggers: R.sortBy(R.compose(R.toLower, R.prop('name')))(api.triggers)
        });
      } catch (error) {
        // Set error and maybe do something with it later if API call fails???
        api.error = error;
      }

      if (api.error) {
        this.props.history.push(routes.admin.vehicleGroups.index);
      }
    }

    this.setState({
      locos: this.props.vehicles
    });
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.vehicles) != JSON.stringify(prevProps.vehicles)) {
      // set unselected
      this.setState({
        locos: this.props.vehicles,
        filteredLocos: filterByVehicleCode(this.state.locos, this.state.locoSearchText)
      });
    }
  }

  vehicleGroupNameKeyChange = (e) => {
    if (e.target.value) {
      this.setState({
        vehicleGroupName: e.target.value
      });
    } else {
      this.setState({
        vehicleGroupName: ''
      });
    }
  }

  vehicleSearchKeyChange = (e) => {
    if (e.target.value) {
      this.setState({
        locoSearchText: e.target.value,
        filteredLocos: filterByVehicleCode(this.state.locos, e.target.value)
      });
    } else {
      this.setState({
        locoSearchText: '',
        filteredLocos: []
      });
    }
  }

  locoCheckboxChange = (e) => {
    const checked = e.target.checked;
    let changesMade = this.state.changesMade;

    if (this.props.match.params.vehicleGroupId) {
      changesMade = true;
    }

    if (checked) {
      // add to selected
      const selectedLoco = this.state.locos.find(l => l.vehicleId == e.target.value);
      let selectedLocos = copy(this.state.selectedLocos);
      selectedLocos.push({vehicleId: selectedLoco.vehicleId, vehicleCode: selectedLoco.vehicleCode});
      this.setState({
        selectedLocos: R.sortBy(R.compose(R.toLower, R.prop('vehicleCode')))(selectedLocos),
        changesMade
      });
    } else {
      // remove from selected
      const selectedLoco = this.state.selectedLocos.find(l => l.vehicleId == e.target.value);
      const selectedLocoIndex = R.indexOf(selectedLoco, this.state.selectedLocos);
      let selectedLocos = copy(this.state.selectedLocos);
      selectedLocos.splice(selectedLocoIndex, 1);
      this.setState({
        selectedLocos,
        changesMade
      });
    }
  }

  locoChannel = (vehicle, index) => {
    let checked = false;
    if (this.state.selectedLocos.find(l => l.vehicleId == vehicle.vehicleId)) {
      checked = true;
    }
    return (
    <div className="loco-list__item" key={index}>
      <input onChange={this.locoCheckboxChange} type="checkbox" id={`vehicle-${vehicle.vehicleId}`} checked={checked} name={`vehicle-${vehicle.vehicleId}`} value={vehicle.vehicleId} />
      <label className="loco-list__item-label" htmlFor={`vehicle-${vehicle.vehicleId}`}>{vehicle.vehicleCode}</label>
    </div>);
  }

  clearSelection = () => {
    let changesMade = this.state.changesMade;

    if (this.props.match.params.vehicleGroupId) {
      changesMade = true;
    }

    this.setState({
      selectedLocos: [],
      changesMade
    });
  }

  removeSelectedVehicle = (e, vehicle) => {
    let changesMade = this.state.changesMade;

    if (this.props.match.params.vehicleGroupId) {
      changesMade = true;
    }

    const selectedLocoIndex = R.indexOf(vehicle, this.state.selectedLocos);
    let selectedLocos = copy(this.state.selectedLocos);
    selectedLocos.splice(selectedLocoIndex, 1);
    this.setState({
      selectedLocos,
      changesMade
    });
  }

  removeCell = (cellInfo) => {
    const vehicleId = this.state.selectedLocos[cellInfo.index][cellInfo.column.id];
    const selectedLoco = this.state.selectedLocos.find(l => l.vehicleId == vehicleId);
    return (
      <div>
        <button className="icon-button icon-button--remove" onClick={(event) => {this.removeSelectedVehicle(event, selectedLoco)}}>{Resources.localizedString.delete}</button>
      </div>
    );
  }

  // viewTrigger = (cellInfo) => {
  //   const triggerId = this.state.selectedTriggers[cellInfo.index][cellInfo.column.id];
  //   const addEditTriggerUrl = this.addEditTriggerUrl + triggerId;
  //   return (
  //     <div>
  //       <Link to={addEditTriggerUrl}>{Resources.localizedString.view}</Link>
  //     </div>
  //   );
  // }

  validate = () => {
    let nameValid = true;
    let vehiclesValid = true;
    let valid = true;

    if (!this.state.vehicleGroupName.trim()) {
      nameValid = false;
      valid = false;
    }

    if (this.state.selectedLocos.length < 1) {
      vehiclesValid = false;
      valid = false;
    }

    this.setState({
      nameValid,
      vehiclesValid
    });

    return valid;
  }

  submit = async function() {
    if (this.validate()) {
      let api = {};

      const request = {
        name: this.state.vehicleGroupName.trim(),
        vehicles: R.pluck('vehicleId')(this.state.selectedLocos)
      };

      if (this.props.match.params.vehicleGroupId) {
        request.vehicleGroupId = this.props.match.params.vehicleGroupId;
      }

      try {
        if (this.props.match.params.vehicleGroupId) {
          request.vehicleGroupId = this.props.match.params.vehicleGroupId;
          api = await vehicleGroupApi.edit(request);
        } else {
          api = await vehicleGroupApi.add(request);
        }

        this.setState({
          changesMade: false
        });

        // Add toast notifications
        this.props.vehicleGroupActions.setSaved(true);
        this.props.history.push(routes.admin.vehicleGroups.index);

      } catch (error) {
        api.error = error;
        if (api.error.response && api.error.response.status === 409) {
          this.setState({
            duplicateError: true,
            errorMessage: Resources.localizedString.vehicleGroupNameExistsErrorMessage
          });
        } else {
          this.setState({
            showUpdateVehicleGroupDialogue: true
          });
        }
      }
    }
  }

  onClose = () => {
    this.setState({
      showUpdateVehicleGroupDialogue: false
    });
  }

  render() {
    const filteredLocos = this.state.locoSearchText.length > 0;
    const resources = Resources.localizedString;
    const createEditTitle = this.props.match.params.vehicleGroupId ? resources.edit : resources.create;
    return (
      <div className="page-content">
        { this.state.vehicleGroupId &&
          <React.Fragment>
            <Prompt
              when={this.state.changesMade}
              message={resources.leaveVehicleGroupEditPageConfirmationText}
            />
            <EditVehicleGroupErrorDialogue showUpdateVehicleGroupDialogue={this.state.showUpdateVehicleGroupDialogue} onClose={this.onClose} />
          </React.Fragment>
        }
        <h1>{createEditTitle} {resources.vehicleGroup}</h1>
        <div className="padded-vertical margin-vertical">
          <label htmlFor="vehicle-name">{resources.groupName}</label>
          <input type="text" id="vehicle-name" className={!this.state.nameValid || this.state.duplicateError ? "error margin-right" : "margin-right"} name="vehicle-name" value={this.state.vehicleGroupName} onChange={this.vehicleGroupNameKeyChange}  />
          {this.state.errorMessage ?
            <span className="message message--error margin-left inline-block">{this.state.errorMessage}</span>
          : null }
        </div>
        <div className="flexbox padded-bottom margin-bottom">
          <div className="loco-selection__wrapper flex">
            <h4>{resources.locos}</h4>
            <div className="half-padded-bottom">
              {this.state.selectedLocos.length > 0 ?
                <button className="loco-selection__clear-selection" onClick={this.clearSelection}>{resources.clearSelection}</button>
              : null }
              <span className="loco-selection__selection-count">{this.state.selectedLocos.length} {resources.selected}</span>
            </div>
            <div className="loco-selection__input-wrapper">
              <input type="text" placeholder={resources.search} className={!this.state.vehiclesValid ? "error full-width" : "full-width"} id="vehicle-search" name="vehicle-search" value={this.state.locoSearchText} onChange={this.vehicleSearchKeyChange}  />
            </div>
            <div className="loco-list">
              {filteredLocos ? this.state.filteredLocos.map(this.locoChannel) : this.state.locos.map(this.locoChannel)}
            </div>
          </div>
          {this.state.selectedLocos.length > 0 ?
            <div className="selected-locos__wrapper flex">
              <h4>{resources.selectedLocos}</h4>
              <ReactTable
                loading={this.state.loading}
                data={this.state.selectedLocos}
                pageSize={this.state.selectedLocos.length}
                showPagination={false}
                columns={[
                  {
                    Header: resources.locoNumber,
                    accessor: "vehicleCode",
                    width: 200
                  },
                  {
                    Header: '',
                    accessor: "vehicleId",
                    Cell: this.removeCell,
                    width: 50
                  }
                ]}
                style={{
                  maxHeight: "600px" // This will force the table body to overflow and scroll, since there is not enough room
                }}
                className="-striped -highlight"
              />
            </div>
          : null}
        </div>
        {this.state.selectedTriggers.length > 0 ?
            <div className="margin-vertical padded-vertical">
              <h4>{resources.selectedTriggers}</h4>
              <ReactTable
                loading={this.state.loading}
                data={this.state.selectedTriggers}
                pageSize={this.state.selectedTriggers.length}
                showPagination={false}
                columns={[
                  {
                    Header: resources.name,
                    accessor: "name"
                  },
                  // {
                  //   Header: '',
                  //   accessor: "triggerId",
                  //   Cell: this.viewTrigger,
                  //   width: 100
                  // }
                ]}
                style={{
                  maxHeight: "600px" // This will force the table body to overflow and scroll, since there is not enough room
                }}
                className="-striped -highlight"
              />
            </div>
          : null}
        <div className="overflow-hidden">
          <button className="button right" onClick={this.submit}>{`${resources.save} ${resources.vehicleGroup}`}</button>
          <Link to={routes.admin.vehicleGroups.index} className="button button--secondary">{resources.cancel}</Link>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    vehicles: copy(state.referenceData.vehicles)
  };
  // If do anything expensive in here, introduce memoization using a library like Reselect.
}

function mapDispatchToProps(dispatch) {
  return {
    vehicleGroupActions: bindActionCreators(vehicleGroupActions, dispatch),
    referenceDataActions: bindActionCreators(referenceDataActions, dispatch)
  };
}

export {EditVehicleGroup as EditVehicleGroupNoRedux};

const ConnectedEditVehicleGroup = connect(mapStateToProps, mapDispatchToProps)(EditVehicleGroup);
export default withRouter(PageLayout(ConnectedEditVehicleGroup));
