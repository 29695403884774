import * as types from 'constants/actionTypes';
import triggersApi from 'api/triggersApi';

function fetchTriggersDataSuccess(triggers) {
  return {type: types.FETCH_TRIGGERS_DATA_SUCCESS, triggers};
}

export function setSaved (saved) {
  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_TRIGGERS_SAVED_FLAG,
      saved
    });
  };
}

export function getAll() {
  return async function (dispatch) {
    try {
      const triggers = await triggersApi.getAll();
      return dispatch(fetchTriggersDataSuccess(triggers));
    }
    catch (error) {
        // OBSERVATION: At one point the Redux store seemed to disappear. I can't see why an exception occurring here should affect that though.
        throw error;
    }
  };
}

export function getAllTriggerInformation() {
  return async function (dispatch) {
    try {
      const triggers = await triggersApi.getAllTriggerInformation();
      return dispatch(fetchTriggersDataSuccess(triggers));
    }
    catch (error) {
        // OBSERVATION: At one point the Redux store seemed to disappear. I can't see why an exception occurring here should affect that though.
        throw error;
    }
  };
}
