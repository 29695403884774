import React from 'react';
import PropTypes from 'prop-types';

const PreviousNavigation = (props) => {
  return (
    <div className="padded-horizontal previous-navigation padded-vertical">
      <div className="padded-horizontal">
        <a href={props.url} className="previous-navigation__link">
          <span className="previous-navigation__arrow-icon vertical-middle"></span>
          <span className="previous-navigation__text vertical-middle">{props.text}</span>
        </a>
      </div>
    </div>
  );
};

PreviousNavigation.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default PreviousNavigation;
