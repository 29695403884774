import cookies from 'utilities/cookieUtilities';
import cookieNames from 'constants/cookieNames';

export const generateRddApiConfig = timeoutMilliseconds => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Api-Key': process.env.API_KEY,
  };

  // OBSERVATION(DC): Although the "cookies.get" is wrapped up in session.getJwtPayload() in sessionUtilities and although I did not get any errors occurring in the
  //                  page, importing that library caused a weird error in one of the tests where it complained about not being to access a property of an empty object.
  //                  The error was not to do with our "non-relative" import syntax.
  //                  It appeared to be related to "circular imports" where the sessionUtilities imported the usersApi and that imported along a chain which lead to
  //                  this file, which was importing sessionUtilities. Since this is only a one-off config type file I just get the cookie manually instead as it may
  //                  be that circular dependencies in general may not be a good idea and the breaking test may be an indication of this.
  const jwt = cookies.get(cookieNames.jwt);
  if (jwt) {
    headers['Authorization'] = 'Bearer ' + jwt;
  }

  return {
    baseURL: process.env.API_URL,
    headers,
    timeout: timeoutMilliseconds,
  };
};

const defaultApiCallTimeoutMilliseconds = 15000;

export default generateRddApiConfig(defaultApiCallTimeoutMilliseconds);
