/* eslint-disable import/default */

import React from 'react';
import {render} from 'react-dom';
import {AppContainer} from 'react-hot-loader';
import configureStore, {history} from './store/configureStore';
import Root from 'components/Root';
import {AppInsights} from "applicationinsights-js";

// import 'styles/demo/demo.scss'; // Yep, that's rinpm buildght. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
require('favicon.ico'); // Tell webpack to load favicon.ico
import 'web.config';
import 'styles/styles.scss';

const store = configureStore();

AppInsights.downloadAndSetup({ instrumentationKey: process.env.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY });

render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./components/Root', () => {
    const NewRoot = require('./components/Root').default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById('app')
    );
  });
}

// DC: I did this.
export default function getCurrentReduxState() {
  return store.getState();
}
