import {PropTypes} from 'prop-types';
import * as channelTypes from 'constants/channelTypes';
import * as searchComparisons from 'constants/searchComparisons';
import * as R from 'ramda';

const detailResolutionValues = ['OneSecond', 'FiveSeconds', 'TenSeconds', 'ThirtySeconds', 'OneMinute', 'FiveMinutes', 'TenMinutes', 'ThirtyMinutes', 'OneHour'];
export const searchComparisonValues = [{value: searchComparisons.GREATER_THAN, name: '>'}, {value: searchComparisons.EQUAL_TO, name: '='}, {value: searchComparisons.LESS_THAN, name: '<'}];

export const detailResolution = R.zipObj(detailResolutionValues, detailResolutionValues);

export const graphSettingsPropTypes = PropTypes.shape({
  // channels: PropTypes.arrayOf(PropTypes.object),              // Can introduce an extra shape if keeping.
  // detailResolution: PropTypes.oneOf(detailResolutionValues),
  // vehicleCode: PropTypes.string,
}).isRequired;

const findVehicle = (vehicleCode, vehicles) => R.find(R.propEq('value', vehicleCode))(vehicles)
export const findVehicleByCode = (vehicleCode, vehicles) => R.find(R.propEq('vehicleCode', vehicleCode))(vehicles)

const detailResolutions = R.map(detailResolution => { return {
  value: detailResolution,
  label: detailResolution,
  isDisabled: detailResolution.startsWith('Ten'),
}})(detailResolutionValues);

const findDetailResolution = detailResolution => R.find()(R.propEq('value', detailResolution))(detailResolutions)

export const getChannelIdsFromOptions = channelIdSelectOptions => R.pluck('value')(channelIdSelectOptions);
export const getChannelColoursFromOptions = channelIdSelectOptions => R.pluck('colour')(channelIdSelectOptions);

export const mapToVehicleSelectOptions = R.map(vehicle => { return {
  // vehicleId looked like the obvious candidate for value but other systems, like IFM, and people know nothing about this and only know about the vehicleCode.
  value: vehicle.vehicleCode,
  label: vehicle.vehicleCode,
}});

export const mapToChannelSelectOptions = R.map(channel => { return {
  value: channel.channelId,
  label: `${channel.channelId} ${channel.name}`,
  colour: channel.colour,
}});

const getChannelsOfType = type => R.filter(R.propEq('channelType', type));
export const getAnalogueChannels = channels => getChannelsOfType(channelTypes.ANALOGUE_CHANNEL)(channels);
export const getDigitalChannels = channels => getChannelsOfType(channelTypes.DIGITAL_CHANNEL)(channels);

const getChannels = (channels, allChannels) => R.innerJoin((cso, cid) => cso.value === cid)(allChannels)(getChannelIdsFromOptions(channels).sort());

export const selectOptions = {
  findVehicle, detailResolutions, findDetailResolution, getChannels, getChannelIdsFromOptions, getChannelColoursFromOptions,
  mapToVehicleSelectOptions, mapToChannelSelectOptions,
}

const containsText = text => x => R.join(', ', [x.name.toLowerCase(), x.shortName.toLowerCase()]).includes(text);
const nameContainsText = text => x => R.join(', ', [x.name.toLowerCase()]).includes(text);
const vehicleCodeContainsText = text => x => x.vehicleCode.includes(text);
export const filterChannels = (channels, searchString) => R.filter(containsText(searchString.toLowerCase()), channels);
export const filterPresets = (presets, searchString) => R.filter(nameContainsText(searchString.toLowerCase()), presets);
export const filterByName = (filterArray, searchString) => R.filter(nameContainsText(searchString.toLowerCase()), filterArray);
export const filterByVehicleCode = (filterArray, searchString) => R.filter(vehicleCodeContainsText(searchString), filterArray);

export const getGraphFromGraphs = (graphId, graphs) => R.find(R.propEq('graphId', graphId), graphs);

export const getChannelColour = (channel) => {
  if (channel.color != null && channel.color.length > 0 ) {
    return channel.color;
  } else {
    return channel.defaultColor;
  }
}

export const getChannelColours = (channels) => {
  let colours = []
  for (let i = 0; i < channels.length; i++) {
    colours.push(getChannelColour(channels[i]));
  }
  return colours;
}

export const getChannelByShortName = (channelName, channels) => R.find(R.propEq('shortName', channelName))(channels);

// Some Ramda sorting examples: https://gist.github.com/orther/0081dda0006fb2e23fdd41b7a8c648e0
// export const sortByNameCaseInsensitive = R.sortBy(R.compose(R.toLower, R.prop('name')));
export const sortByNameCaseInsensitive = R.sortWith([
  // As things stand, this should always be the first item in any ordering of Channels.
  R.ascend(R.prop('priorityGroup')),
  R.ascend(R.compose(R.toLower, R.prop('name')))
]);

export const filterSelectedChannel = (channels, selectedChannel) => {
  var index = channels.findIndex(x => x.channelId === selectedChannel.channelId);
  if (index > -1) {
    channels.splice(index, 1);
  }
  return channels;
}

export const filterSelectedChannels = (channels, selectedGraphChannels) => {
  const selected = [].concat.apply([], selectedGraphChannels);
  for (let i = 0; i < selected.length; i++){
    channels = filterSelectedChannel(channels, selected[i]);
  }
  return channels;
}

export const getPresetById = (presetId, presets) => {
  let preset = null;
  const index = presets.findIndex(x => x.presetId === presetId);
  if (index > -1) {
    preset = presets[index];
  }
  return preset;
}

export const copy = (o) => {
  let output, v, key;
  output = Array.isArray(o) ? [] : {};
  for (key in o) {
      v = o[key];
      output[key] = (typeof v === "object") ? copy(v) : v;
  }
  return output;
}

export const getGraphsChannels = (graphs) => {
  let channels = [];
  for (let i = 0; i < graphs.length; i++){
    channels = [...channels, ...graphs[i].channels];
  }
  return channels;
}