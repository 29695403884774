import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import DialogueTitle from 'components/DialogueTitle';
import DialogueButtons from 'components/DialogueButtons';
import sharingApi from 'api/sharingApi';
import shareIcon from 'images/share_icon_black.png';
import Resources from 'utilities/Resources';

class ShareDialogueContent extends React.Component {
  static propTypes = {
      onRequested: PropTypes.func.isRequired,
      onClose: PropTypes.func.isRequired,
      graphSettings: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context);

    // get from env variables
    this.defaultUrl = process.env.APP_URL;

    this.state = {
      graphSettings: this.props.graphSettings,
      shareId: '',
      copyStatus: 'Copy',
      shareUrl: ''
    };

    this.initialState = this.state;

    this.shareUrlInput = React.createRef();
  }

  close = () => {
    this.setState(this.initialState, () => {
      this.props.onClose();
    });
  };

  async componentDidMount() {
    let api = {};
    try {
      api = await sharingApi.saveSettings({configuration: JSON.stringify(this.state.graphSettings)});
      const shareUrl = this.defaultUrl + '?shareId=' + api;
      this.setState({
        sharingId: api,
        shareUrl
      });
    } catch (error) {
      api.error = error;
    }

    this.props.onRequested(api);
  }

  render = () => {
    return (
      <React.Fragment>
        <DialogueTitle title="Share" />
        <div className="padded">
          <div className="padded">
            <div className="margin-vertical padded-vertical text-center">
              <img src={shareIcon} className="margin-vertical padded-vertical" />
              <br />
              <span className="padded-vertical">
                {Resources.localizedString.copyInstruction}
              </span>
              <div className="margin-vertical padded-vertical text-center">
                <input type="text" ref={this.shareUrlInput} readOnly={true} value={this.state.shareUrl} className="margin-horizontal textbox-wide inline" />
                {
                  /* will only render the copy button if the browser supports JS copy query */
                  /* otherwise button is hidden and user will have to manually select & copy */
                  document.queryCommandSupported('copy')
                    ? <button className="button inline" onClick={this.copyToClipboard}>
                      {this.state.copyStatus}
                      </button>
                      : null}
              </div>
            </div>
          </div>
        </div>
        <DialogueButtons
          cancelFunction={this.close}
          confirmFunction={this.close}
          confirmButtonText="OK" />
      </React.Fragment>
    );
  }

  copyToClipboard = (e) => {
    //select text from referenced input and copy value to clipboard
    this.shareUrlInput.current.select();
    document.execCommand('copy');

    //re-focus to button so that text is not left selected
    e.target.focus();

    //update to indicate that text has been copied
    this.setState({ copyStatus: 'Copied!' });
  }
}

function mapStateToProps(state) {
  return {
    copyStatus: state.copyStatus,
    graphSettings: state.graphSettings
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export { ShareDialogueContent as ShareDialogueContentNoRedux };

const ConnectedShareDialogueContent = connect(mapStateToProps, mapDispatchToProps)(ShareDialogueContent);
export default ConnectedShareDialogueContent;
