import * as R from 'ramda';

const objectUtilities = {};

objectUtilities.isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }

  return true;
}

// Pinched from: https://github.com/ramda/ramda/wiki/Cookbook#map-keys-of-an-object-rename-keys-by-a-function
objectUtilities.mapKeys = R.curry((fn, obj) => R.fromPairs(R.map(R.adjust(0, fn), R.toPairs(obj))));

objectUtilities.propertiesToLower = objectUtilities.mapKeys(x => x.toLowerCase());

export default objectUtilities;
