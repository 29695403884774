import React from 'react';
import {Route} from 'react-router-dom';

// DC: This was all my idea. Was having trouble trying to find the modern way to do routing. Previous to React Router 4, static routing, where all the routes are
//     defined in one place, was the standard. Now dynamic routing, using react-router-dom, seems to be more the order of the day.
//     https://reacttraining.com/react-router/web/guides/philosophy provides comprehensive documentation but seems more like a series of examples of the different
//     things you can do. React Slingshot utilised <Switch> in the <App> component.
//     One thing that seems certain to me is we still need to define information about routes and the components they are related to in a way that can be
//     centrally referenced by components. For example, it looks rather dirty to have the URL of a route in magic strings.

// These are tutorials for React Router v4:
//    https://www.sitepoint.com/react-router-v4-complete-guide/
//    https://medium.com/@pshrmn/a-simple-react-router-v4-tutorial-7f23ff27adf
// The second link mentions dealing with more complex routes: https://github.com/pillarjs/path-to-regexp/tree/v1.7.0

const route = (url, component) => <Route path={url} component={component} />;

// Route will not allow any extra segments after the specified url.
const exactRoute = (url, component) => <Route exact path={url} component={component} />

export {route, exactRoute};

export default {
  home: '/:vehicleCode?',
  admin: {
    triggers: {
      index:'/admin/triggers',
      addEditTrigger: '/admin/triggers/addEditTrigger/:triggerId?'
    },
    presets:{
      index: '/admin/presets',
      edit: '/admin/presets/edit/:presetId?'
    },
    vehicleGroups: {
      index: '/admin/vehicle-groups',
      addEdit: '/admin/vehicle-groups/add-edit/:vehicleGroupId?'
    }
  },
  demo: {
    demoPage: '/demo',
    home: '/demo/home',
    jwtTest: '/demo/jwt-test',
    fuelSavings: '/demo/fuel-savings',
    about: '/demo/about/:topic',
    // about: '/demo/about/:topic?',    // See <Route /> comment in App.js.
    graphs: '/test/graphs',
  }
};
