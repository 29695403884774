const functions = {};

functions.defaultDebounceDelayMilliseconds = 600;

// https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf
functions.debounce = (func, delay) => {
  delay = delay || functions.defaultDebounceDelayMilliseconds;

  let inDebounce;

  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

// https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf
functions.throttle = (func, limit) => {
  let lastFunc;
  let lastRan;

  return function () {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function () {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

export default functions;
