import * as types from 'constants/actionTypes';

export function addDistanceHairline(xval) {
  return function (dispatch) {
    return dispatch({
      type: types.ADD_DISTANCE_HAIRLINE,
      xval,
    });
  };
}

export function moveDistanceHairline(oldXval, newXval) {
  return function (dispatch) {
    return dispatch({
      type: types.MOVE_DISTANCE_HAIRLINE,
      oldXval,
      newXval,
    });
  };
}

export function deleteDistanceHairline(xval) {
  return function (dispatch) {
    return dispatch({
      type: types.DELETE_DISTANCE_HAIRLINE,
      xval,
    });
  };
}

export function deleteAllDistanceHairlines() {
  return function (dispatch) {
    return dispatch({
      type: types.DELETE_ALL_DISTANCE_HAIRLINES,
    });
  };
}

// Start and/or end times of selected range (not detail) have changed.
export function selectedRangeChanged(rangeStartXval, rangeEndXval) {
  return function (dispatch) {
    return dispatch({
      type: types.DISTANCE_HAIRLINES_SELECTED_RANGE_CHANGED,
      rangeStartXval,
      rangeEndXval,
    });
  };
}
