import React from 'react';
import {PropTypes} from 'prop-types';
import * as R from 'ramda';
import {connect} from 'react-redux';
import {getGraphsChannels} from 'utilities/graphSettings';
import DialogueTitle from 'components/DialogueTitle';
import DialogueButtons from 'components/DialogueButtons';
import DateTimePicker from 'components/DateTimePicker';
import exportApi from 'api/exportDataApi';
import 'styles/exports.scss';
import moment from 'moment';
import AddEmails from 'components/AddEmails';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {getDygraphsArrayWithDigitalGraphsFirst} from 'utilities/dygraphsFunctions';
import Resources from 'utilities/Resources';
import * as digitalGraphSettings from 'constants/digitalGraphSettings';
import loadingIcon from 'images/ajax-loader.gif';
window.html2canvas = html2canvas;

class ExportDialogueContent extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onRequested: PropTypes.func.isRequired,
    graphSettings: PropTypes.object.isRequired,
    onExportTypeChange: PropTypes.func.isRequired,
    setPdfExportRequested: PropTypes.func.isRequired,
    userEmail: PropTypes.string
  };

  constructor(props, context) {
    super(props, context);

    let emails = [];
    if (this.props.userEmail) {
      emails = [this.props.userEmail];
    }

    this.state = {
      // email: '',
      emails,
      exportType: 'csv',
      valid: true,
      validEndDate: true,
      validStartDate: true,
      emailValid: true,
      rangeValid: true,
      rangeStart: this.getStartDate(),
      rangeEnd: moment(this.props.graphSettings.rangeEnd),
      exportRequested: false
    };

    this.initialState = this.state;
    this.submit = this.submit.bind(this);
    this.resources = Resources.localizedString;
  }

  getStartDate = () => {
    let startDate = moment(this.props.graphSettings.rangeEnd).subtract(1, 'days');
    if(startDate.valueOf() < moment(this.props.graphSettings.rangeStart).valueOf()) {
      startDate = moment(this.props.graphSettings.rangeStart);
    }
    return startDate;
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      if (this.props.graphSettings.rangeStart.valueOf() !== prevProps.graphSettings.rangeStart.valueOf()
        || this.props.graphSettings.rangeEnd.valueOf() !== prevProps.graphSettings.rangeEnd.valueOf()) {
        this.setState({
          rangeStart: this.getStartDate(),
          rangeEnd: moment(this.props.graphSettings.rangeEnd),
        });
      }

      if (JSON.stringify(this.props.userEmail) !== JSON.stringify(prevProps.userEmail)) {
        // Ensures email addresses are unique even if already added
        const selectedEmails = [...this.state.emails, ...this.props.userEmail];
        const emailSet = new Set(...selectedEmails);
        this.setState({
          emails: [...emailSet]
        });
      }
    }
  }

  radioChange = (e) => {
    const value = e.target.value;
    this.setState({
      exportType: value
    }, () => {
      this.props.onExportTypeChange(value);
    });
  }

  emailListUpdateCallback = (emails, changesMade) => {
    this.setState({
      emails
    });
  }

  close = () => {
    this.setState(this.initialState, () => {
      this.props.onClose();
    });
  };

  validateEmails = () => {
    const emailValid = this.state.emails.length > 0;
    this.setState({emailValid});
    return emailValid;
  };

  validate = () => {
    const rangeValid = this.validateDates();
    const emailValid = this.validateEmails();
    // if(emailValid && rangeValid)
    if (emailValid && rangeValid)
    {
      this.setState({
        valid: true
      });
      return true;
    } else {
      this.setState({
        valid: false
      });
      return false;
    }
  };

  validateDates = () => {
    const startDate = this.state.rangeStart;
    const endDate = this.state.rangeEnd;
    const duration = moment.duration(endDate.diff(startDate));

    if (this.state.validStartDate && this.state.validEndDate) {
      // The three needs to be a config value
      if (duration.asDays() <= 1 && duration.asDays() >= 0 && endDate <= moment() && startDate <= moment()) {
        this.setState({rangeValid: true});
        return true;
      } else {
        this.setState({rangeValid: false});
        return false;
      }
    } else {
      this.setState({rangeValid: false});
      return false;
    }
  };

  setStartDate = (date, valid) => {
    this.setState({
      rangeStart: moment(date),
      validStartDate: valid
    })
  }

  setEndDate = (date, valid) => {
    this.setState({
      rangeEnd: moment(date),
      validEndDate: valid
    })
  }

  buildLegendHtml = (dygraph) => {
    let legendDiv = document.createElement('div');

    let legendHtml = '';

    for (let index = 0; index < dygraph.channels.length; index++) {
      const channel = dygraph.channels[index];
      let backgroundColour = '';
      if (index == 0 || index == 4) {
        legendHtml += `<div style="display:inline-block; width: 515px">`;
      }
      if (channel.color) {
        backgroundColour = channel.color;
      } else {
        backgroundColour = channel.defaultColor;
      }
      legendHtml += `<div style="padding: 3px 20px;"><span style="display:inline-block; vertical-align:middle; margin-right:10px; width:15px; height:15px; background-color:${backgroundColour}"></span><spanstyle="display:inline-block; vertical-align:middle;">${channel.name} (${channel.shortName})</span></div>`;
      if (index == 3 || index == 7) {
        legendHtml += "</div>";
      }
    }

    legendDiv.style.paddingTop = "20px";
    legendDiv.style.overflow = "hidden";
    legendDiv.innerHTML = legendHtml;

    return legendDiv;
  }

  submit = async function () {
    let response = {};
    if(this.state.exportType ==='csv') {
      const valid = this.validate();
      if (valid) {
        const digitalChannels = getGraphsChannels(this.props.graphSettings.digitalGraphs);
        const analogueChannels = getGraphsChannels(this.props.graphSettings.analogueGraphs);
        const channels = [...digitalChannels, ...analogueChannels];
        const channelIds = R.pluck('channelId')(channels);
        const request = {
          emails: this.state.emails,
          vehicleCode: this.props.graphSettings.selectedVehicleCode,
          channelIds: channelIds,
          detailStartDatetime: this.state.rangeStart.toISOString(),
          detailEndDatetime: this.state.rangeEnd.toISOString(),
          userAdjustedWheelDiameterMillimetres: this.props.graphSettings.userAdjustedWheelDiameterMillimetres,
        };

        try {
          let apiResponse = await exportApi.getExportForVehicle(request);
          //Check things in here or get data attribute if need to
          response = apiResponse;
        } catch (error) {
          // Set error
          response.error = error;
        }

        this.setState(this.initialState);
        this.props.onRequested(true, this.state.exportType, response);
      }
    } else {
      this.setState({
        exportRequested: true
      }, () => {
        this.props.setPdfExportRequested(true);
      });

      // Get graphs from dygraphs array
      const dygraphs = getDygraphsArrayWithDigitalGraphsFirst();

      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'px'
      });

      let chartSection = document.querySelector(".chart-section");
      chartSection.classList.remove("overflow-hidden");

      for (let index = 0; index < dygraphs.length; index++) {
        let dygraph = dygraphs[index];

        // Only add to pdf if graph is in array of used dygraphs
        if (dygraph) {
          const legendDiv = this.buildLegendHtml(dygraph);

          // Get the original size for the graph element
          let originalHeight = dygraph.height_;
          let originalWidth = dygraph.width_;

          // Work out what to size to scale it to fit on the a4 page
          const width = 1030;
          let height = 350;
          dygraph.height = height;
          dygraph.width = width;

          if (dygraph.isDigital) {
            height = Math.round((dygraph.channels.length * 60) + 80);
            if (height < digitalGraphSettings.DIGITAL_GRAPH_MIN_HEIGHT) {
              height = digitalGraphSettings.DIGITAL_GRAPH_MIN_HEIGHT;
            }
            dygraph.height = height;
          }

          // Get the graph element
          const input = document.getElementById("graphdiv-" + dygraphs[index].graphId);
          input.style.height = height + "px";
          input.style.overflow = "visible";

          const parent = input.parentNode;
          parent.style.setProperty('width', `${width}px`);
          parent.style.overflow = 'visible';
          parent.appendChild(legendDiv);

          // set the scaled width
          input.style.setProperty('width', `${width}px`, 'important');
          dygraph.resize();

          await html2canvas(parent, {scale: 1}).then((canvas) => {
            dygraph.height = originalHeight;
            dygraph.width = originalWidth;
            input.style.height = originalHeight + "px";
            input.style.width = originalWidth + "px";
            input.style.removeProperty('overflow');
            parent.style.removeProperty('width');
            parent.style.overflow = 'hidden';
            dygraph.resize();
            parent.removeChild(legendDiv);
            // Need to grab the canvas produced from theHTML input
            const imgData = canvas.toDataURL('image/png');
            if (index != 0) {
              pdf.addPage();
            }
            const startDate = this.props.graphSettings.detailRangeStart.local().format(this.resources.fullDateTimeFormat);
            const endDate = this.props.graphSettings.detailRangeEnd.local().format(this.resources.fullDateTimeFormat);

            // Add title strings and graph Id
            pdf.text(`${this.resources.vehicle} ${this.props.graphSettings.selectedVehicleCode} - (${startDate} - ${endDate})`, 25, 35);
            pdf.addImage(imgData, 'PNG', 12, 45);
          }).catch((error) => {
            let response = {};
            response.error = error;
            this.props.setPdfExportRequested(false);
            this.props.onRequested(true, this.state.exportType, response);
          });
        }
      }

      chartSection.classList.add("overflow-hidden");
      pdf.save(`export_${this.props.graphSettings.selectedVehicleCode}_${this.props.graphSettings.detailRangeStart.local().format("DD-MM-YYYY_HH-mm-ss")}_${this.props.graphSettings.detailRangeEnd.local().format("DD-MM-YYYY_HH-mm-ss")}.pdf`);
      this.setState({
        exportRequested: false
      });
      this.props.setPdfExportRequested(false);
      this.close();
    }
  };

  render() {
    const resources = Resources.localizedString;
    return (
      <React.Fragment>
        <DialogueTitle title="Export" />
        <div className="padded">
          <div className="padded-horizontal">
              <div className="margin-bottom padded-vertical flexbox flex-space-between">
                <div>
                  <label htmlFor="export-radio-csv" className="export-choice margin-bottom">
                    <span className="label">{resources.rawData}</span>
                    <input type="radio" onChange={this.radioChange} name="export-radio" id="export-radio-csv" className="text margin-bottom" value="csv" checked={this.state.exportType === 'csv'} />
                    <span className="export-choice__label export-choice__label--csv">{resources.csv}</span>
                  </label>
                </div>
                <div>
                  <label htmlFor="export-radio-pdf" className="export-choice margin-bottom">
                    <span className="label">Graphs</span>
                    <input type="radio" onChange={this.radioChange} name="export-radio" id="export-radio-pdf" className="text margin-bottom" value="pdf" />
                    <span className="export-choice__label export-choice__label--pdf">PDF</span>
                  </label>
                </div>
              </div>
              {this.state.exportType === 'csv' &&
                <React.Fragment>
                  <span className="block margin-bottom">{resources.selectTwentyFourHourPeriodToExport}</span>
                  <div className="flexbox">
                    <div className="margin-bottom padded-bottom flexbox__item--same-size">
                      <DateTimePicker date={this.state.rangeStart} classNames="margin-right inline-block vertical-bottom" datePickerTitle="Start date and time" updateDateCallback={this.setStartDate} />
                      <DateTimePicker date={this.state.rangeEnd} classNames="margin-horizontal inline-block vertical-bottom" datePickerTitle="End date and time" updateDateCallback={this.setEndDate} />
                      <span className={"message message--error" + (!this.state.rangeValid ? ' inline-block vertical-bottom margin-left' : '')}>{resources.pleaseSelectValidDateRange}</span>
                      <div className="clear"></div>
                    </div>

                    <div className="margin-bottom margin-left flexbox__item--same-size">
                      <label htmlFor="export-email">{resources.emailExportTo}</label>
                      <div className="inline-block vertical-top margin-right">
                        <AddEmails emailListUpdateCallback={this.emailListUpdateCallback}
                          selectedEmails={this.state.emails}/>
                      </div>
                      {!this.state.emailValid ?
                        <span className="message message--error inline-block vertical-top">{resources.pleaseEnterValidEmailAddress}</span>
                      : null}
                    </div>
                  </div>
                </React.Fragment>
              }
              {this.state.exportType === 'pdf' && this.state.exportRequested &&
                <React.Fragment>
                  <div className="margin padded text-center">
                    <img src={loadingIcon} alt="Loading" />
                  </div>
                  <p className="text-center">{this.resources.pdfExportText}</p>
                </React.Fragment>
              }
          </div>
        </div>
        <DialogueButtons
          cancelButtonEnabled={!this.state.exportRequested}
          confirmButtonEnabled={!this.state.exportRequested}
          cancelButtonText={this.resources.close}
          cancelFunction={this.close}
          confirmFunction={this.submit}
          confirmButtonText={resources.export} />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    graphSettings: state.graphSettings,
    userEmail: state.user.email
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}
export {ExportDialogueContent as ExportDialogueContentNoRedux};

const ConnectedExportDialogueContent = connect(mapStateToProps, mapDispatchToProps)(ExportDialogueContent);
export default ConnectedExportDialogueContent;
