import React from 'react';
import { connect } from 'react-redux';

import Resources from 'utilities/Resources';
import queryStrings from 'utilities/queryStringUtilities';

class ExternalLoginSelector extends React.Component {
  constructor(props, context) {
    super(props, context);

    const queryStringPropertyArrays = queryStrings.getPropertyArrays(window.location);
    this.attempts = queryStrings.getValue('attempts', queryStringPropertyArrays);
  }

  render() {
    return (
      <div className="login">
        <h1 className="login-title padded">Sign In</h1>
        <div className="login-form-container padded--double text-center">

          {
            this.attempts > 0 &&
            <span className={"message message--error block margin-vertical"}>
              Unable to connect to RDD. Please try again, or close the tab to return to IFM.
            </span>
          }

          <span>{Resources.localizedString.signIntoRddWith}</span>

          <a href={`${process.env.IFM_APP_URL}/Login.aspx?Rdd=true&ReturnUrl=${window.location.href}`} className="link-button padded backIfmBlue">
            <span className="ifm-icon vertical-middle" />
            <span className="vertical-middle">{Resources.localizedString.appNameIfm}</span>
          </a>

          <a href={`${process.env.EDS_APP_URL}/Login.aspx?Rdd=true&ReturnUrl=${window.location.href}`} className="link-button padded backEdsOrange">
            <span className="eds-icon vertical-middle" />
            <span className="vertical-middle">{Resources.localizedString.appNameEds}</span>
          </a>

        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export { ExternalLoginSelector as ExternalLoginNoRedux };

export default connect(mapStateToProps, mapDispatchToProps)(ExternalLoginSelector);
