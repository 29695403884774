import React from 'react';
import {PropTypes} from 'prop-types';
import * as R from 'ramda';
import Modal from 'react-modal';
import DialogueButtons from 'components/DialogueButtons';
import Resources from 'utilities/Resources';
import 'styles/_dialogue.scss';

Modal.setAppElement('#app')

const DeleteDialogue = (props) => {
  let additionalObjectsToBeDeletedList = '';
  if (props.additionalObjectsToBeDeleted && props.additionalObjectsToBeDeleted.length) {
    const additionalObjectsToBeDeleted = R.pluck("name")(props.additionalObjectsToBeDeleted);
    additionalObjectsToBeDeletedList = additionalObjectsToBeDeleted.join(", ");
  }

  const defaultProps = {
    showCannotBeUndone: true
  }

  const mergedProps = {...defaultProps, ...props};

  return(
    <Modal
      className={"dialogue--delete"}
      contentLabel="Delete Modal"
      onRequestClose={mergedProps.onCancel}
      onClose={mergedProps.onConfirm}
      isOpen={mergedProps.showDialogue}
      style={{
        content: {
          width: 560
        }
      }}>
        <div className="padded half-margin">
          <h2>{props.dialogueTitle}</h2>
          <div className="padded margin">
            <div className="message message--delete block">
              <span className="block margin-bottom padded-bottom">{mergedProps.dialogueText}
              {mergedProps.children}
              </span>
              {mergedProps.showCannotBeUndone ?
                <span>{Resources.localizedString.thisActionCannotBeUndone}</span>
                : null}
            </div>
            {mergedProps.additionalObjectsToBeDeleted && mergedProps.additionalObjectsToBeDeleted.length > 0 ?
              <div className="padded margin-top additional-objects-list">
                {additionalObjectsToBeDeletedList}
              </div>
              : null}
          </div>
        </div>
        <DialogueButtons
          cancelButtonText={mergedProps.cancelButtonText}
          showCancelButton={true}
          cancelFunction={mergedProps.onCancel}
          confirmFunction={mergedProps.onConfirm}
          confirmButtonText={mergedProps.confirmButtonText} />
    </Modal>
  );
}

DeleteDialogue.propTypes = {
  showCannotBeUndone: PropTypes.bool,
  showDialogue: PropTypes.bool.isRequired,
  dialogueTitle: PropTypes.string.isRequired,
  dialogueText: PropTypes.string,
  confirmButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  additionalObjectsToBeDeleted: PropTypes.array
};

export default DeleteDialogue;
