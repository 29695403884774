import {copy} from 'utilities/graphSettings';
// NOTE: We could just import {map} if we only use that but I think treeshaking might get rid of unused stuff:
//       https://stackoverflow.com/questions/42051588/wildcard-or-asterisk-vs-named-or-selective-import-es6-javascript
// NOTE: Pay no attention to the light bulb - without the "* as" we do not have access to the map() method.
import * as R from 'ramda';

const aggregations = {};

aggregations.fromTo = (from, to) => R.unfold(x => x <= to && [x, x + 1])(from);

aggregations.getLabels = R.map(x => x.toString());

// We could, of course, use the javascript map() method but Ramda might be of great use to us.
aggregations.transformToValueMinMaxFromChannels =
  requestedChannels =>
    R.map(apiDataPoint => {
      let pointsForAllChannels = R.map(channelId =>
        R.find(p => p.channelId === channelId)(apiDataPoint.points) || null
      )(requestedChannels);

      // NOTE: We now are using p.ValueMax for the data point value as that is an actual reading whereas p.value is only an average.
      let valuesForEachChannel = R.map(p => p && [p.valueMin, p.valueMax, p.valueMax])(pointsForAllChannels);
      let timePlusValuesForEachChannel = [new Date(apiDataPoint.rmdDateTime), ...valuesForEachChannel];
      return timePlusValuesForEachChannel
    });

let ensureValuesForAllChannelsAreNonEmpty = (dummyPoint, channelsChartData) => {
  // NOTE: The dummy point will contain values for at least 1 channel, but not necessarily all. We will ensure that values for all channels in the dummyPoint
  //       are non-empty.
  for (let i = 1; i < dummyPoint.length; i++) {
    // Go through points backwards until get to the first one where the value non-empty.
    if (!dummyPoint[i] || R.isEmpty(dummyPoint[i])) {
      for (let j = channelsChartData.length - 2; j >= 0; j--) {
        if (channelsChartData[j][i] && !R.isEmpty(channelsChartData[j][i])) {
          dummyPoint[i] = channelsChartData[j][i];
          break;
        }
      }
    }
  }
}

aggregations.addDummyDataPointForStartTimeIfNotThere =
  (channelsChartData, requestStartTime) => {
    if (channelsChartData.length > 0 && new Date(requestStartTime) < channelsChartData[0][0]) {
      const dummyPoint = [new Date(requestStartTime)];
      for (let i = 0; i < channelsChartData[0].length - 1; i++) {
        dummyPoint.push(null);
      }
      channelsChartData.unshift(dummyPoint);

      // Returning the Dates defining the interval of the channel readings and the REAL channel readings can be used by the caller.
      return {
        underlayStartTime: channelsChartData[0][0],
        underlayEndTime: channelsChartData[1][0],
      };
    }

    return null;
  };

  aggregations.addDummyDataPointForEndTimeIfNotThere =
  (channelsChartData, requestEndTime) => {
    if (channelsChartData.length > 0 && new Date(requestEndTime) > channelsChartData[channelsChartData.length - 1][0]) {
      const dummyPoint = copy(channelsChartData[channelsChartData.length - 1]);
      dummyPoint[0] = new Date(requestEndTime);
      ensureValuesForAllChannelsAreNonEmpty(dummyPoint, channelsChartData);
      channelsChartData.push(dummyPoint);

      // Returning the Dates defining the interval of the channel readings and the REAL channel readings can be used by the caller.
      return {
        underlayStartTime: channelsChartData[channelsChartData.length - 2][0],
        underlayEndTime: channelsChartData[channelsChartData.length - 1][0],
      };
    }

    return null;
  };

export default aggregations;
