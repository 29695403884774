import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as dialogueActions from 'actions/dialogueActions';
import * as presetActions from 'actions/presetActions';
import * as referenceDataActions from 'actions/referenceDataActions';
import ChannelSelectDialogue from 'components/ChannelSelectDialogue';
import {copy} from 'utilities/graphSettings';
import {Link} from 'react-router-dom';
import PageLayout from 'components/PageLayout';
import moment from 'moment';
import routes from 'routes';
import Resources from 'utilities/Resources';
import ReactTable from "react-table";
import presetsApi from 'api/presetsApi';
import DeleteDialogue from './DeleteDialogue';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/_toast-notifications.scss';
import {Slide} from 'utilities/reactToastifyHelpers';
import "react-table/react-table.css";
import 'styles/_table.scss';

class ManagePresets extends React.Component {
  static propTypes = {
    dialogueActions: PropTypes.object.isRequired,
    presetActions: PropTypes.object.isRequired,
    referenceDataActions: PropTypes.object.isRequired,
    presets: PropTypes.array.isRequired,
    savedPreset: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.resources = Resources.localizedString;

    const presets = this.setAnalogueDigitalCounts(this.props.presets);
    this.state = {
      presets: presets,
      loading: true,
      showDeleteDialogue: false,
      toBeDeletedId: null,
      deleteApiResult: null
    }

    this.editPresetUrl = routes.admin.presets.edit.replace('/:presetId?', '');
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);

    // Shows toast notification if preset has previously been saved
    {this.props.savedPreset && this.showPresetSavedNotification()}
  }

  async componentDidMount(){
    let apiResult = {};
    try {
      apiResult = await presetsApi.get();
    } catch (error) {
      apiResult.error = error;
    }

    if (!apiResult.error) {
      this.props.presetActions.setPresets(apiResult);
    } else {
      this.showPresetLoadFailedNotification();
      this.setState({
        loading: false
      });
    }
  }

  setAnalogueDigitalCounts = (presets) => {
    const presetsCopy = [...presets];
    presetsCopy.forEach((preset) => {
      preset.analogueCount = this.countChannels(preset, "analogueGraphs");
      preset.digitalCount = this.countChannels(preset, "digitalGraphs");
    })
    return presetsCopy;
  }

  componentDidUpdate(prevProps){
    if (this.props.presets != prevProps.presets) {
      const presets = this.setAnalogueDigitalCounts(this.props.presets);
      this.setState({
        presets: presets,
        loading: false
      });
    }
  }

  delete = (e, presetId) => {
    this.setState({
      showDeleteDialogue: true,
      toBeDeletedId: presetId
    });
  }

  createPreset = () => {
    this.props.presetActions.setSaveNew(true);
    this.props.dialogueActions.openChannelsDialogue();
  }

  dateCell = (cellInfo) => {
    return (
      <div>
        {moment.utc(this.state.presets[cellInfo.index][cellInfo.column.id]).format(this.resources.fullDateTimeFormat)}
      </div>
    );
  }

  optionsCell = (cellInfo) => {

    const preset = this.state.presets[cellInfo.index];
    const presetId = preset.presetId;
    const editUrl = this.editPresetUrl + "/" + presetId;
    if(preset.canEdit)
    {
      return (
        <div>
          <Link to={editUrl} className="icon-button icon-button--edit margin-right">{this.resources.edit}</Link>
          <button className="icon-button icon-button--delete" onClick={(event) => {this.delete(event, presetId)}}>{this.resources.delete}</button>
        </div>
      );
    }
    else{
      return(
        <div></div>
      );
    }
  }

  countChannels = (preset, graphType) => {
    let count = 0;

    try {
      const presetObject = JSON.parse(preset.configuration);

      const graphs = presetObject[graphType];
      graphs.map((graph) => {
        count += graph.channels.length;
      })
      return count;
    } catch (e) {
      return count;
    }
  }

  setEnableDisablePreset = async function (event, cellInfo) {
    let presets = copy(this.state.presets);
    presets[cellInfo.index][cellInfo.column.id] = event.target.checked;

    let api = {};
    try {
      const request = {
        presetId: presets[cellInfo.index].presetId,
        isEnabled: event.target.checked
      }

      api = await presetsApi.setEnabled(request);

      this.props.referenceDataActions.refreshEnabledPresetsData();
      this.setState({
        presets
      });
    } catch (error) {
      // Set error and do something later if needed
      api.error = error;
    }

    this.props.presetActions.getAllPresets();
  }

  enableCell = (cellInfo) => {
    return (
      <div>
        <label className="label--wrapper" htmlFor={"preset_" + this.state.presets[cellInfo.index].presetId}>
          <input type="checkbox"
                 id={"preset_" + this.state.presets[cellInfo.index].presetId}
                 name={"preset_" + this.state.presets[cellInfo.index].presetId}
                 checked={this.state.presets[cellInfo.index][cellInfo.column.id]}
                 onChange={(event) => {this.setEnableDisablePreset(event, cellInfo)}} />
        </label>
      </div>
    );
  }

  nameCell = (cellInfo) => {
    const presetId = this.state.presets[cellInfo.index].presetId;
    const editUrl = this.editPresetUrl + "/" + presetId;
    const preset = this.state.presets[cellInfo.index];
    if(preset.canEdit)
    {
      return (
        <div>
          <Link to={editUrl}>{this.state.presets[cellInfo.index].name}</Link>
        </div>
      );
    }
    else{
      return(
        <div>{this.state.presets[cellInfo.index].name}</div>
      );
    }
  }

  onDeleteConfirm  = async function() {
    let api = {};
    try {
      api = await presetsApi.deletePreset(this.state.toBeDeletedId);
    } catch (error) {
      // Set error to be used to show notification of success/failure
      api.error = error;
    }

    this.setState({
      showDeleteDialogue: false,
      toBeDeleted: null,
      deleteApiResult: api
    }, () => {
      if (!this.state.deleteApiResult.error) {
        this.setState({
          loading: true
        });
        this.props.presetActions.getAllPresets();
        this.props.referenceDataActions.refreshEnabledPresetsData();
      }
      this.showDeleteSavedNotification();
    });
  }

  onDeleteCancel = () =>{
    this.setState({
      showDeleteDialogue: false
    })
  }

  onCreateNewPreset = () => {
    this.props.presetActions.getAllPresets();
    this.props.referenceDataActions.refreshEnabledPresetsData();
  }

  showDeleteSavedNotification = () => {
    if (!this.state.deleteApiResult.error) {
      toast(this.resources.presetSuccessfullyDeleted, {
        type: toast.TYPE.SUCCESS,
        onClose: this.setState({deleteApiResult: null})
      });
    } else {
      toast(this.resources.presetFailedToDelete, {
        type: toast.TYPE.ERROR,
        onClose: this.setState({deleteApiResult: null})
      });
    }
  }

  showPresetSavedNotification = () => {
    toast(this.resources.presetSuccessfullySaved, {
      type: toast.TYPE.SUCCESS,
      onClose: this.props.presetActions.setSaved(false)
    });
  }

  showPresetLoadFailedNotification = () => {
    toast(this.resources.presetsFailedToLoad, {
      type: toast.TYPE.ERROR
    });
  }

  render() {
    const data = this.state.presets;
    return (
      <div className="page-content">
        <ToastContainer autoClose={6000} hideProgressBar={true} transition={Slide} newestOnTop={true} />
        <ChannelSelectDialogue onPresetSave={this.onCreateNewPreset} />
        <DeleteDialogue
        showDialogue={this.state.showDeleteDialogue}
        dialogueTitle={this.resources.deletePreset}
        dialogueText={this.resources.deletingPresetWillPermanentlyRemoveIt}
        confirmButtonText={this.resources.yesDeletePreset}
        cancelButtonText={this.resources.noKeepPreset}
        onCancel={this.onDeleteCancel}
        onConfirm={this.onDeleteConfirm} />
        <h1>
          <button className="button right" onClick={this.createPreset}>{this.resources.createPreset}</button>
          {this.resources.presets}
        </h1>

        <ReactTable
          loading={this.state.loading}
          data={data}
          defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
          columns={[
            {
              Header: this.resources.name,
              accessor: "name",
              Cell: this.nameCell,
              filterable: true
            },
            {
              Header: `${this.resources.digital} ${this.resources.channels}`,
              accessor: "digitalCount",
              id: "digital"
            },
            {
              Header: `${this.resources.analogue} ${this.resources.channels}`,
              accessor: "analogueCount",
              id: "analogue"
          },
            {
              Header: this.resources.modifiedBy,
              accessor: "modifiedBy",
              filterable: true
            },
            {
              Header: this.resources.date,
              accessor: "dateModified",
              Cell: this.dateCell
            },
            {
              Header: this.resources.enabled,
              accessor: "isEnabled",
              Cell: this.enableCell
            },
            {
              Header: this.resources.options,
              accessor: "presetId",
              id: "options",
              sortable: false,
              Cell: this.optionsCell
            },
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    presets: state.preset.presets,
    savedPreset: state.preset.saved
  };
  // If do anything expensive in here, introduce memoization using a library like Reselect.
}

function mapDispatchToProps(dispatch) {
  return {
    dialogueActions: bindActionCreators(dialogueActions, dispatch),
    referenceDataActions: bindActionCreators(referenceDataActions, dispatch),
    presetActions: bindActionCreators(presetActions, dispatch)
  };
}

export {ManagePresets as ManagePresetsNoRedux};

const ConnectedManagePresets = connect(mapStateToProps, mapDispatchToProps)(ManagePresets);
export default PageLayout(ConnectedManagePresets);
