import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import HeaderProfile from 'components/HeaderProfile';
import PreviousNavigation from 'components/PreviousNavigation';
import routes from 'routes';
import {NavLink, withRouter} from 'react-router-dom';
import logo from 'images/logo.svg';
import urlUtilities from 'utilities/urlUtilities';
import objectUtilities from 'utilities/objectUtilities';

class Header extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    this.userName = hasUserSession(this.props.user) && `${this.props.user.firstName} ${this.props.user.lastName}`;
    const prevNavText = hasUserSession(this.props.user) && `Return to ${this.props.user.applicationId}`;
    const prevNavUrl = hasUserSession(this.props.user) && urlUtilities.getBackToUrlFromLoginUrl(this.props.user.loginUrl);

    let homeRoute = routes.home.replace('/:vehicleCode?', '/');

    if (this.props.match.params.vehicleCode) {
      homeRoute += `${this.props.match.params.vehicleCode}`;
    }

    return (
    <header>
      <div className="half-padded-horizontal">
        <div className="padded-horizontal">
          <NavLink exact to={homeRoute} className="app-logo left">
            <img src={logo} className="logo" alt="Logo" />
          </NavLink>
          {
            hasUserSession(this.props.user) && <HeaderProfile userName={this.userName || 'Administrator Administrator'}/>
          }
          <div className="clear"></div>
        </div>
      </div>
      {
        hasUserSession(this.props.user) && <PreviousNavigation url={prevNavUrl} text={prevNavText} />
      }
    </header>
    );
  }
}

function hasUserSession(user) {
  return !objectUtilities.isEmpty(user);
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
  // If do anything expensive in here, introduce memoization using a library like Reselect.
}

function mapDispatchToProps(dispatch) {
  return {
  };
}

export {Header as HeaderNoRedux};

const ConnectedHeader = connect(mapStateToProps, mapDispatchToProps)(Header);
export default withRouter(ConnectedHeader);
