import cookies from 'utilities/cookieUtilities';
import cookieNames from 'constants/cookieNames';

const urlUtilities = {};

urlUtilities.extractHostnameWithPort = url => urlUtilities.extractHostname(url, false);

// Copied from https://stackoverflow.com/questions/8498592/extract-hostname-name-from-string. Only modified slightly.
urlUtilities.extractHostname = (url, removePort = true) => {
  let hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("//") > -1) {
    hostname = url.split('/')[2];
  }
  else {
    hostname = url.split('/')[0];
  }

  if (removePort) {
    //find & remove port number
    hostname = hostname.split(':')[0];
  }

  //find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname;
}

// Copied from https://stackoverflow.com/questions/8498592/extract-hostname-name-from-string.
// To address those who want the "root domain," use this function:
urlUtilities.extractRootDomain = url => {
  let domain = urlUtilities.extractHostname(url),
      splitArr = domain.split('.'),
      arrLen = splitArr.length;

  //extracting the root domain here
  //if there is a subdomain
  if (arrLen > 2) {
    domain = splitArr[arrLen - 2] + '.' + splitArr[arrLen - 1];
    //check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
      //this is using a ccTLD
      domain = splitArr[arrLen - 3] + '.' + domain;
    }
  }
  return domain;
}

urlUtilities.getHostRootUrl = loginUrl => {
  const hostname = urlUtilities.extractHostnameWithPort(loginUrl);
  const hostRootUrl = loginUrl.match(new RegExp(`^.*${hostname}`, 'i'));
  return hostRootUrl && hostRootUrl[0];
};

urlUtilities.getBackToUrlFromLoginUrl = loginUrl => {
  const lastReferrerUrl = cookies.get(cookieNames.lastReferrerUrl);
  if (lastReferrerUrl) {
    return lastReferrerUrl;
  }

  const hostRootUrl = urlUtilities.getHostRootUrl(loginUrl);
  return hostRootUrl;
};

urlUtilities.doesDocumentReferrerHaveSameHostNameAsThisSite = () =>
  document.referrer.toLowerCase().includes(urlUtilities.extractHostnameWithPort(window.location.href.toLowerCase()));

export default urlUtilities;
