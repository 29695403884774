import queryStrings from 'utilities/queryStringUtilities';
import usersApi from 'api/usersApi';
import cookies from 'utilities/cookieUtilities';

const session = {};

session.getSessionQueryStringValue = () => {
  const queryStringPropertyArrays = queryStrings.getPropertyArrays(window.location);
  const queryStringValue = queryStrings.getValue('session', queryStringPropertyArrays);
  return queryStringValue;
};

session.generateSameUrlWithoutSessionQueryString = () => {
  const queryStringPropertyArrays = queryStrings.getPropertyArrays(window.location);
  const propertyArraysWithoutSession = queryStrings.removeFromPropertyArrays('session', queryStringPropertyArrays);
  const redirectUrl = queryStrings.replaceUrlSegmentFromPropertyArrays(window.location, propertyArraysWithoutSession);
  return redirectUrl;
};

session.fetchJwtFromApiAndGenerateRedirectUrl = async sessionId => {
  const redirectUrl = session.generateSameUrlWithoutSessionQueryString();
  const jwt = await usersApi.getToken(sessionId);
  return [jwt, redirectUrl];
};

// Originally copied from https://thinkster.io/tutorials/angularjs-jwt-auth/decoding-jwt-payloads
const parseJwtSection = (jwt, sectionIndex) => {
  const section = jwt.split('.')[sectionIndex];
  const base64 = section.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

session.getJwtPayload = () => cookies.get('jwt');

session.parseJwtPayload = () => {
  const jwt = session.getJwtPayload();
  return jwt ? parseJwtSection(jwt, 1) : null;
};

session.parseJwtAppUserJson = () => {
  const jwtPayload = session.parseJwtPayload();
  if (jwtPayload) {
    const appUser = JSON.parse(jwtPayload.appUserJson);
    return appUser;
  }
  return null;
};

session.userHasGeneralAccessToRdd = () => {
  const auj = session.parseJwtAppUserJson();
  return auj && auj.permissions && auj.permissions.length > 0;
};

session.getExistingJwtUserSessionId = () => {
  const auj = session.parseJwtAppUserJson();
  return auj && auj.userSessionId;
};

session.userDoesNotHaveGeneralAccessToRdd = () => !session.userHasGeneralAccessToRdd();

export default session;
