import * as actionTypes from 'constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';
import {copy} from 'utilities/graphSettings';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function sidebarValuesReducer(state = initialState.sidebarValues, action) {
  switch (action.type) {
    case actionTypes.UPDATE_SIDEBAR_VALUES: {
      // For this example, just simulating a save by changing date modified.
      // In a real app using Redux, you might use redux-thunk and handle the async call in fuelSavingsActions.js
      return objectAssign({}, state, action.sidebarValues);
    }
    case actionTypes.REMOVE_SIDEBAR_VALUES: {
      // For this example, just simulating a save by changing date modified.
      // In a real app using Redux, you might use redux-thunk and handle the async call in fuelSavingsActions.js
      let sidebarValues = copy(state);
      sidebarValues.channelValues.forEach(function (c){ delete c.value });
      sidebarValues.currentTimestamp = null;
      return objectAssign({}, sidebarValues);
    }
    default: {
      return state;
    }
  }
}
