import axios from 'axios';
import rddApiConfig from './rddApiConfig';
// "ReferenceError: regeneratorRuntime is not defined" when used async/await: https://github.com/babel/babel/issues/5085
// in the form "async => await () => ..."
// This page suggests it can be done without including the polyfill: https://www.valentinog.com/blog/how-async-await-in-react/
import '@babel/polyfill';

const vehicleGroupsApi = {};

// https://medium.com/codingthesmartway-com-blog/getting-started-with-axios-166cb0035237
// https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index

vehicleGroupsApi.get = async () => {
  const response = await axios.get('vehicle-group', rddApiConfig);
  return response.data;
}

vehicleGroupsApi.getById = async (vehicleGroupId) => {
  const response = await axios.get('vehicle-group/' + vehicleGroupId, rddApiConfig);
  return response.data;
}

vehicleGroupsApi.add = async (vehicleGroup) => {
  const response = await axios.post('vehicle-group', vehicleGroup, rddApiConfig);
  return response.data;
}

vehicleGroupsApi.edit = async (vehicleGroup) => {
  const response = await axios.put('vehicle-group', vehicleGroup, rddApiConfig);
  return response.data;
}

vehicleGroupsApi.delete = async (vehicleGroupId) => {
  const response = await axios.delete(`vehicle-group/${vehicleGroupId}`, rddApiConfig);
  return response.data;
}

export default vehicleGroupsApi;