import React from 'react';
import PropTypes from 'prop-types';

const DialogueTitle = (props) => {
  return (
    <h2 className="ReactModal__title">{props.title}</h2>
  );
};

DialogueTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default DialogueTitle;