import * as types from 'constants/actionTypes';
import vehiclesApi from 'api/vehiclesApi';
import channelsApi from 'api/channelsApi';
import presetsApi from 'api/presetsApi';
import triggersApi from 'api/triggersApi';

function fetchVehiclesDataSuccess(vehicles) {
  return {type: types.FETCH_VEHICLES_DATA_SUCCESS, vehicles};
}

export function refreshVehiclesData() {
  return async function (dispatch) {
    try {
      const vehicles = await vehiclesApi.getEnabledVehicles();
      return dispatch(fetchVehiclesDataSuccess(vehicles));
    }
    catch (error) {
        // OBSERVATION: At one point the Redux store seemed to disappear. I can't see why an exception occurring here should affect that though.
        throw error;
    }
  };
}

function fetchChannelsDataSuccess(channels) {
  return {type: types.FETCH_CHANNELS_DATA_SUCCESS, channels};
}

export function refreshChannelsData() {
  return async function (dispatch) {
    try {
      const channels = await channelsApi.getChannels();
      return dispatch(fetchChannelsDataSuccess(channels));
    }
    catch (error) {
      // alert(error);
      throw error;
    }
  };
}

function fetchEnabledPresetsDataSuccess(enabledPresets) {
  return {type: types.FETCH_ENABLED_PRESETS_DATA_SUCCESS, enabledPresets};
}

export function refreshEnabledPresetsData() {
  return async function (dispatch) {
    try {
      const presets = await presetsApi.getEnabled();
      return dispatch(fetchEnabledPresetsDataSuccess(presets));
    }
    catch (error) {
      // alert(error);
      throw error;
    }
  };
}

function fetchOperatorReferencesSuccess(operators) {
  return {type: types.FETCH_OPERATOR_REFERENCES_SUCCESS, operators};
}

export function refreshOperatorReferences() {
  return async function (dispatch) {
    try {
      const operators = await triggersApi.getOperatorReferences();
      return dispatch(fetchOperatorReferencesSuccess(operators));
    }
    catch (error) {
      // alert(error);
      throw error;
    }
  };
}

function fetchEvaluationTypeReferencesSuccess(triggerEvaluationTypes) {
  return {type: types.FETCH_EVALUATION_TYPE_SUCCESS, triggerEvaluationTypes};
}

export function refreshEvaluationTypeReferences() {
  return async function (dispatch) {
    try {
      const triggerEvaluationTypes = await triggersApi.getEvaluationTypeReferences();
      return dispatch(fetchEvaluationTypeReferencesSuccess(triggerEvaluationTypes));
    }
    catch (error) {
      // alert(error);
      throw error;
    }
  };
}

