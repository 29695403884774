import * as actionTypes from 'constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function vehicleGroupReducer(state = initialState.vehicleGroup, action) {
  switch (action.type) {
    case actionTypes.FETCH_VEHICLE_GROUPS_DATA_SUCCESS:
        return objectAssign([], state, {vehicleGroups: action.vehicleGroups});

    case actionTypes.UPDATE_VEHICLE_GROUP_SAVED_FLAG:
      return objectAssign({}, state, {saved: action.saved});

    default:
      return state;
  }
}