import React from 'react';
import PropTypes from 'prop-types';
import Resources from 'utilities/Resources';

class DialogueButtons extends React.Component{
  static propTypes = {
    showCancelButton: PropTypes.bool,
    cancelButtonText: PropTypes.string,
    cancelButtonEnabled: PropTypes.bool,
    cancelFunction: PropTypes.func,
    confirmButtonEnabled: PropTypes.bool,
    confirmButtonText: PropTypes.string.isRequired,
    confirmFunction: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.resources = Resources.localizedString;

    this.defaultOptions = {
      confirmButtonEnabled: true,
      cancelButtonEnabled: true,
      showCancelButton: true,
      cancelButtonText: this.resources.cancel
    }

    this.state = {...this.defaultOptions, ...props};
  }

  confirmFunction = () => {
    if (this.state.confirmButtonEnabled) {
      this.props.confirmFunction();
    }
  }

  cancelFunction = () => {
    if (this.state.cancelButtonEnabled) {
      this.props.cancelFunction();
    }
  }

  componentDidMount() {
    // Prevents that black focus indicator in Chrome from focusing on the whole dialogue. That looks a bit ugly as it does not properly surround the DialogueButtons
    // div as it does the rest of the dialogue. There's probably a better way to do this.
    if (this.state.showCancelButton && this.state.cancelButtonEnabled) {
      this.cancelButton.focus();
    }
    else if (this.state.confirmButtonEnabled) {
      this.confirmButton.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props) != JSON.stringify(prevProps)) {
      this.setState({...this.defaultOptions, ...this.props});
    }
  }

  render() {
    return (
      <div className="ReactModal__actions-wrapper overflow-hidden">
        {this.state.showCancelButton && (
        <button className={this.state.cancelButtonEnabled ? "button button--secondary left" : "button button--secondary button--disabled left"}
                onClick={this.cancelFunction}
                ref={input => { this.cancelButton = input; }}>
          {this.state.cancelButtonText}
        </button>
        )}
        <button className={this.state.confirmButtonEnabled ? "button button--arrow-right right" : "button button--arrow-right button--disabled right"}
                onClick={this.confirmFunction}
                ref={input => { this.confirmButton = input; }}>
          {this.state.confirmButtonText}
        </button>
      </div>
    );
  }
}

export default DialogueButtons;
