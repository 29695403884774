// import {UPDATE_GRAPH_SETTINGS, FETCH_GRAPH_DATA_POINTS} from 'constants/actionTypes';
import * as actionTypes from 'constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case actionTypes.UPDATE_USER:
      return objectAssign({}, state, action.user);

    default:
      return state;
  }
}
