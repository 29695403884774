import * as types from 'constants/actionTypes';
import triggerProblemStateApi from 'api/triggerProblemStateApi';
import moment from 'moment';
import {getAvailableResolutionForRange} from 'utilities/graphZoom.js';
import * as channelTypes from 'constants/channelTypes';

function clearGraphsAndSetNewChannels(triggerProblemState, graphs) {
  graphs.forEach(element => {
    element.channels= []
  });

  if (graphs[0].accepts === channelTypes.ANALOGUE_CHANNEL)
  {
    // DC (16-10-2020): At some point we may need some extra intelligence to split analogue Channels between different graphs. This would be if the values in
    //                  one Channel are on a much greater scale than another.
    graphs[0].channels = triggerProblemState.analogueChannels
  }
  else if (graphs[0].accepts === channelTypes.DIGITAL_CHANNEL)
  {
    graphs[0].channels = triggerProblemState.digitalChannels
  }

  return graphs;
}

function getTriggerProblemStateByIdSuccess(triggerProblemState, analogueGraphs, digitalGraphs) {
  const [rangeStart, rangeEnd] = getRangeLocal(triggerProblemState);
  const endDateTime = triggerProblemState.timeExited ? moment.utc(triggerProblemState.timeExited) : null;
  const updatedAnalogueGraphs = clearGraphsAndSetNewChannels(triggerProblemState, analogueGraphs);
  const updatedDigitalGraphs = clearGraphsAndSetNewChannels(triggerProblemState, digitalGraphs);

  return {
    type: types.UPDATE_GRAPH_SETTINGS,
    newSettings: {
      triggerProblemState: {name: triggerProblemState.triggerName, startDateTime: moment.utc(triggerProblemState.timeEntered).local(), endDateTime},
      selectedVehicleCode: triggerProblemState.vehicleCode,
      rangeStart,
      rangeEnd,
      detailRangeStart: rangeStart,
      detailRangeEnd: rangeEnd,
      analogueGraphs: updatedAnalogueGraphs,
      digitalGraphs: updatedDigitalGraphs,
      detailResolution: getAvailableResolutionForRange(rangeStart, rangeEnd),
      detailQuery: false
    }
  };
}

function getRangeLocal(triggerProblemState) {
  // If the Trigger uses a monitor, data from when the monitor was opened would usually be of interest.
  let rangeStart = moment.utc(triggerProblemState.monitorTimeOpened || triggerProblemState.timeEntered).local();

  let rangeEnd;
  let timeEntered = moment.utc(triggerProblemState.timeEntered).local();
  if (triggerProblemState.timeExited) {
    rangeEnd = moment.utc(triggerProblemState.timeExited).local();
    // If timeExited is too far ahead of the timeStarted shorten the range. Many problem states aren't exited at the right time. Originally I chose 24 hours
    // but the hairline text went a bit too far underneth the GraphSummary so I reduced it to avoid any complaints.
    const maxRangeEnd = moment(timeEntered).add(12, 'hours');
    if (rangeEnd.valueOf() > maxRangeEnd.valueOf()) {
      rangeEnd = maxRangeEnd;
    }
  } else {
    // Just show a default range from triggerProblemState.timeEntered.
    rangeEnd = moment(timeEntered).add(4, 'hours');
  }

  // Widening the range may give more context. This is especially important at the start where we may be otherwise missing some values.
  rangeStart = rangeStart.subtract(30, 'minutes');
  rangeEnd = rangeEnd.add(30, 'minutes');

  const currentTime = moment();
  if (rangeEnd.valueOf() >= currentTime.valueOf()) {
    rangeEnd = currentTime;
  }

  return [rangeStart, rangeEnd];
}

export function getById(id, analogueGraphs, digitalGraphs) {
  return async function (dispatch) {
    try {
      const triggerProblemState = await triggerProblemStateApi.getById(id);
      return dispatch(getTriggerProblemStateByIdSuccess(triggerProblemState, analogueGraphs, digitalGraphs));
    }
    catch (error) {
      // OBSERVATION: At one point the Redux store seemed to disappear. I can't see why an exception occurring here should affect that though.
      throw error;
    }
  };
}
