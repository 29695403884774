import LocalizedStrings from 'react-localization';

// Wraps the "react-localization" stuff and the "resources" property from our Redux store into one class therefore avoiding the need to:
//  1) Import the package into every component,
//  2) Add "resources" to mapStateToProps() function in every component.
//  3) Avoids the need to convert functional stateless components to classes so that can connect to redux to access "resources". I am not sure it is possible:
//      https://blog.logrocket.com/react-redux-connect-when-and-how-to-use-it-f2a1edab2013
export default class Resources {
  static selectedLanguageField = 'en-EN';
  
  static get selectedLanguage() {
    return Resources.selectedLanguageField;
  }

  static localizedStringField = {};

  // NOTE: This needs to have been called initially, before localizedString is used
  static updateLocalizedString(resourceDefinitionsObject, selectedLanguage) {
    Resources.selectedLanguageField = selectedLanguage;
    Resources.localizedStringField = new LocalizedStrings(resourceDefinitionsObject);
    Resources.localizedStringField.setLanguage(Resources.selectedLanguageField);
  }

  static get localizedString() {
    return Resources.localizedStringField;
  }
}
