import * as actionTypes from 'constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';
import {mapToVehicleSelectOptions, mapToChannelSelectOptions} from 'utilities/graphSettings';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function referenceDataReducer(state = initialState.referenceData, action) {
  switch (action.type) {
    case actionTypes.FETCH_VEHICLES_DATA_SUCCESS:
    try {
      const vehicles = action.vehicles;
      const vehicleSelectOptions = mapToVehicleSelectOptions(action.vehicles);
      return objectAssign({}, state, {vehicles, vehicleSelectOptions});
      }
      catch (error) {
        return state;
      }

    case actionTypes.FETCH_CHANNELS_DATA_SUCCESS:
      try {
        const channels = action.channels;
        const channelSelectOptions = mapToChannelSelectOptions(action.channels);
        return objectAssign({}, state, {channels, channelSelectOptions});
      }
      catch (error) {
        return state;
      }

    case actionTypes.FETCH_ENABLED_PRESETS_DATA_SUCCESS:
      try {
        const enabledPresets = action.enabledPresets;
        return objectAssign({}, state, {enabledPresets});
      }
      catch (error) {
        return state;
      }

    case actionTypes.FETCH_OPERATOR_REFERENCES_SUCCESS:
      try{
        const triggerOperators = action.operators;
        return objectAssign({}, state, {triggerOperators})
      }
      catch(error){
        return state;
      }

      case actionTypes.FETCH_EVALUATION_TYPE_SUCCESS:
      try {
        const triggerEvaluationTypes = action.triggerEvaluationTypes;
        return objectAssign({}, state, {triggerEvaluationTypes});
      }
      catch (error) {
        return state;
      }

    default:
      return state;
  }
}
