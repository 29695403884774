import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as graphHairlinesActions from 'actions/graphHairlinesActions';
import * as graphSettingsActions from 'actions/graphSettingsActions';
import * as sidebarActions from  'actions/sidebarValuesActions';
import * as searchActions from 'actions/advancedSearchActions';
import {getChannelColour, detailResolution} from 'utilities/graphSettings';
import * as R from 'ramda';
import graphComponentsCommon from 'utilities/graphComponentsCommon';
import Resources from 'utilities/Resources';

const alphaLevel = 0.5;

// Note that if 256 * al < 16 this function will incorrectly return only 1 character but we expect nowhere near that level of transparency.
const getAlphaLevelHex = al => (256 * al).toString(16);
// Adding alpha to the line colour seems to be the only way of making it transparent. If we don't do this we end up with the line showing as a border.
const addAlphaToHexCode = hexCode => hexCode + getAlphaLevelHex(alphaLevel);

// Create a Map function with an index parameter: https://stackoverflow.com/questions/36573832/how-can-i-access-iteration-index-in-ramda-map
const mapIndexed = R.addIndex(R.map);
const generateChannelAxisTickers = mapIndexed((val, idx) => {
  return {v: idx, label: val};
});

class DigitalGraph extends React.Component {
  static propTypes = graphComponentsCommon.standardPropTypes;

  constructor(props) {
    super(props);

    this.common = graphComponentsCommon.generateBoundCommon(this);

    this.common.setPropertiesOfThis();

    this.dygraphOptions = {
      ...this.common.getCommonDygraphOptions(),
      // This needs to be a bit bigger than the default for all labels to be showing when 8 channels on graph. Didn't look as though could dynamically change height.
      // !! -- Now removed, and is set using CSS based on number of channels in the updateDygraphAfterSuccessfullyFetchingDataPoints function
      // height: 340,
      fillAlpha: alphaLevel,
      // http://dygraphs.com/tests/x-axis-formatter.html
      axes: {
        y: {
          // axisLabelFormatter: this.yAxisChannelNamesLabelFormatter,
          ticker: this.yAxisChannelTickerGenerator,
          axisLabelWidth: 70,
        }
      },
    };

    this.state = {
      graph: R.find(R.propEq('graphId', this.props.graphId))(this.props.graphSettings.digitalGraphs),
      requestHasData: true
    };
    this.channelNames = [];
    this.channelShortNames = [];
  }

  async componentDidMount() {
    await this.common.componentDidMount({isDigitalGraph: true});
  }

  componentDidUpdate(prevProps) {
    this.common.componentDidUpdate(prevProps);
  }

  componentWillUnmount() {
    this.common.componentWillUnmount();
  }

  getChannelColours() {
    let colours = [];
    for (let i = 0; i < this.state.graph.channels.length; i++) {
      const originalColour = getChannelColour(this.state.graph.channels[i]);
      const alphaColor = addAlphaToHexCode(originalColour);
      colours.push(alphaColor);
    }
    return colours;
  }

  async getDataPointsFromApi(startDateTime, endDateTime) {
    const [request, channelsChartData, rawChartData, chartMissingStartDataInterval, chartMissingEndDataInterval] =
      await this.common.requestAndProcessDataPointsFromApi(startDateTime, endDateTime, detailResolution.OneSecond);

    // channelShortNames is reversed as these are used for the y-axis labels and, in transformForDigital() further down, we now give channels values that are
    // higher depending on how low their index is (so channels on graph are shown in the same order as the user selected them in the list).
    this.channelShortNames = R.reverse(R.pluck('shortName')(this.state.graph.channels));
    // channelNames is no longer used for y-axis labels. However, the dygraphOptions.labels property below, which is built from it, is used by dygraphs when
    // graph.indexFromSetName() is called from within dygraphsFunctions when we want to update the sidebar values. It therefore needs to remain in the same order
    // as the channels were selected by the user.
    this.channelNames = R.reverse([...this.channelShortNames]);

    this.chartData = [...channelsChartData];

    this.dygraphOptions = {
      ...this.dygraphOptions,
      title: `${Resources.localizedString.graph} ${this.props.graphId}`,
      labels: ['Time', ...this.channelNames],
      colors: this.getChannelColours(this.state.graph.channels),
      file: [...channelsChartData],
      dateWindow: [startDateTime.valueOf(), endDateTime.valueOf()],
      underlayCallback: graphComponentsCommon.generateUnderlayCallback(chartMissingStartDataInterval, chartMissingEndDataInterval),
    };

    this.common.updateDygraphAfterSuccessfullyFetchingDataPoints(request, rawChartData, this.getUpdatedOptionsTransformedForDigital());
  }

  render() {
    return (
      <div className="graph-container">
        <div id="hairline-template" className="hairline-info hairline-info--channel" style={{display: 'none'}}>
          <button className="hairline-kill-button">Remove</button>
          <div className="hairline-legend"></div>
        </div>
        <div id="hairline-template-distance" className="hairline-info hairline-info--distance" style={{display: 'none'}}>
          <button className="hairline-kill-button">Remove</button>
          <div className="hairline-legend"></div>
        </div>
        <div id="hairline-template-event" className="hairline-info hairline-info--event" style={{display: 'none'}}>
          <button className="hairline-kill-button">Remove</button>
          <div className="hairline-legend"></div>
        </div>
        <div id="hairline-template-trigger" className="hairline-info hairline-info--trigger" style={{display: 'none'}}>
          <button className="hairline-kill-button">Remove</button>
          <div className="hairline-legend"></div>
        </div>
        <div id="hairline-template-search" className="hairline-info hairline-info--search" style={{display: 'none'}}>
          <button className="hairline-kill-button">Remove</button>
          <div className="hairline-legend"></div>
        </div>
        <div id={"graphdiv-" + this.props.graphId} className="graph-div digital-graph"></div>
        <div id={this.graphContainerId + "-loading"} className="graph-loading"></div>
      </div>
    );
  }

  getUpdatedOptionsTransformedForDigital() {
    const baseUpdatedOptions = {
      file: this.chartData,
      ...this.dygraphOptions,
    };

    const updatedOptions = this.transformForDigital(baseUpdatedOptions);
    if (updatedOptions.file.length == 0) {
      // An empty array for the "file" property causes a console error.
      updatedOptions.file = null;
    }

    return updatedOptions;
  }

  transformForDigital(updatedOptions) {
    if (updatedOptions.file) {
      for (let point = 0; point < updatedOptions.file.length; point++) {
        // [0] is the date/time.
        for (let channel = 1; channel < updatedOptions.file[point].length; channel++) {
          if (updatedOptions.file[point][channel]) {
            const value = updatedOptions.file[point][channel][1];
            // The channels need to be in reverse order on the graph, i.e. first channel will be at the top and have the largest values, last channel will have
            // smallest. This ensures that the digital channels appear on the graph in the same order as the user selected them in the list.
            const channelGraphPosition = updatedOptions.file[point].length - channel;
            const transformedMinValue = channelGraphPosition - 1;
            const transformedValue = value > 0 ? channelGraphPosition - 0.3 : channelGraphPosition - 0.9;
            // const transformedMinValue = channel - 1;
            // const transformedValue = value > 0 ? channel - 0.2 : channel - 0.9;
            const transformedMaxValue = transformedValue;

            updatedOptions.file[point][channel] = [
              transformedMinValue,
              transformedValue,
              transformedMaxValue,
            ];
          }
        }
      }
    }

    return updatedOptions;
  }

  // DC: https://stackoverflow.com/questions/8481437/how-to-set-specific-y-axis-label-points-in-dygraphs
  //     The ticker property is there for explicit labels to be displayed. Dygraphs will always display them but will not generate
  //     any others, e.g. if the user zooms in to a much more fin-grained resolution. This is more convenient for dispalying channel labels.
  yAxisChannelTickerGenerator = (min, max, pixels, opts, dygraph, vals) => {
    return generateChannelAxisTickers(this.channelShortNames);
  };

  // DC: Setting the axisLabelFormatter property of the y axis is really what it says, in that dygraphs is still supposed to
  //     decide what labels are displayed and will change them depending on how zoomed in the user is. We just tell it their
  //     format. For example, we may want dygraphs to decide numbers to be displayed but we want the numbers prfixed with a '£'.
  yAxisChannelNamesLabelFormatter = d => {
    if (Number.isInteger(d) && d <= this.channelShortNames.length) {
      if (this.channelShortNames[d]) {
        return `<span title="${this.channelShortNames[d]}">${this.channelShortNames[d]}</span>`;
      }
    } else {
      return '';
    }
  };
}

// function mapStateToProps(state, ownProps) {
function mapStateToProps(state) {
  return {
    // DC: This will give the component the "this.props.graphSettings" property.
    graphSettings: state.graphSettings,
    graphHairlines: state.graphHairlines,
    distances: state.distances
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...graphSettingsActions, ...sidebarActions, ...searchActions, ...graphHairlinesActions}, dispatch)
  };
}

// DC: I think this is needed only if we want to write Jest tests for the component.
export {DigitalGraph as DigitalGraphNoRedux};

// These names used in redux docs but could use different ones.
export default connect(mapStateToProps, mapDispatchToProps)(DigitalGraph);
