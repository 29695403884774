import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import DialogueTitle from 'components/DialogueTitle';
import DialogueButtons from 'components/DialogueButtons';
import Resources from 'utilities/Resources';
import 'styles/exports.scss';

class ExportDialogueContentCompleted extends React.Component {
  static propTypes = {
    response: PropTypes.object.isRequired,
    exportType: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  };

  close = () => {
    this.props.onClose();
  };

  render() {
    const res = Resources.localizedString;
    return (
      <React.Fragment>
        <DialogueTitle title={!this.props.response.error ? res.success : res.exportFailed} />
        <div className="padded margin-vertical">
          <div className="padded margin-vertical text-center">
          {!this.props.response.error ? (
            <React.Fragment>
              <span className="status-icon status-icon--success margin-bottom"></span>
              <p className="margin-top">We have now started exporting the data.</p>
              <p>You will recieve an email with the CSV file attached once it has finished exporting.</p>
            </React.Fragment>
           ) : (
            <React.Fragment>
              <span className="status-icon status-icon--error margin-bottom"></span>
              <p className="margin-top">An error has occurred while trying to export your data.</p>
              <p>Please try again in a few minutes.</p>
            </React.Fragment>
           )}
          </div>
        </div>
        <DialogueButtons
          showCancelButton={false}
          confirmFunction={this.close}
          confirmButtonText={res.ok} />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}
export {ExportDialogueContentCompleted as ExportDialogueContentCompletedNoRedux};

const ConnectedExportDialogueContentCompleted = connect(mapStateToProps, mapDispatchToProps)(ExportDialogueContentCompleted);
export default ConnectedExportDialogueContentCompleted;