import axios from 'axios';
import rddApiConfig from './rddApiConfig';
// "ReferenceError: regeneratorRuntime is not defined" when used async/await: https://github.com/babel/babel/issues/5085
// in the form "async => await () => ..."
// This page suggests it can be done without including the polyfill: https://www.valentinog.com/blog/how-async-await-in-react/
import '@babel/polyfill';

const exportApi = {};

// https://medium.com/codingthesmartway-com-blog/getting-started-with-axios-166cb0035237
// https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index
exportApi.getExportForVehicle = async (request) => {
  const response = await axios.put('export/vehicle-multi-channel', request, rddApiConfig);
  return response.data;
}

// We won't use the Promise syntax now as we have now got async/await syntax to work.
// (1) Promise syntax is more cluttered and confusing,
// (2) we have the problems of the returns below returning from the immediately enclosing arrow functions rather than the OUTER method,
// (3) Promises are not supported in IE so not gaining any extra compatibility.

export default exportApi;
