import {combineReducers} from 'redux';
import fuelSavings from './fuelSavingsReducer';
import graphHairlines from './graphHairlinesReducer';
import graphSettings from './graphSettingsReducer';
import sidebarValues from './sidebarValuesReducer';
import referenceData from './referenceDataReducer';
import userSettings from './userSettingsReducer';
import resources from './resourcesReducer';
import distances from './distanceReducer';
import preset from './presetReducer';
import vehicleGroup from './vehicleGroupReducer';
import user from './userReducer';
// import {routerReducer} from 'react-router-redux';
import graphConfigurationDialogue from './graphConfigurationDialogueReducer';
import triggers from './triggersReducer';
import exportDialogue from './exportDialogueReducer';
import shareDialogue from './shareDialogueReducer';
import {connectRouter} from 'connected-react-router';

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  fuelSavings,
  graphConfigurationDialogue,
  exportDialogue,
  shareDialogue,
  graphHairlines,
  graphSettings,
  referenceData,
  resources,
  userSettings,
  sidebarValues,
  distances,
  // routing: routerReducer,
  triggers,
  preset,
  user,
  vehicleGroup,
});

export default rootReducer;
