import * as types from 'constants/actionTypes';
// import getCurrentReduxState from 'index';
import {getAvailableResolutionForRange} from 'utilities/graphZoom.js';

// example of a thunk using the redux-thunk middleware
// DC: We return a function accepting a dispatch function and returning a call to that dispatch function.
export function updateSelectedVehicle(vehicleCode) {
  return function (dispatch) {
    // thunks allow for pre-processing actions, calling apis, and dispatching multiple actions
    // in this case at this point we could call a service that would persist the fuel savings
    return dispatch({
      type: types.UPDATE_GRAPH_SETTINGS,
      newSettings: {selectedVehicleCode: vehicleCode},
    });
  };
}

export function updateDisplayStepPlots(shouldDisplayStepPlots) {
  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_GRAPH_SETTINGS,
      newSettings: {displayStepPlots: shouldDisplayStepPlots},
    });
  };
}

export function updateDetailResolution(detailResolution) {
  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_GRAPH_SETTINGS,
      newSettings: {detailResolution: detailResolution},
    });
  };
}

export function updateSelectedChannels(selectedChannels) {
  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_GRAPH_SETTINGS,
      newSettings: {selectedChannels: selectedChannels},
    });
  };
}

// Could really just have used this with everything.
export function updateGraphSettings(newSettings) {
  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_GRAPH_SETTINGS,
      newSettings,
    });
  };
}

// Although this one is a bit more specialised.
export function updateRangeDetailSettings(detailQuery, detailRangeStart, detailRangeEnd) {
  const newSettings = {
    detailQuery,
    detailRangeStart,
    detailRangeEnd,
    detailResolution: getAvailableResolutionForRange(detailRangeStart, detailRangeEnd),
  };

  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_GRAPH_SETTINGS,
      newSettings,
    });
  };
}

export function fetchGraphDataPoints(shouldFetchPointsFromApi) {
  return function (dispatch) {
    return dispatch({
      type: types.FETCH_GRAPH_DATA_POINTS,
      shouldFetchPointsFromApi: shouldFetchPointsFromApi,
    });
  };
}
