import * as channelTypes from 'constants/channelTypes';
import Dygraph from 'dygraphs';
import * as channelValueTypes from 'constants/channelValueTypes';
import * as R from 'ramda';
import Resources from 'utilities/Resources';

let dygraphs = [];
let blockRedraw = false;

export function addToDygraphsArray(dygraph) {
  dygraphs.push(dygraph);
}

export function checkIfInDygraphsArray(dygraph) {
  return dygraphs.indexOf(dygraph) != -1;
}

export function removeFromDygraphsArray(dygraph) {
  var index = dygraphs.indexOf(dygraph);
  if (index !== -1) {
    dygraphs.splice(index, 1);
  }
}

export function redraw(dygraph, initial) {
  // stops recusrion as graphs are redrawn
  if (blockRedraw || initial) return;
  blockRedraw = true;
  
  const range = dygraph.xAxisRange();
  for (let i = 0; i < dygraphs.length; i++) {
      if (dygraphs[i] == dygraph) continue;
      dygraphs[i].updateOptions({
        dateWindow: range
      });
  }
  
  blockRedraw = false;
}

const digitalFirstComparators = [R.descend(R.prop('isDigital')), R.ascend(R.prop('graphId'))];

export function getDygraphsArrayWithDigitalGraphsFirst() {
  const dygraphsArrayWithDigitalGraphsFirst = R.sortWith(digitalFirstComparators)(dygraphs);
  return dygraphsArrayWithDigitalGraphsFirst;
}

const getNormalisedDigitalValue = value => {
  const res = Resources.localizedString;

  const roundedValue = Math.round(value);
  // return roundedValue > 0 ? 'ON' : 'OFF';
  return roundedValue > 0
          ? `<span class="digital-on">${res.on.toUpperCase()}</span>`
          : `<span class="digital-off">${res.off.toUpperCase()}</span>`;
}

const getChannelSidebarMinMax = (channel, value, channelIndex, channelsLength) => {
  // value stores min[0], value[1] and max[2]
  let displayValue;
  if (channel.channelType === channelTypes.DIGITAL_CHANNEL) {
    // In the digital graphs the lower the index of the channel the larger its value (so the first channel appears at the top of the graph and the last at the bottom).
    // If channelIndex goes from 0 to N, (channelsLength - channelIndex - 1) will go from N to 0.
    displayValue = getNormalisedDigitalValue(value[1] - (channelsLength - channelIndex - 1));
  } else {
    const [min, max] = [parseFloat(value[0]).toFixed(2), parseFloat(value[2]).toFixed(2)];
    // displayValue = min == max ? max : `${max}<br />${min}`;
    displayValue = min == max ? max : `${min}&nbsp;-&nbsp;${max}`;
  }

  return displayValue;
};

// DC: I use the graph.rawData to locate the values for each channel for the latest time <= pointTimestamp.
export function getGraphChannelSidebarValues(graph, pointTimestamp) {
  let graphChannels = [];

  // Adam's original used graph.getRowForX() but that would return null if a graph had no points for that timeStamp, meaning we had no starting point from which to
  // go backwards in time in order to find the last non-zero points before that timeStamp.
  // const dataRowIndex_old = graph.getRowForX(pointTimestamp);
  // console.log('dataRowIndex_old', dataRowIndex_old);
  
  for (let i = 0; i < graph.channels.length; i++) {
    //get a new channel object
    let channel = Object.assign({}, graph.channels[i]);
    //gets the column index in the data row for the given channel name
    const columnIndex = graph.indexFromSetName(channel.shortName);
    
    // DC: The rawData_ suggests it is a "private" field. However, it contains exactly what we need to look up by "nearest timestamp value" and we are not
    //     modifying it.
    if (graph.rawData_) {
        const dataRowIndex = R.findLastIndex(x => x[0] <= pointTimestamp && x[columnIndex] != null)(graph.rawData_);
        
        if (dataRowIndex != -1) {
          const value = graph.rawData_[dataRowIndex][columnIndex];
          channel.value = getChannelSidebarMinMax(channel, value, i, graph.channels.length);
        } else {
          channel.value = channelValueTypes.EMPTY_CHANNEL_VALUE;
        }
    } else {
      channel.value = channelValueTypes.EMPTY_CHANNEL_VALUE;
    }

    graphChannels.push(channel);
  }

  return graphChannels;
}

const underlayFunctions = {};

// When we have times along the X-axis, xStart and xEnd are 13 digit UNIX time stamps (milliseconds).
function highlightPeriod(canvas, area, g, xStart, xEnd, colour) {
  var xCanvasLeft = g.toDomXCoord(xStart);
  var xCanvasRight = g.toDomXCoord(xEnd);
  var canvasWidth = xCanvasRight - xCanvasLeft;
  canvas.fillStyle = colour;
  canvas.fillRect(xCanvasLeft, area.y, canvasWidth, area.h);
}

// For times this returns 13 digit UNIX time stamps (milliseconds).
const getDataIntervalX = g => [g.getValue(0, 0), g.getValue(g.numRows() - 1, 0)];

const lightGey = "#eeeeee";
const yellow = "rgba(255, 255, 102, 1.0)";

underlayFunctions.addUnderlayForTimeInterval = (canvas, area, g, chartDataInterval) => {
  if (chartDataInterval) {
    const fromTime = chartDataInterval.underlayStartTime.getTime();
    const toTime = chartDataInterval.underlayEndTime.getTime();
    highlightPeriod(canvas, area, g, fromTime, toTime, lightGey);
  }
}

// Just an experiment.
underlayFunctions.addYellowUnderlayToLeftHalf = (canvas, area, g) => {
  const [xMinData, xMaxData] = getDataIntervalX(g);
  highlightPeriod(canvas, area, g, xMinData, xMinData + (xMaxData - xMinData) / 2, yellow);
}

export {underlayFunctions};

export function mouseDown(event, g, context) {
  // Right-click should not initiate a zoom.
  if (event.button && event.button == 2) return;

  context.initializeMouseDown(event, g, context);
  Dygraph.startZoom(event, g, context);
}

export function mouseMove(event, g, context) {
  if (context.isZooming) {
    Dygraph.moveZoom(event, g, context);
  }
}

export function mouseUp(event, g, context) {
  if (context.isZooming) {
    Dygraph.endZoom(event, g, context);
  }
}

export function generateSomeInitialChartData() {
  // http://dygraphs.com/gallery/#g/dynamic-update
  let data = [];
  let t = new Date();
  for (let i = 10; i >= 0; i--) {
    let x = new Date(t.getTime() - i * 1000);
    data.push([x, Math.random()]);
  }

  return data;
}

export const getHairlinesByType = type => R.filter(R.propEq('type', type));
