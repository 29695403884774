import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as dialogueActions from 'actions/dialogueActions';
import ChannelSelectionDialogueContent from 'components/ChannelSelectionDialogueContent';
import SavePresetDialogueCompletedContent from 'components/SavePresetDialogueCompletedContent';
import Modal from 'react-modal';
import 'styles/_dialogue.scss';

Modal.setAppElement('#app')

class ChannelSelectDialogue extends React.Component {
  static propTypes = {
    showGraphConfigurationDialogue: PropTypes.bool.isRequired,
    dialogueActions: PropTypes.object.isRequired,
    onPresetSave: PropTypes.func
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      requested: false,
      response: null,
      modalWidth: 1265,
      modalHeight: 540
    };

    this.successFailureModalWidth = 1155;
    this.successFailureModalHeight = 430;

    this.initialState = this.state;
  }

  onRequested = (requested, response) => {
    let modalWidth = this.state.modalWidth;
    let modalHeight = this.state.modalHeight;

    if (requested) {
      modalHeight = this.successFailureModalHeight;
      modalWidth = this.successFailureModalWidth;
    }

    this.setState({
      requested,
      response,
      modalHeight,
      modalWidth
    });
  };

  onClose = () => {
    this.props.dialogueActions.closeChannelsDialogue();
    this.setState(this.initialState);
  }

  render() {
    let content;
    if (!this.state.requested) {
      content = <ChannelSelectionDialogueContent onRequested={this.onRequested} onClose={this.onClose} onPresetSave={this.props.onPresetSave} />;
    } else {
      content = <SavePresetDialogueCompletedContent response={this.state.response} onClose={this.onClose} />;
    }

    return(
      <Modal
        className={"dialogue"}
        contentLabel="Channel Selection Modal"
        onRequestClose={this.onClose}
        onClose={this.onClose}
        isOpen={this.props.showGraphConfigurationDialogue}
        style={{
          content: {
            width: this.state.modalWidth,
            height: this.state.modalHeight
          }
        }}>
          {content}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    showGraphConfigurationDialogue: state.graphConfigurationDialogue.show
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dialogueActions: bindActionCreators(dialogueActions, dispatch)
  };
}

export {ChannelSelectDialogue as ChannelSelectDialogueNoRedux};

const ConnectedChannelSelectDialogue = connect(mapStateToProps, mapDispatchToProps)(ChannelSelectDialogue);
export default ConnectedChannelSelectDialogue;