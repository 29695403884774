import * as React from 'react'
import {PropTypes} from 'prop-types';
import * as dragDropTypes from 'constants/dragDropTypes';
import UnselectedGraphChannel from 'components/UnselectedGraphChannel';
import { DropTarget } from 'react-dnd'

const unselecteedChannelListTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();
    props.onRemove(item);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget()
  };
}

class UnselectedChannelList extends React.Component {
	static propTypes = {
    channels: PropTypes.array.isRequired,
    // Injected by React DnD above:
    connectDropTarget: PropTypes.func.isRequired
	};
	
  showChannelData = (channel, index) => {
    return (
      <UnselectedGraphChannel key={index} channel={channel} />
    )
  };

  render() {
    const connectDropTarget = this.props.connectDropTarget;
    
    return (
      connectDropTarget &&
      connectDropTarget(
        <div className="channel-list">
          {this.props.channels.map(this.showChannelData)}
        </div>
      )
    )
  }
}

export default DropTarget(dragDropTypes.SELECTED_CHANNEL, unselecteedChannelListTarget, collect)(UnselectedChannelList);
