import React from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';
import {emailRegex} from 'constants/regex';
import Resources from 'utilities/Resources';
import 'styles/_email-list.scss';

class AddEmails extends React.Component{
    static propTypes = {
      selectedEmails: PropTypes.array,
      emailListUpdateCallback: PropTypes.func.isRequired,
      emailLabel: PropTypes.string,
      tall: PropTypes.bool,
      externalValidation: PropTypes.bool,
      showValidation: PropTypes.bool,
      triggerEmailList: PropTypes.bool,
      validationCallback: PropTypes.func
    };

    constructor(props, context) {
      super(props, context);

      this.tall = false;
      if (this.props.tall) {
        this.tall = true;
      }

      let selectedEmails = [];
      if (this.props.selectedEmails) {
        selectedEmails = this.props.selectedEmails;
      }

      this.state = {
        email: '',
        emails: selectedEmails,
        validEmail: true,
        internalValidation: true
      };

      this.emailKeyup = this.emailKeyup.bind(this);
    }

    async componentDidUpdate(prevProps){
      if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
        if (JSON.stringify(this.props.selectedEmails) !== JSON.stringify(prevProps.selectedEmails)) {
          this.setState({
              emails: this.props.selectedEmails
          }, this.updateEmailList(this.props.selectedEmails , false));
        }

        if (this.props.showValidation !== prevProps.showValidation) {
          if (this.props.showValidation) {
            this.setState({
              validEmail: false
            });
          } else {
            this.setState({
              validEmail: true
            });
          }
        }

        if (this.props.externalValidation !== prevProps.externalValidation) {
          if (this.props.externalValidation) {
            this.setState({
              internalValidation: false
            });
          }
        }
      }
    }

    updateEmailList = (newEmails, changesMade) => {
      this.props.emailListUpdateCallback(newEmails, changesMade);
    }

    emailKeyup = (e) => {
      const keyCode = e.keyCode;
      if (keyCode === 13) {
        this.addEmail();
      }
    }

    onChange = (e) => {
      const email = e.target.value;
      this.setState({
        email
      });
    }

    validateEmail = (email) => {
      const valid = emailRegex.test(email);
      if (this.props.validationCallback) {
        this.props.validationCallback(valid);
      }
      return valid;
    }
    
    addEmail = () => {
      let email = this.state.email;
      this.setState({
        internalValidation: true
      });
      let validEmail = this.state.validEmail;
      validEmail = this.validateEmail(email);
      if (validEmail) {
        let emailsCopy = [...this.state.emails];
        if (this.props.triggerEmailList) {
          email = this.createTriggerEmailFromString(email);
        }
        emailsCopy.push(email);
        this.setState({
          email: '',
          emails: emailsCopy
        }, this.updateEmailList(emailsCopy, true));
      }
      this.setState({validEmail});
    }

    createTriggerEmailFromString = (email) => {
      return {
        triggerEmailId: null,
        triggerId: null,
        email,
      }
    }

    removeEmailsFromList = (e, index) => {
      let emailsCopy = [...this.state.emails];
      emailsCopy.splice(index, 1)

      this.setState({
        emails: emailsCopy
      }, this.updateEmailList(emailsCopy, true));
    }

    render(){
      const resources = Resources.localizedString;
      const emailItems = this.state.emails.map((triggerEmail, index) => {
        return (<div key={index} className="email-list__email">
          {this.props.triggerEmailList ? triggerEmail.email : triggerEmail}
          <button className="email-list__remove" onClick={(event) => {this.removeEmailsFromList(event, index)}}>x</button>
        </div>);
      });

      return(
        <React.Fragment>
          {this.props.emailLabel &&
            <h3>{this.props.emailLabel}</h3>
          }
          <div className="full-container">
            <input type="text" className={!this.state.validEmail ? "email-list__input error" : "email-list__input"} value={this.state.email} onChange={this.onChange} onKeyUp={this.emailKeyup}/>
            <button className="icon-button icon-button--add" onClick={this.addEmail}>Add</button>
          </div>
          {this.state.internalValidation && !this.state.validEmail ? 
            <span className="message message--error inline-block">{resources.pleaseEnterValidEmailAddress}</span>
          : null}
          <div className={this.tall ? "email-list__selected-container--tall" : "email-list__selected-container"}>
            {this.state.emails && this.state.emails.length> 0 ?
              <div className="selection-list">
                {emailItems}
              </div>
            : null}
          </div>
        </React.Fragment>
      )
    }
}

function mapStateToProps(state) {
    return {  };
  }

function mapDispatchToProps(dispatch) {
  return {  };
}

export {AddEmails as AddEmailsNoRedux};

const ConnectedAddEmails = connect(mapStateToProps, mapDispatchToProps)(AddEmails);
export default ConnectedAddEmails;
