import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import Resources from 'utilities/Resources';

class TriggerDetails extends React.Component{
    static propTypes = {
      setTriggerDetailsAreValid: PropTypes.func.isRequired,
      detailsChangedCallback: PropTypes.func.isRequired,
      triggerDetails: PropTypes.object,
      needsValidating: PropTypes.bool,
      duplicateError: PropTypes.bool
    }

    constructor(props, context){
      super(props, context);

      this.state = {
        name: "",
        isEnabled: false,
        valid: true
      };
    }

    async componentDidUpdate(prevProps){
      if (JSON.stringify(this.props.triggerDetails) !== JSON.stringify(prevProps.triggerDetails)) {
        this.setState({
          name: this.props.triggerDetails.name,
          isEnabled: this.props.triggerDetails.isEnabled
        });
      }

      if (this.props.needsValidating) {
        const valid = this.validateDetails();
        this.setState({
          valid
        }, () => {this.props.setTriggerDetailsAreValid({name: this.state.name, isEnabled: this.state.isEnabled, isValid: valid})});
      }

      if (JSON.stringify(this.props.duplicateError) != JSON.stringify(prevProps.duplicateError))
      {
        var duplicateError = this.props.duplicateError
        this.setState({
          duplicateError,
          valid: false
        });
      }
    }

    validateDetails = () => {
      if (!this.state.name.trim()) {
        return false;
      }

      return true;
    }

    setName = (e) => {
      this.setState({
        name: e.target.value
      }, this.props.detailsChangedCallback);
    }

    setIsEnabled = (e) => {
      this.setState({
        isEnabled: e.target.checked
      }, this.props.detailsChangedCallback);
    }

    render(){
      return(
        <React.Fragment>
          <div className="input-field">
              <div className="field-label">
                  <label>Name:</label>
              </div>
              <div className="field-input">
                  <input type="text" value={this.state.name} name="TriggerName" id="textBoxTriggerName" className={this.state.valid ? "" : "error"} onChange={this.setName} ></input>
                  {this.state.duplicateError ?
                  <span className="message message--error margin-left inline-block">{Resources.localizedString.triggerNameExistsErrorMessage}</span>
                  : null }
              </div>
          </div>
          <div className="input-field">
              <div className="field-label">
                  <label>Enabled:</label>
              </div>
              <div className="field-input">
                  <input type="checkbox" checked={this.state.isEnabled} name="IsEnabled" id="checkBoxIsEnabled" className="input" onChange={this.setIsEnabled} />
              </div>
          </div>
        </React.Fragment>
      )
    }
}

function mapStateToProps(state) {
    return {  };
  }

  function mapDispatchToProps(dispatch) {
    return {  };
  }

    export {TriggerDetails as TriggerDetailsNoRedux};

    const ConnectedTriggerDetails = connect(mapStateToProps, mapDispatchToProps)(TriggerDetails);
    export default ConnectedTriggerDetails;
