import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as referenceDataActions from "actions/referenceDataActions";
import * as vehicleGroupActions from "actions/vehicleGroupActions";
import * as triggerActions from "actions/triggerActions";
import PageLayout from 'components/PageLayout';
import Resources from 'utilities/Resources';
import TriggerDetails from 'components/TriggerDetails';
import AddEditTriggerCondition from 'components/AddEditTriggerCondition';
import SelectTriggerActions from 'components/SelectTriggerActions';
import EditTriggerErrorDialogue from 'components/EditTriggerErrorDialogue';
import AddEmails from 'components/AddEmails';
import ListSelector from 'components/ListSelector';
import triggersApi from 'api/triggersApi';
import distributionListApi from 'api/distributionListApi';
import { withRouter } from "react-router";
import routes from 'routes';
import {copy} from 'utilities/graphSettings';
import {Prompt, Link} from "react-router-dom";

class AddEditTrigger extends React.Component{
    static propTypes = {
      match: PropTypes.object,
      location: PropTypes.object.isRequired,
      history: PropTypes.object.isRequired,
      referenceDataActions: PropTypes.object.isRequired,
      vehicleGroupActions: PropTypes.object.isRequired,
      triggerActions: PropTypes.object.isRequired,
      vehicleGroups: PropTypes.array
    }

    constructor(props, context) {
      super(props, context);

      const defaultCondition =
      {
        orderIndex: 0,
        conditionValue: "",
        evaluationDurationSeconds: "",
        selectedEvaluationType: {},
        selectedOperator: {},
        selectedChannel: {},
        conditionTypeId: 1,
        conditionDescription: ""
      };

      this.state = {
        showErrorDialogue: false,
        //Data to be bound
        vehicleGroups: this.props.vehicleGroups,
        vehicleGroupsForSelector: [],
        distributionLists: [],
        distributionListsForSelector: [],
        defaultCondition: copy(defaultCondition),
        //Trigger
        triggerDetails: {name:"", isEnabled: false},
        conditions: [copy(defaultCondition)],
        selectedDistributionLists: [],
        selectedVehicleGroups: [],
        enteredEmails: [],
        selectedActionTypes: [],
        //Validating states
        needsValidating: false,
        validateEmailList: false,
        formIsVaild: false,
        triggerConditionsAreValid: false,
        triggerDetailsAreValid: false,
        previousSubmitAttempted: false,
        formValidationMessage: [],
        changesMadeToTrigger: false
      };

      this.submitButtonClicked = false;

      // DC (3-2-2021): Moved this to a property of the class rather than a state variable. The fact that setting state does not have an immediate effect
      //                is causing the Trigger to be submit multiple times in certain situations, resulting in the same Trigger record being created multiple
      //                times just with a different ID.
      //                For some reason, when there has been a validation error, submit() ends up being called once for each condition!!! ...or something like that.
      //                I can see from debugging that the timing of the setting of state in the line at the start of submit() was slower after there had
      //                been a validation error in a previous submit than in the first submit.
      //                checkFormIsValid() is called multiple times. I don't know why and am having great difficulty following the code. Debugging is not
      //                easy in this environment either as every time you step to the next line there seems to be a reasonable chance it will jump into some
      //                obsure NPM package javascript file.
      //                If this change doesn't work I will just cut my losses and try throttling the submit() function so it will only run once within a given
      //                time interval, no matter how many times it gets called.
      this.formPosted = false;
    }

    addConditionToList = () => {
      let conditionsCopy = [...this.state.conditions];
      let newCondition = copy(this.state.defaultCondition);
      conditionsCopy.push(newCondition);
      this.setState({
        conditions: conditionsCopy
      });
    }

    submitTriggerDetails = () => {
      let validateEmailList = false;
      const notificationAction = this.state.selectedActionTypes.find(n => n.actionTypeId === 1);
      if (notificationAction && this.state.selectedDistributionLists.length == 0 && this.state.enteredEmails.length == 0) {
        validateEmailList = true;
      }

      this.setState({
        validateEmailList,
        needsValidating: true,
        previousSubmitAttempted: true
      }, () => {this.submitButtonClicked = true;});
    }

    conditionIsAverage = (element) => {
      return element.selectedEvaluationType.triggerEvaluationTypeId == 3;
    }

    conditionNotAverage = (element) => {
      return element.selectedEvaluationType.triggerEvaluationTypeId != 3;
    }

    async checkFormIsValid() {
      let formIsValid = true;
      let validationMessages = new Set();
      const conditionsToCheck = this.state.conditions;

      if (!this.state.triggerDetailsAreValid) {
        formIsValid = false;
      }
      if (!this.state.conditionsAreValid){
        formIsValid = false;
      }

      // check to see if the action type is notification and that a disributionlist or email have been entered
      const notificationAction = this.state.selectedActionTypes.find(n => n.actionTypeId === 1);
      if (notificationAction && this.state.selectedDistributionLists.length == 0 && this.state.enteredEmails.length == 0) {
        formIsValid = false;
        validationMessages.add("Please enter an email address or select a distribution list.");
      }

      // testing conditions have correct pattern
      const conditionsContainAnAverage = conditionsToCheck.some(this.conditionIsAverage);
      const conditionsContainAnNoneAverage = conditionsToCheck.some(this.conditionNotAverage);

      if (conditionsContainAnAverage && !conditionsContainAnNoneAverage) {
        formIsValid = false;
        validationMessages.add(Resources.localizedString.pleasePickSomethingAlongSideAnAverage);
      }

      this.setState({
        formValidationMessage: [...validationMessages]
      });

      if (formIsValid) {
        if (this.submitButtonClicked && !this.formPosted) {
          await this.submit();
          return;
        }
      }

      this.setState({
        needsValidating: false,
      }, () => {this.submitButtonClicked = false;});
    }

    //async submit()
    submit = async function () {
      this.formPosted = true;

      this.setState({
        changesMadeToTrigger: false
      });

      const triggerToAdd = {
        triggerId: this.state.triggerId,
        name: this.state.triggerDetails.name.trim(),
        isEnabled: this.state.triggerDetails.isEnabled,
        triggerEmails: this.state.enteredEmails,
        distributionLists: this.state.selectedDistributionLists,
        vehicleGroups: this.state.selectedVehicleGroups,
        triggerActions: this.state.selectedActionTypes,
        conditions: this.state.conditions,
        // The problem with auto-generating a description in the web app is that the API end point may choose to re-arrange the conditions before writing
        // to the database.
        // // description: this.generateDescription(),
        // The API now generates the value if there isn't one already, currently from the condition descriptions.
        description: null,
      };

      let api = {};
      try {
        api = await triggersApi.addEditTrigger(triggerToAdd);

        this.props.triggerActions.setSaved(true);
        this.props.history.push(routes.admin.triggers.index);
      }
      catch(error) {
        api.error = error;
        if (api.error.response && api.error.response.status === 409) {
          this.setState({
            duplicateError: true
          });
        }
        else {
          this.setState({
            showErrorDialogue: true
          })
        }

        // DC: If there has been a submission error, the user needs to be able to click submit again. Otherwise the the Trigger will have been successfully
        //     submitted, meaning we will navigate back to the Triggers page.
        this.formPosted = false;
      }
    }

    generateDescription = () => {
      const conditions = this.state.conditions;
      let description = conditions[0].description;
      for (let i = 1, len = conditions.length; i < len; i++) {
        const condition = conditions[i];
        description = description.concat(" " + Resources.localizedString.and + " ", condition.description);
      }

      return description;
    }

    detailsChangedCallback = () => {
      this.setState({changesMadeToTrigger: true});
    }

    //update triggers parts callbacks
    distributionListUpdateCallback = (distributionLists, changesMadeToDistList) => {
      let selectedDistributionLists = [];
      let changesMadeToTrigger = this.state.changesMadeToTrigger;
      distributionLists.forEach((distributionList) => {
        selectedDistributionLists.push({distributionListId: distributionList.itemId})
      });
      if(changesMadeToDistList)
      {
        changesMadeToTrigger = changesMadeToDistList;
      }
      this.setState({selectedDistributionLists, changesMadeToTrigger});
    }

    vehicleGroupUpdateCallback = (vehicleGroups, changesMadeToVehicleGroups) => {
      let selectedVehicleGroups = [];
      let changesMadeToTrigger = this.state.changesMadeToTrigger;
      if(changesMadeToVehicleGroups)
      {
        changesMadeToTrigger = changesMadeToVehicleGroups
      }
      vehicleGroups.forEach((vehicleGroup) => {
        selectedVehicleGroups.push({vehicleGroupId: vehicleGroup.itemId})
      });
      this.setState({selectedVehicleGroups, changesMadeToTrigger});
    }

    emailListUpdateCallback = (emails, changesMadeToEmailList) => {
      let changesMadeToTrigger = this.state.changesMadeToTrigger;
      if(changesMadeToEmailList)
      {
        changesMadeToTrigger = changesMadeToEmailList;
      }

      this.setState({enteredEmails: emails, changesMadeToTrigger});
    }

    actionTypesUpdateCallback = (actionTypes, changesMadeToActionTypes) => {
      let changesMadeToTrigger = this.state.changesMadeToTrigger;
      if (changesMadeToActionTypes)
      {
        changesMadeToTrigger = changesMadeToActionTypes
      }
      this.setState({selectedActionTypes: actionTypes, changesMadeToTrigger});
    }

    triggerConditionsUpdateCallback = (triggerCondition, changesMadeToConditions) => {
      let conditionsCopy = [...this.state.conditions];
      conditionsCopy[triggerCondition.orderIndex] = triggerCondition;
      let changesMadeToTrigger = this.state.changesMadeToTrigger;

      if(changesMadeToConditions)
      {
        changesMadeToTrigger = changesMadeToConditions;
      }

      this.setState({
        conditions: conditionsCopy,
        changesMadeToTrigger
      });
    }

    removeCondition = (triggerCondition, changesMadeToConditions) => {
      let conditionsCopy = [...this.state.conditions];
      let changesMadeToTrigger = this.state.changesMadeToTrigger;
      if (changesMadeToConditions)
      {
        changesMadeToTrigger = changesMadeToConditions;
      }
      if (conditionsCopy.length > 1)
      {
        conditionsCopy.splice(triggerCondition.orderIndex, 1);
        this.setState({
          conditions: conditionsCopy,
          changesMadeToTrigger
        });
      }
    }

    //check valid componenet callbacks
    //could I cut these functions down
    setConditionsAreValid = (triggerCondition) => {
      let conditions = this.state.conditions;
      let conditionsAreValid = true;

      conditions[triggerCondition.orderIndex] = triggerCondition;
      conditions.forEach((condition) => {
        if (!condition.isValid) {
          conditionsAreValid = false
        }
      });

      this.setState({
        conditions,
        conditionsAreValid
      }, () => {
        this.checkFormIsValid();
      });
    }

    setTriggerDetailsAreValid = (triggerDetails) => {
      this.setState({
        triggerDetails,
        triggerDetailsAreValid: triggerDetails.isValid
      }, () => {
        this.checkFormIsValid();
      });
    }

    async getDistributionLists() {
      const distributionLists = await distributionListApi.getAll();
      let distributionReferenceData = [];
      if (distributionLists && distributionLists.length > 0) {
        distributionLists.forEach((list) =>
        {
          distributionReferenceData.push({itemId: list.distributionListId, value: list.value});
        });
      }

      return distributionReferenceData;
    }

    async bindLists()
    {
      const distributionLists = await this.getDistributionLists();
      await this.props.vehicleGroupActions.getAllVehicleGroups();
      const vehicleGroups = this.state.vehicleGroups;

      let distributionListsToBindToSelector = []
      distributionLists.forEach((distributionList) => {
        distributionListsToBindToSelector.push({
          itemId: distributionList.distributionListId,
          value: distributionList.value
        })});

      let vehicleGroupsToBindToSelector = []
      vehicleGroups.forEach((vehicleGroup) => {
        vehicleGroupsToBindToSelector.push({
            itemId: vehicleGroup.vehicleGroupId,
            value: vehicleGroup.name
          })});


      this.setState({
        distributionListsForSelector: distributionLists,
        vehicleGroupsForSelector: vehicleGroupsToBindToSelector
      });
    }

    async componentDidUpdate(prevProps){
      if(JSON.stringify(this.props.vehicleGroups) !== JSON.stringify(prevProps.vehicleGroups))
      {
        this.setState({
          vehicleGroups: this.props.vehicleGroups
        });
      }
    }

    async componentDidMount(){
      const triggerId = this.props.match.params.triggerId;
      if (triggerId) {
        this.setState({
          loading: true
        });
      }

      this.props.referenceDataActions.refreshEvaluationTypeReferences();
      this.props.referenceDataActions.refreshOperatorReferences();

      await this.bindLists();
      if (triggerId)
      {

        let trigger = {};
        try {
          trigger = await triggersApi.getById(triggerId);
          const triggerDetails = {
            name: trigger.name,
            isEnabled: trigger.isEnabled
          };

          let vehicleGroupsToBind = [];
          trigger.vehicleGroups.forEach((vehicleGroup) => {
            vehicleGroupsToBind.push({
                itemId: vehicleGroup.vehicleGroupId
            })
          });

          let distributionListsToBind = [];
          trigger.distributionLists.forEach((distributionList) => {
            distributionListsToBind.push({
              itemId: distributionList.distributionListId
            })
          });

          this.setState({
            loading: false,
            triggerId: triggerId,
            triggerDetails: triggerDetails,
            conditions: [...trigger.conditions],
            selectedActionTypes: trigger.triggerActions,
            previouslySelectedvehicleGroups: vehicleGroupsToBind,
            previouslySelectedDistributionLists: distributionListsToBind,
            previouslySelectedEmails: trigger.triggerEmails
          });
        }
        catch (error) {
          // Set error and maybe do something with it later if API call fails???
          trigger.error = error;
          this.setState({
            loading: false
          });
        }
      }
    }

    onClose = () => {
      this.setState({
        showErrorDialogue: false
      });
    }

    componentWillUnmount() {
      this.setState({
        needsValidating: false,
      }, () => {this.submitButtonClicked = false;});
    }

    render() {
      const resources = Resources.localizedString;
      const conditions = this.state.conditions.map((item, orderIndex) => {
        item.orderIndex = orderIndex;
        return (<li key={item.orderIndex}>
          <AddEditTriggerCondition
            triggerConditionsUpdateCallback = {this.triggerConditionsUpdateCallback}
            triggerCondition={item}
            needsValidating = {this.state.needsValidating}
            setConditionsAreValid = {this.setConditionsAreValid}
            removeCondition = {this.removeCondition} />
        </li>);
      });
      return(
          <div className="add-trigger-container relative">
             { this.state.triggerId &&
              <React.Fragment>
                <Prompt
                  when={this.state.changesMadeToTrigger}
                  message={resources.leaveTriggerEditPageConfirmationText}
                />
              </React.Fragment>
             }
            <h1>{ this.state.triggerId ? "Edit Trigger" : resources.createTrigger}</h1>
            <div className="page-section">
              <TriggerDetails
                triggerDetails={this.state.triggerDetails}
                setTriggerDetailsAreValid={this.setTriggerDetailsAreValid}
                needsValidating={this.state.needsValidating}
                detailsChangedCallback = {this.detailsChangedCallback}
                duplicateError = {this.state.duplicateError} />
            </div>
            <div className="trigger-container">
              <div>
                <span id="labelInformation" className="message message--notice message--notice--with-icon inline-block right">{resources.averageEvaluationTypeNotice}</span>
                <h2>{resources.triggerStatement}</h2>
              </div>
              <ul id="ListTriggerCondtions" className="selection-list">
                {conditions}
              </ul>
              <button onClick={this.addConditionToList} className="button right">{resources.addStatement}</button>
            </div>
            <div className="overflow-hidden margin-top">
              <SelectTriggerActions
                actionTypesUpdateCallback={this.actionTypesUpdateCallback}
                selectedActionTypes={this.state.selectedActionTypes} />
                <div className="column-third vertical-top">
                  <AddEmails emailListUpdateCallback={this.emailListUpdateCallback}
                    selectedEmails={this.state.previouslySelectedEmails}
                    triggerEmailList={true}
                    emailLabel="Emails"
                    tall={true}
                    externalValidation={this.state.needsValidating}
                    showValidation={this.state.validateEmailList} />
                </div>
                <ListSelector
                  listSelectorUpdateCallback={this.distributionListUpdateCallback}
                  listItemsToBind={this.state.distributionListsForSelector}
                  listTitle={resources.distributionLists}
                  previouslySelectedItems={this.state.previouslySelectedDistributionLists} />
            </div>
            <div className="pageSeperator"/>
            <div className="overflow-hidden">
              <ListSelector
                listSelectorUpdateCallback={this.vehicleGroupUpdateCallback}
                listItemsToBind={this.state.vehicleGroupsForSelector}
                listTitle={resources.vehicleGroups}
                previouslySelectedItems={this.state.previouslySelectedvehicleGroups} />
            </div>
            <div className="overflow-hidden">
              <div className="column-third vertical-bottom">
              <Link to={routes.admin.triggers.index} className="button button--secondary">{resources.cancel}</Link>
              </div>
              <div className="column-third vertical-bottom"></div>
              <div className="column-third vertical-bottom">
                <button onClick={this.submitTriggerDetails} className="button right">{resources.submit}</button>
              </div>
            </div>
            <EditTriggerErrorDialogue showDialogue={this.state.showErrorDialogue} onClose={this.onClose} />
            {this.state.loading ?
              <div className="loading"></div>
            : null}
            {this.state.formValidationMessage.length > 0 ?
              <div className="overflow-hidden">
                <span className="message message--error inline-block right">
                  {this.state.formValidationMessage.map(function (errorMessage, i) {
                    return (<span key={i} className="block">{errorMessage}</span>)
                  })}
                </span>
              </div>
            : null}
          </div>
      )
    }
}

function mapStateToProps(state) {
    return {
      vehicleGroups: state.vehicleGroup.vehicleGroups
    };
  }

 function mapDispatchToProps(dispatch) {
    return {
      referenceDataActions: bindActionCreators(referenceDataActions, dispatch),
      vehicleGroupActions: bindActionCreators(vehicleGroupActions, dispatch),
      triggerActions: bindActionCreators(triggerActions, dispatch)
    };
  }

export {AddEditTrigger as AddEditTriggerNoRedux};

const ConnectedAddEditTrigger = connect(mapStateToProps, mapDispatchToProps)(AddEditTrigger);
export default withRouter(PageLayout(ConnectedAddEditTrigger));
