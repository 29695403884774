import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import DialogueTitle from 'components/DialogueTitle';
import DialogueButtons from 'components/DialogueButtons';
import Resources from 'utilities/Resources';

import errorIcon from 'images/error_icon.svg';

class ShareDialogueContentFailed extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  }

  constructor(props, context) {
    super(props, context);
    this.state = {};

    this.initialState = this.state;
  }

  close = () => {
    this.setState(this.initialState, () => {
      this.props.onClose();
    });
  };

  render = () => {
    return (
      <React.Fragment>
        <DialogueTitle title="Error" />
        <div className="padded">
          <div className="padded">
            <div className="margin-vertical padded-vertical text-center">
              <img src={errorIcon} className="margin-vertical padded--double" />
              <br />
              <span className="margin-vertical padded--double">
                {Resources.localizedString.shareSettingsLinkError}
                <br />
                {Resources.localizedString.pleaseTryAgainInFewMinutes}
              </span>
            </div>
          </div>
        </div>
        <DialogueButtons
          confirmFunction={this.close}
          confirmButtonText="Close" />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}
export { ShareDialogueContentFailed as ShareDialogueContentFailedNoRedux };

const ConnectedShareDialogueContentFailed = connect(mapStateToProps, mapDispatchToProps)(ShareDialogueContentFailed);
export default ConnectedShareDialogueContentFailed;
