import * as actionTypes from 'constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

export default function graphConfigurationDialogueReducer(state = initialState.graphConfigurationDialogue, action) {
  switch (action.type) {
    case actionTypes.OPEN_CHANNEL_DIALOGUE:
      return objectAssign({}, state, action.newSettings);

    case actionTypes.CLOSE_CHANNEL_DIALOGUE:
      return objectAssign({}, state, action.newSettings);

    default:
      return state;
  }
}