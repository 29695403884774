import React from 'react';
import {Link} from 'react-router-dom';
import PageLayout from 'components/PageLayout';
import routes from 'routes';
import Resources from 'utilities/Resources';

const NotFoundPage = () => {
  const homeRoute = routes.home.replace('/:vehicleCode?', '');
  const resources = Resources.localizedString;
  return (
    <div className="page-content">
      <h1>
        {resources.fourZeroFourPageNotFound}
      </h1>
      <Link to={homeRoute}>{resources.goBackToHomepage}</Link>
    </div>
  );
};

export default PageLayout(NotFoundPage);
