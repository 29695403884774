// import {UPDATE_GRAPH_SETTINGS, FETCH_GRAPH_DATA_POINTS} from 'constants/actionTypes';
import * as actionTypes from 'constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';
import {getAvailableResolutionForRange} from 'utilities/graphZoom.js';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function graphSettingsReducer(state = initialState.graphSettings, action) {
  switch (action.type) {
    case actionTypes.UPDATE_GRAPH_SETTINGS:

      // If any of the graph channels have changed, override any detail settings and revert to whole range.
      // This could happen when the user selects a different preset or when they change things using the Set Data Channels dialogue.
      if (action.newSettings.analogueGraphs || action.newSettings.digitalGraphs) {
        action.newSettings.detailQuery = false;
        action.newSettings.detailResolution =
          getAvailableResolutionForRange(action.newSettings.rangeStart || state.rangeStart,
                                         action.newSettings.rangeEnd || state.rangeEnd);
      }

      // In a real app using Redux, you might use redux-thunk and handle the async call in fuelSavingsActions.js
      return objectAssign({}, state, action.newSettings);

    case actionTypes.FETCH_GRAPH_DATA_POINTS:
      return objectAssign({}, state, {shouldFetchPointsFromApi: action.shouldFetchPointsFromApi});

    default:
      return state;
  }
}
