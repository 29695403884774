import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as presetActions from 'actions/presetActions';
import * as referenceDataActions from 'actions/referenceDataActions';
import PageLayout from 'components/PageLayout';
import ChannelSelection from 'components/ChannelSelection';
import EditPresetErrorDialogue from 'components/EditPresetErrorDialogue';
import * as channelTypes from 'constants/channelTypes';
import routes from 'routes';
import moment from 'moment';
import Resources from 'utilities/Resources';
import presetsApi from 'api/presetsApi';
import {withRouter} from "react-router";
import {Prompt, Link} from "react-router-dom";
import {copy} from 'utilities/graphSettings';

class EditPreset extends React.Component {
  static propTypes = {
    presetActions: PropTypes.object.isRequired,
    referenceDataActions: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      presetId: '',
      presetName: '',
      presetAnalogueConfiguration: null,
      presetDigitalConfiguration: null,
      presetModifiedBy: '',
      presetModifiedDate: null,
      changesMade: false,
      blocking: false,
      showUpdatePresetDialogue: false
    }

    this.submit = this.submit.bind(this);
  }

  async componentDidMount(){
    // get from match / location to get the id
    let api = {};
    try {
      api = await presetsApi.getById(this.props.match.params.presetId);
      const configuration = JSON.parse(api.configuration);

      if(api.createdByUserId != this.props.user.userId)
      {
        this.props.history.push(routes.admin.presets.index);
      }

      this.setState({
        presetName: api.name,
        presetAnalogueConfiguration: configuration.analogueGraphs,
        presetDigitalConfiguration: configuration.digitalGraphs,
        presetModifiedBy: api.modifiedBy,
        presetModifiedDate: moment.utc(api.dateModified),
        presetId: this.props.match.params.presetId
      });
    } catch (error) {
      // Set error and maybe do something with it later if API call fails???
      api.error = error;
    }

    if (api.error) {
      this.props.history.push(routes.admin.presets.index);
    }
  }

  onGraphUpdate = (graphs, channelType) => {
    if (channelType == channelTypes.ANALOGUE_CHANNEL) {
      this.setState({
        presetAnalogueConfiguration: graphs,
        changesMade: true
      });
    } else if (channelType == channelTypes.DIGITAL_CHANNEL) {
      this.setState({
        presetDigitalConfiguration: graphs,
        changesMade: true
      });
    }
  };

  submit = async function() {
    let api = {};
    let configuration = {analogueGraphs: copy(this.state.presetAnalogueConfiguration), digitalGraphs: copy(this.state.presetDigitalConfiguration)}

    const request = {
      configuration: JSON.stringify(configuration),
      presetId: this.state.presetId
    };

    try {
      api = await presetsApi.updatePreset(request);
      this.setState({
        changesMade: false
      });

      // Add toast notifications
      this.props.presetActions.setSaved(true);
      this.props.referenceDataActions.refreshEnabledPresetsData();
      this.props.history.push(routes.admin.presets.index);

    } catch (error) {
      api.error = error;
      this.setState({
        showUpdatePresetDialogue: true
      });
    }
  }

  onClose = () => {
    this.setState({
      showUpdatePresetDialogue: false
    });
  }

  render() {
    const resources = Resources.localizedString;
    return (
      <div className="page-content">
        { this.state.presetId &&
          <React.Fragment>
            <Prompt
              when={this.state.changesMade}
              message={resources.leavePresetEditPageConfirmationText}
            />
            <EditPresetErrorDialogue showUpdatePresetDialogue={this.state.showUpdatePresetDialogue} onClose={this.onClose} />
            <h1>
              {this.state.presetName}
            </h1>
          </React.Fragment>
        }
        { this.state.presetAnalogueConfiguration &&
        <div className="padded-vertical margin-vertical--triple highlight-panel">
          <div className="padded--double overflow-hidden">
            <h3>{`${resources.analogue} ${resources.channels}`}</h3>
            <ChannelSelection channelType={channelTypes.ANALOGUE_CHANNEL} graphs={this.state.presetAnalogueConfiguration} onGraphUpdate={this.onGraphUpdate} />
          </div>
        </div>
        }
        { this.state.presetAnalogueConfiguration &&
          <div className="padded-vertical margin-vertical--triple highlight-panel">
            <div className="padded--double margin overflow-hidden">
              <h3>{`${resources.digital} ${resources.channels}`}</h3>
              <ChannelSelection channelType={channelTypes.DIGITAL_CHANNEL} graphs={this.state.presetDigitalConfiguration} onGraphUpdate={this.onGraphUpdate} />
            </div>
          </div>
        }
        { this.state.presetId &&
        <div className="overflow-hidden">
          <button className="button right" onClick={this.submit}>{`${resources.save} ${resources.preset}`}</button>
          <Link to={routes.admin.presets.index} className="button button--secondary">{resources.cancel}</Link>
        </div>
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
  // If do anything expensive in here, introduce memoization using a library like Reselect.
}

function mapDispatchToProps(dispatch) {
  return {
    presetActions: bindActionCreators(presetActions, dispatch),
    referenceDataActions: bindActionCreators(referenceDataActions, dispatch)
  };
}

export {EditPreset as EditPresetNoRedux};

const ConnectedEditPreset = connect(mapStateToProps, mapDispatchToProps)(EditPreset);
export default withRouter(PageLayout(ConnectedEditPreset));
