import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Modal from 'react-modal';
import {Tab, Tabs, TabList, TabPanel} from  'react-tabs';
import DialogueTitle from 'components/DialogueTitle';
import DialogueButtons from 'components/DialogueButtons';
import ChannelSelection from 'components/ChannelSelection';
import * as graphSettingsActions from 'actions/graphSettingsActions';
import * as referenceDataActions from 'actions/referenceDataActions';
import * as presetActions from 'actions/presetActions';
import * as channelTypes from 'constants/channelTypes';
import * as permissions from 'constants/permissions';
import {isAllowed} from 'utilities/authorization';
import presetsApi from 'api/presetsApi';
import Resources from 'utilities/Resources';
import {copy} from 'utilities/graphSettings';
import 'styles/_dialogue.scss';
import 'styles/_preset-save.scss';

Modal.setAppElement('#app')

class ChannelSelectionDialogueContent extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onRequested: PropTypes.func.isRequired,
    graphSettingsActions: PropTypes.object.isRequired,
    referenceDataActions: PropTypes.object.isRequired,
    presetActions: PropTypes.object.isRequired,
    analogueGraphs: PropTypes.array.isRequired,
    digitalGraphs: PropTypes.array.isRequired,
    savePreset: PropTypes.bool.isRequired,
    onPresetSave: PropTypes.func,
    user: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      analogueGraphs: copy(props.analogueGraphs),
      digitalGraphs: copy(props.digitalGraphs),
      saveAsPreset: props.savePreset,
      presetName:'',
      presetValid: true,
      errorMessage: ''
    };

    this.submit = this.submit.bind(this);
    this.initialState = copy(this.state);
  }

  onGraphUpdate = (graphs, channelType) => {
    if (channelType == channelTypes.ANALOGUE_CHANNEL) {
      this.setState({
        analogueGraphs: graphs
      });
    } else if (channelType == channelTypes.DIGITAL_CHANNEL) {
      this.setState({
        digitalGraphs: graphs
      });
    }
  };

  reset = () => {
    this.setState(this.initialState);
    this.props.presetActions.setSaveNew(false);
  };

  close = () => {
    this.props.onClose();
  }

  validatePresetName = () => {
    let valid = true;
    if (!this.state.presetName || !this.state.presetName.trim()) {
      valid = false;
    }

    this.setState({
      presetValid: valid
    });

    return valid;
  }

  submit = async function () {
    if (this.state.saveAsPreset) {
      if (this.validatePresetName()) {
        let requested = true;
        let api = {};
        let configuration = {analogueGraphs: copy(this.state.analogueGraphs), digitalGraphs: copy(this.state.digitalGraphs)};
        const request = {
          name: this.state.presetName.trim(),
          configuration: JSON.stringify(configuration)
        }

        try {
          api = await presetsApi.savePreset(request);
        } catch (error) {
          api.error = error;
        }

        if (api.status === 200) {
          this.props.referenceDataActions.refreshEnabledPresetsData();
          this.props.presetActions.setSelectedId(api.data);
          this.props.graphSettingsActions.updateGraphSettings(configuration);
          this.initialState.analogueGraphs = copy(this.state.analogueGraphs);
          this.initialState.digitalGraphs = copy(this.state.digitalGraphs);
          this.reset();
          if (this.props.onPresetSave) {
            this.props.onPresetSave();
          }
        // 409 is the status code for a conflict. Seemed approppriate if exists with the same name
        } else if (api.error.response && api.error.response.status === 409) {
          requested = false;
          this.setState({
            presetValid: false,
            errorMessage: api.error.response.data.message
          });
        }
        // Otherwise just passed error to dialogue to show the completed error state
        this.props.onRequested(requested, api);
      }
    } else {
      this.props.presetActions.setSelectedId('');
      this.props.graphSettingsActions.updateGraphSettings({analogueGraphs: this.state.analogueGraphs, digitalGraphs: this.state.digitalGraphs});
      this.initialState.analogueGraphs = copy(this.state.analogueGraphs);
      this.initialState.digitalGraphs = copy(this.state.digitalGraphs);
      this.props.onRequested(false, false, null);
      this.reset();
      this.close();
    }

  };

  presetNameKeyUp = (e) => {
    if (e.target.value) {
      this.setState({
        presetName: e.target.value
      });
    } else {
      this.setState({
        presetName: ''
      });
    }
  }

  saveAsPresetChange = (e) => {
    this.setState({
      saveAsPreset: e.target.checked
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.analogueGraphs !== prevProps.analogueGraphs || this.props.digitalGraphs !== prevProps.digitalGraphs) {
      this.setState({
        analogueGraphs: copy(this.props.analogueGraphs),
        digitalGraphs: copy(this.props.digitalGraphs)
      });
      this.initialState.analogueGraphs = copy(this.props.analogueGraphs);
      this.initialState.digitalGraphs = copy(this.props.digitalGraphs);
    }

    if (this.props.savePreset !== prevProps.savePreset) {
      this.setState({
        saveAsPreset: this.props.savePreset
      });
    }
  }

  render() {
    const resources = Resources.localizedString;
    return(
        <React.Fragment>
          <DialogueTitle title={resources.setDataChannels} />
          <Tabs className="channel-select-tab-wrapper">
            <TabList className={this.state.errorMessage ? "react-tabs__tab-list invalid-preset": "react-tabs__tab-list" }>
              {isAllowed(this.props.user, [permissions.RDD_CAN_READ]) &&
                <span className="right preset-save padded-horizontal">
                  {this.state.errorMessage ?
                    <span className="message message--error margin-right no-margin-top inline-block vertical-middle">{this.state.errorMessage}</span>
                  : null }
                  <input type="checkbox" className="margin-right" id="preset" name="preset" checked={this.state.saveAsPreset} onChange={this.saveAsPresetChange} />
                  <label htmlFor="preset" className="margin-right">{resources.saveAsPreset}</label>
                  {this.state.saveAsPreset ?
                    <input type="text" placeholder={resources.presetName} className={!this.state.presetValid ? "margin-left margin-right error" : "margin-left margin-right"} value={this.state.presetName} onChange={this.presetNameKeyUp} />
                  : null }
                </span>
              }
              <Tab>{resources.analogue}</Tab>
              <Tab>{resources.digital}</Tab>
            </TabList>
            <TabPanel>
              <ChannelSelection channelType={channelTypes.ANALOGUE_CHANNEL} graphs={this.state.analogueGraphs} onGraphUpdate={this.onGraphUpdate} />
            </TabPanel>
            <TabPanel>
              <ChannelSelection channelType={channelTypes.DIGITAL_CHANNEL} graphs={this.state.digitalGraphs} onGraphUpdate={this.onGraphUpdate} />
            </TabPanel>
          </Tabs>
          <DialogueButtons
            cancelButtonText={resources.cancel}
            showCancelButton={true}
            cancelFunction={this.close}
            confirmFunction={this.submit}
            confirmButtonText={!this.state.saveAsPreset ? resources.applyChanges : resources.saveAndApplyChanges} />
        </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    analogueGraphs: state.graphSettings.analogueGraphs,
    digitalGraphs: state.graphSettings.digitalGraphs,
    savePreset: state.preset.saveNew,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    graphSettingsActions: bindActionCreators(graphSettingsActions, dispatch),
    referenceDataActions: bindActionCreators(referenceDataActions, dispatch),
    presetActions: bindActionCreators(presetActions, dispatch)
  };
}

export {ChannelSelectionDialogueContent as ChannelSelectionDialogueContentNoRedux};

const ConnectedChannelSelectionDialogueContent = connect(mapStateToProps, mapDispatchToProps)(ChannelSelectionDialogueContent);
export default ConnectedChannelSelectionDialogueContent;
