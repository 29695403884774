import React from 'react';
import {PropTypes} from 'prop-types';
import {NavLink} from 'react-router-dom';
import routes from 'routes';
import Header from 'components/Header';
import Footer from 'components/Footer';

const demoPageLayout = (WrappedComponent, defaultActiveColor) => {
  defaultActiveColor = defaultActiveColor || 'blue';
  
  const NewComponent = props => {
    // console.log('props', props);
    const {...passThroughProps} = props;
    const activeStyle = {color: props.activeColor};

    return (
      <React.Fragment>
        <Header />
        <div>
          <NavLink exact to={routes.demo.home} activeStyle={activeStyle}>Home</NavLink>
          {' | '}
          <NavLink to={routes.demo.fuelSavings} activeStyle={activeStyle}>Demo App</NavLink>
          {' | '}
          <NavLink to={routes.demo.about.replace(':topic', 'doughnuts')} activeStyle={activeStyle}>About</NavLink>
          {' | '}
          <NavLink to={routes.demo.jwtTest} activeStyle={activeStyle}>JWT Test</NavLink>
          {' | '}
          <NavLink to={routes.demo.graphs} activeStyle={activeStyle}>Graphs</NavLink>
        </div>
        <WrappedComponent {...passThroughProps} />
        <Footer />
      </React.Fragment>
    );
  };

  NewComponent.propTypes = {
    activeColor: PropTypes.string
  }

  NewComponent.defaultProps = {
    activeColor: defaultActiveColor
  }

  return NewComponent;
};

export default demoPageLayout;
