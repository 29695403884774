import vehicleGroupsApi from 'api/vehicleGroupsApi';
import * as types from 'constants/actionTypes';

function fetchVehicleGroupsDataSuccess(vehicleGroups) {
  return {type: types.FETCH_VEHICLE_GROUPS_DATA_SUCCESS, vehicleGroups};
}

export function setSaved (saved) {
  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_VEHICLE_GROUP_SAVED_FLAG,
      saved
    });
  };
}

export function setVehicleGroups (vehicleGroups) {
  return function (dispatch) {
    return dispatch(fetchVehicleGroupsDataSuccess(vehicleGroups));
  };
}

export function getAllVehicleGroups() {
  return async function (dispatch) {
    try {
      const vehicleGroups = await vehicleGroupsApi.get();
      return dispatch(fetchVehicleGroupsDataSuccess(vehicleGroups));
    }
    catch (error) {
      // alert(error);
      throw error;
    }
  };
}