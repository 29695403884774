// Originally copied from an answer on https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript.
const cookies = {};

const millisecondsInDay = 24 * 60 * 60 * 1000;

cookies.set = (name, value, days) => cookies.setToExpireByMillisecondsInFuture(name, value, days * millisecondsInDay);

cookies.setToExpireByMillisecondsInFuture = (name, value, milliseconds) => {
  let expires = '';
  if (milliseconds) {
      const date = new Date();
      date.setTime(date.getTime() + milliseconds);
      expires = '; expires=' + date.toUTCString();
  }

  let cookie = name + '=' + (value || '')  + expires + '; path=/';

  if (process.env.NODE_ENV == 'production') {
    // We cannot set the HttpOnly flag when creating cookie via javascript:
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#JavaScript_access_using_Document.cookie
    cookie += '; secure';
  }

  document.cookie = cookie;
};

cookies.get = name => {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

cookies.erase = name => {
  document.cookie = name + '=; Max-Age=-99999999;';
};

export default cookies;
