import * as types from 'constants/actionTypes';
import moment from 'moment';
import {getDistanceSidebarValue} from 'utilities/distanceUtilities';
import {getDygraphsArrayWithDigitalGraphsFirst, getGraphChannelSidebarValues} from 'utilities/dygraphsFunctions';

export function updateSidebarValues(e, pointTimestamp, pts, row) {
  const dygraphs = getDygraphsArrayWithDigitalGraphsFirst();
  let graphChannelValues = [];
  let currentTimestamp = null;

  for (let i = 0; i < dygraphs.length; i++) {
    const graph = dygraphs[i];
    // Get data row for hovered time. This will return null if there are no points for any channel in the graph for the specified time.
    const dataRowIndex = graph.getRowForX(pointTimestamp);
    graph.setSelection(dataRowIndex);

    graphChannelValues = [...graphChannelValues, ...getGraphChannelSidebarValues(graph, pointTimestamp)];
  }

  if (pointTimestamp) {
    currentTimestamp = moment(pointTimestamp);
  }

  const distanceValue = getDistanceSidebarValue(pointTimestamp);

  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_SIDEBAR_VALUES,
      sidebarValues: {currentTimestamp: currentTimestamp, channelValues: graphChannelValues, distance: distanceValue},
    });
  };
}

export function removeSidebarValues(e) {
  const dygraphs = getDygraphsArrayWithDigitalGraphsFirst();

  for (var i = 0; i < dygraphs.length; i++) {
    dygraphs[i].clearSelection();
  }

  return function (dispatch) {
    return dispatch({
      type: types.REMOVE_SIDEBAR_VALUES,
      //what to do with values
      // talk of using a hairline to default back to.
      //sidebarValues: {currentTimestamp: moment(x)},
    });
  };
}
