/* eslint-disable import/no-named-as-default */
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {hot} from 'react-hot-loader';
import deepForceUpdate from 'react-deep-force-update';
import routes from 'routes';
import {Switch, Route, withRouter} from 'react-router-dom';
import * as referenceDataActions from 'actions/referenceDataActions';
import * as userActions from 'actions/userActions';
import Resources from 'utilities/Resources';
import cookies from 'utilities/cookieUtilities';
import cookieNames from 'constants/cookieNames';
import session from 'utilities/sessionUtilities';
import urlUtilities from 'utilities/urlUtilities';
import Main from 'components/Main';
import ExternalLoginPage from 'components/ExternalLoginPage';
import ManagePresets from 'components/ManagePresets';
import EditPreset from 'components/EditPreset';
import VehiclePage from 'components/VehiclePage';
import AddEditVehicleGroup from 'components/AddEditVehicleGroup';
import NotFoundPage from 'components/NotFoundPage';
import * as permissions from 'constants/permissions';
import {isAllowed} from 'utilities/authorization';
import TriggerPage from 'components/TriggerPage'
import AddEditTrigger from 'components/AddEditTrigger'

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

// Simple example from https://reacttraining.com/react-router/core/api/Route
const User = ({ match }) => {
  return <h1>Hello {match.params.username}!</h1>
};

User.propTypes = {
  match: PropTypes.object.isRequired,
};

class App extends React.Component {
  static propTypes = {
    referenceDataActions: PropTypes.object.isRequired,
    userActions: PropTypes.object.isRequired,
    children: PropTypes.element,
    userSettings: PropTypes.object.isRequired,
    resources: PropTypes.object.isRequired,
    user: PropTypes.object,
    history: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    Resources.updateLocalizedString(this.props.resources, this.props.userSettings.selectedLanguage);
    if (!this.props.user) {
      const jwt = session.parseJwtAppUserJson();
      this.props.userActions.updateUser(jwt);
      if (jwt && process.env.RAYGUN_API_KEY) {
        rg4js('setUser', {
          identifier: jwt.userId,
          isAnonymous: false,
          email: jwt.email,
          firstName: jwt.firstName,
          fullName: `${jwt.firstName} ${jwt.lastName}`
        });
      }
    }

    if (process.env.RAYGUN_API_KEY) {
      this.props.history.listen((location, action) => {
        rg4js('trackEvent', {
          type: 'pageView',
          path: location.pathname + location.search // Or perhaps window.location.hash
        });
      });
    }

    const sessionQueryStringValue = session.getSessionQueryStringValue();
    if (sessionQueryStringValue) {
      const existingUserSessionId = session.getExistingJwtUserSessionId();
      if (existingUserSessionId === sessionQueryStringValue) {
        // We will not fetch the JWT from the API if there is an existing cookie containing a JWT with a matching userSessionId.
        // We will use the existing JWT and just change the URL shown in the browser without redirecting.
        window.history.replaceState(history.state, document.title, session.generateSameUrlWithoutSessionQueryString());
      }
      else {
        this.needToFetchJwtThenRedirect = true;

        // NOTE: constructors cannot be async. Therefore we are using the older fashioned Promise syntax as we absolutely have to perform certain actions if the
        //       session query string is set without continuing on with other things.
        session.fetchJwtFromApiAndGenerateRedirectUrl(sessionQueryStringValue).then(result => {
          const [jwt, redirectUrl] = result;
          cookies.set(cookieNames.jwt, jwt, 1);
          // cookies.setToExpireByMillisecondsInFuture('cookieNames.jwt, jwt, 20000);

          // DC: I just went for a standard javascript redirect. I am not sure if redirecting via react-router would be better or not.
          window.location.replace(redirectUrl);
        }, error => {
          // If there is an exception in the API call it is likely that no matching UserSession record was found or a matching one had timed out or was set to IsClosed.
          // NOTE: If the use has an existing jwt cookie in there browser that will be used instead.
          this.needToFetchJwtThenRedirect = false;
          // DC: If You think this is a dirty hack, it is but it achieved the desired result. Could do to be changed at some point as should be a proper alternative.
          this.forceUpdate();
        });

        return;
      }
    }

    // The second part is just to ensure that document.referrer does not contain a URL from the RDD website. This happened when I clicked the browser back button
    // and went to a previous RDD page from an RDD page. It could possibly have also happened during the redirect when a session is first started.
    // A page called RddLink.html located in the wwwroot folder of the API project can be used to test things.
    if (document.referrer && !urlUtilities.doesDocumentReferrerHaveSameHostNameAsThisSite()) {
      cookies.set(cookieNames.lastReferrerUrl, document.referrer, 1);
    }

    // NOTE(DC): Both these functions are async but we cannot await them here as constructors cannot be async. Maybe there would be a more appropriate
    //           place to put them (component lifecycle methods can be async).
    //           Also, if they do end up being run synchronously, it won't be bad as we need those Redux store properties to be initialised anyway.
    //           Whatever the case, this works.
    this.props.referenceDataActions.refreshVehiclesData();
    this.props.referenceDataActions.refreshChannelsData();
    this.props.referenceDataActions.refreshEnabledPresetsData();
  }

  render() {
    if (!session || session.userDoesNotHaveGeneralAccessToRdd()) {
      return (
        <ExternalLoginPage />
      );
    }

    return (
      <div>
        <Switch>
          {isAllowed(this.props.user, [permissions.RDD_CAN_READ]) &&
            <Route path={routes.admin.presets.edit} component={EditPreset} />
          }
          {isAllowed(this.props.user, [permissions.RDD_CAN_READ]) &&
            <Route path={routes.admin.presets.index} component={ManagePresets} />
          }
          {isAllowed(this.props.user, [permissions.RDD_CAN_CONFIGURE]) &&
            <Route path={routes.admin.triggers.addEditTrigger} component={AddEditTrigger} />
          }
          {isAllowed(this.props.user, [permissions.RDD_CAN_CONFIGURE]) &&
            <Route path={routes.admin.triggers.index} component={TriggerPage} />
          }
          {isAllowed(this.props.user, [permissions.RDD_CAN_CONFIGURE]) &&
            <Route path={routes.admin.vehicleGroups.addEdit} component={AddEditVehicleGroup} />
          }
          {isAllowed(this.props.user, [permissions.RDD_CAN_CONFIGURE]) &&
            <Route path={routes.admin.vehicleGroups.index} component={VehiclePage} />
          }
          <Route exact path={routes.home} component={Main} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    );
  }

  componentDidUpdate() {
    if (Resources.selectedLanguage !== this.props.userSettings.selectedLanguage) {
      Resources.updateLocalizedString(this.props.resources, this.props.userSettings.selectedLanguage);
      // NOTE(DC): The package documentation says not to use this in application code: https://github.com/gaearon/react-deep-force-update
      // HOWEVER:  I think that is a warning about trying to overcome bad usage of React with a global "force everything to update".
      //           Change of locale is one place where it has a worthwhile value as we KNOW all components that use "resources" from the Redux store will need to
      //           refresh and they will not do so until their state changes or their parents re-render them. See comments in Resources.js.
      deepForceUpdate(this);
    }
  }
}

function mapStateToProps(state) {
  return {
    userSettings: state.userSettings,
    resources: state.resources,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    referenceDataActions: bindActionCreators(referenceDataActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  };
}

const app = hot(module)(App);
export { app as AppNoRedux };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(app));
