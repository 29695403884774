import moment from 'moment';

const dateTime = {};

dateTime.generateRangeOfSameDurationWhichEndsNow = (rangeStart, rangeEnd) => {
  const currentRangeSeconds = rangeEnd.diff(rangeStart, 'seconds');
  const newRangeEnd = new moment();
  const newRangeStart = new moment().subtract(currentRangeSeconds, 'seconds');
  return {newRangeStart, newRangeEnd};
};

const timeToSecondComparisonFormat = 'YYYY/MM/DD HH:mm:ss';

dateTime.getToNearestSecond = momentDateTime => momentDateTime.format(timeToSecondComparisonFormat);

export default dateTime;
