import React from 'react';
import { connect } from 'react-redux';
import PageLayout from 'components/PageLayout';
import ExternalLoginSelector from 'components/ExternalLoginSelector'

class ExternalLoginPage extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <div className="login-content-wrapper">
            <div className="login-content">
              <div className="restricted-page-width">
              <ExternalLoginSelector />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export { ExternalLoginPage as ExternalLoginNoRedux };

export default PageLayout(connect(mapStateToProps, mapDispatchToProps)(ExternalLoginPage));
