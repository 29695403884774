import * as types from 'constants/actionTypes';
import sharingApi from 'api/sharingApi';
import moment from 'moment';

function getShareSettingsByIdSuccess(sharingSettings) {
  let graphSettings = JSON.parse(sharingSettings.configuration);

  // update times to moment objects
  graphSettings.rangeStart = moment(graphSettings.detailRangeStart);
  graphSettings.rangeEnd = moment(graphSettings.detailRangeEnd);
  graphSettings.detailRangeStart = moment(graphSettings.detailRangeStart);
  graphSettings.detailRangeEnd = moment(graphSettings.detailRangeEnd);
  graphSettings.detailQuery = false;
  graphSettings.advancedSearchPoints = [];
  graphSettings.shareId = sharingSettings.shareId;

  return {
    type: types.UPDATE_GRAPH_SETTINGS,
    newSettings: graphSettings
  };
}

export function getById(shareId) {
  return async function (dispatch) {
    try {
      const sharingSettings = await sharingApi.getById(shareId);
      return dispatch(getShareSettingsByIdSuccess(sharingSettings));
    }
    catch (error) {
        // OBSERVATION: At one point the Redux store seemed to disappear. I can't see why an exception occurring here should affect that though.
        throw error;
    }
  };
}
