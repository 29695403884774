import * as actionTypes from 'constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

export default function exportDialogueReducer(state = initialState.exportDialogue, action) {
  switch (action.type) {
    case actionTypes.OPEN_EXPORT_DIALOGUE:
      return objectAssign({}, state, action.newSettings);

    case actionTypes.CLOSE_EXPORT_DIALOGUE:
      return objectAssign({}, state, action.newSettings);

    default:
      return state;
  }
}