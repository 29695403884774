import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import DialogueTitle from 'components/DialogueTitle';
import DialogueButtons from 'components/DialogueButtons';
import Modal from 'react-modal';
import Resources from 'utilities/Resources';
import 'styles/exports.scss';

class InvalidVehicleErrorDialogue extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    vehicleCode: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired
  };

  close = () => {
    this.props.onClose();
  };

  render() {
    const resources = Resources.localizedString;
    return (
      <Modal
      className={"dialogue"}
      contentLabel="Vehicle Code Invalid Modal"
      onRequestClose={this.close}
      onClose={this.close}
      isOpen={this.props.isOpen}
      style={{
        content: {
          width: 950,
          height: 300
        }
      }}>
        <DialogueTitle title={resources.vehicleNotFound} />
        <div className="padded margin-vertical">
          <div className="padded margin-vertical text-center">
            <span className="status-icon status-icon--error margin-bottom"></span>
            <p className="margin-top">{resources.vehicleInvalidError}</p>
            <p>{this.props.vehicleCode}</p>
          </div>
        </div>
        <DialogueButtons
          showCancelButton={false}
          confirmFunction={this.close}
          confirmButtonText={resources.ok} />
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}
export {InvalidVehicleErrorDialogue as InvalidVehicleErrorDialogueNoRedux};

const ConnectedInvalidVehicleErrorDialogue = connect(mapStateToProps, mapDispatchToProps)(InvalidVehicleErrorDialogue);
export default ConnectedInvalidVehicleErrorDialogue;