export const SAVE_FUEL_SAVINGS = 'SAVE_FUEL_SAVINGS';
export const CALCULATE_FUEL_SAVINGS = 'CALCULATE_FUEL_SAVINGS';

export const UPDATE_GRAPH_SETTINGS = 'UPDATE_GRAPH_SETTINGS';

export const ADD_DISTANCE_HAIRLINE = 'ADD_DISTANCE_HAIRLINE';
export const MOVE_DISTANCE_HAIRLINE = 'MOVE_DISTANCE_HAIRLINE';
export const DELETE_DISTANCE_HAIRLINE = 'DELETE_DISTANCE_HAIRLINE';
export const DELETE_ALL_DISTANCE_HAIRLINES = 'DELETE_ALL_DISTANCE_HAIRLINES';
export const DISTANCE_HAIRLINES_SELECTED_RANGE_CHANGED = 'DISTANCE_HAIRLINES_SELECTED_RANGE_CHANGED';

export const UPDATE_GRAPH_SIDEBAR = 'UPDATE_GRAPH_SIDEBAR';
export const FETCH_GRAPH_DATA_POINTS = 'FETCH_GRAPH_DATA_POINTS';

export const FETCH_VEHICLES_DATA_SUCCESS = 'FETCH_VEHICLES_DATA_SUCCESS';
export const FETCH_CHANNELS_DATA_SUCCESS = 'FETCH_CHANNELS_DATA_SUCCESS';
export const FETCH_ENABLED_PRESETS_DATA_SUCCESS = 'FETCH_ENABLED_PRESETS_DATA_SUCCESS';

export const UPDATE_SELECTED_USER_LANGUAGE = 'UPDATE_SELECTED_USER_LANGUAGE';

export const OPEN_CHANNEL_DIALOGUE ='OPEN_DIALOGUE';
export const CLOSE_CHANNEL_DIALOGUE ='CLOSE_DIALOGUE';

export const OPEN_EXPORT_DIALOGUE ='OPEN_EXPORT_DIALOGUE';
export const CLOSE_EXPORT_DIALOGUE ='CLOSE_EXPORT_DIALOGUE';

export const OPEN_SHARE_DIALOGUE ='OPEN_SHARE_DIALOGUE';
export const CLOSE_SHARE_DIALOGUE ='CLOSE_SHARE_DIALOGUE';

export const UPDATE_SIDEBAR_VALUES = 'UPDATE_SIDEBAR_VALUES';
export const REMOVE_SIDEBAR_VALUES = 'REMOVE_SIDEBAR_VALUES';

export const UPDATE_DISTANCES = 'UPDATE_DISTANCES';

export const UPDATE_LATITUDE_LONGITUDES = 'UPDATE_LATITUDE_LONGITUDES';

//trigger
export const UPDATE_TRIGGERS_SAVED_FLAG = 'UPDATE_TRIGGERS_SAVED_FLAG';
export const GET_ALL_TRIGGERS = 'GET_ALL_TRIGGERS';
export const FETCH_TRIGGERS_DATA_SUCCESS = 'FETCH_TRIGGERS_DATA_SUCCESS';
export const UPDATE_PRESET_SAVE_FLAG = 'UPDATE_PRESET_SAVE_FLAG';
export const UPDATE_PRESET_SAVED_FLAG = 'UPDATE_PRESET_SAVED_FLAG';
export const UPDATE_SELECTED_PRESET_ID = 'UPDATE_SELECTED_PRESET_ID';
export const FETCH_PRESETS_DATA_SUCCESS = 'FETCH_PRESETS_DATA_SUCCESS';
export const FETCH_OPERATOR_REFERENCES_SUCCESS = 'FETCH_OPERATOR_REFERENCES';
export const FETCH_EVALUATION_TYPE_SUCCESS = 'FETCH_EVALUATION_TYPE_SUCCESS';

export const UPDATE_VEHICLE_GROUP_SAVED_FLAG = 'UPDATE_VEHICLE_GROUP_SAVED_FLAG';
export const FETCH_VEHICLE_GROUPS_DATA_SUCCESS = 'FETCH_VEHICLE_GROUPS_DATA_SUCCESS';

export const UPDATE_SEARCH_POINTS = 'UPDATE_SEARCH_POINTS';

export const UPDATE_USER = 'UPDATE_USER';
