import * as types from 'constants/actionTypes';

export function openChannelsDialogue() {
  return function (dispatch) {
    return dispatch({
      type: types.OPEN_CHANNEL_DIALOGUE,
      newSettings: {show: true}
    });
  };
}

export function closeChannelsDialogue() {
  return function (dispatch) {
    return dispatch({
      type: types.CLOSE_CHANNEL_DIALOGUE,
      newSettings: {show: false}
    });
  };
}

export function openExportDialogue() {
  return function (dispatch) {
    return dispatch({
      type: types.OPEN_EXPORT_DIALOGUE,
      newSettings: {show: true}
    });
  };
}

export function closeExportDialogue() {
  return function (dispatch) {
    return dispatch({
      type: types.CLOSE_EXPORT_DIALOGUE,
      newSettings: {show: false}
    });
  };
}

export function openShareDialogue() {
  return function (dispatch) {
    return dispatch({
      type: types.OPEN_SHARE_DIALOGUE,
      newSettings: {show: true}
    });
  };
}

export function closeShareDialogue() {
  return function (dispatch) {
    return dispatch({
      type: types.CLOSE_SHARE_DIALOGUE,
      newSettings: {show: false}
    });
  };
}