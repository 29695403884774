import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ReactTable from 'react-table'
import * as triggerActions from "actions/triggerActions";
import routes from 'routes';
import Resources from 'utilities/Resources';
import {Link} from 'react-router-dom';
import moment from 'moment';
import 'react-table/react-table.css';
import DeleteDialogue from './DeleteDialogue';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/_toast-notifications.scss';
import triggersApi from 'api/triggersApi';
import string from 'utilities/stringUtilities';

class TriggerGrid extends React.Component {
  static propTypes = {
    triggerActions: PropTypes.object.isRequired,
    triggers: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showDeleteDialogue: false,
      toBeDeletedId: null
    };

    this.editTriggerUrl = routes.admin.triggers.addEditTrigger.replace('/:triggerId?', '/');
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.resources = Resources.localizedString;
  }

  componentDidMount() {
    this.props.triggerActions.getAllTriggerInformation();

    // DC: Probably a really hacky way of focusing on the Name filter (the first <input /> in the component) but it does the job.
    const input = document.querySelector("input");
    input.focus();
  }

  componentDidUpdate(prevProps) {
    if (this.props.triggers !== prevProps.triggers) {
      this.setState({
        loading: false
      });
    }
  }

  dateCell = (cellInfo) => {
    return (
      <div>
        {moment.utc(this.props.triggers[cellInfo.index][cellInfo.column.id]).local().format(this.resources.fullDateTimeFormat)}
      </div>
    );
  }

  getIsEnabledChangeSuccessMessageFormat = isEnabled => isEnabled ? this.resources.entityNameSuccessfullyEnabledFormat : this.resources.entityNameSuccessfullyDisabledFormat;
  getIsEnabledChangeErrorMessageFormat = isEnabled => isEnabled ? this.resources.errorEnablingEntityNameFormat : this.resources.errorDisablingEntityNameFormat;

	async onChangeIsEnabled(trigger) {
    let api = {};
    const newIsEnabledSetting = !trigger.isEnabled;

    try {
      api = await triggersApi.setIsEnabled(trigger.triggerId, newIsEnabledSetting);

      const messageFormat = this.getIsEnabledChangeSuccessMessageFormat(newIsEnabledSetting);
      const message = string.format(`${messageFormat}.`, trigger.name);
      toast(message, {type: toast.TYPE.SUCCESS});

      this.props.triggerActions.getAllTriggerInformation();
    }
    catch (error) {
      const messageFormat = this.getIsEnabledChangeErrorMessageFormat(newIsEnabledSetting);
      const message = string.format(`${messageFormat}.`, trigger.name);
      toast(message, {type: toast.TYPE.ERROR});

      api.error = error;
    }
	}

  formAddEditLink = (triggerId) => {
    return this.editTriggerUrl + triggerId;
  }

  optionsCell = (cellInfo) => {
    const trigger = this.props.triggers[cellInfo.index];
    const triggerId = trigger.triggerId;
    const createdBy = trigger.createdBy;
    const isSystemTrigger = createdBy == 'RDD Migration Script';
    if (isSystemTrigger) {
      return (<span id="labelInformation" className="message message--notice message--notice--with-icon inline-block">System Trigger</span>);
    }
    else {
      return (
        <div>
          <Link to={this.formAddEditLink(triggerId)} className="icon-button icon-button--view"></Link>
          <button className="icon-button icon-button--delete" onClick={(event) => {this.delete(event, trigger)}}>{this.resources.delete}</button>
        </div>
      );
    }
  }

  onDeleteCancel = () => {
    this.setState({
      showDeleteDialogue: false
    })
  }

  delete = (e, trigger) => {
    this.setState({
      showDeleteDialogue: true,
      toBeDeletedId: trigger.triggerId
    });
  }

  onDeleteConfirm  = async function () {
    let api = {};
    try {
      api = await triggersApi.delete(this.state.toBeDeletedId);
    }
    catch (error) {
      // Set error to be used to show notification of success/failure
      api.error = error;
    }

    this.setState({
      showDeleteDialogue: false,
      toBeDeleted: null,
      deleteApiResult: api
    }, () => {
      if (!this.state.deleteApiResult.error) {
        this.setState({
          loading: true
        });
        this.props.triggerActions.getAllTriggerInformation();
      }
      this.showDeleteSavedNotification();
    });
  }

  showDeleteSavedNotification = () => {
    if (!this.state.deleteApiResult.error) {
      toast(this.resources.triggerSuccessfullyDeleted, {
        type: toast.TYPE.SUCCESS,
        onClose: this.setState({deleteApiResult: null})
      });
    } else {
      toast(this.resources.triggerFailedToDelete, {
        type: toast.TYPE.ERROR,
        onClose: this.setState({deleteApiResult: null})
      });
    }
  }

  render() {
    return (
      <React.Fragment>
      <DeleteDialogue
        showDialogue={this.state.showDeleteDialogue}
        dialogueTitle={this.resources.deleteTrigger}
        dialogueText={this.resources.confirmTriggerDeletion}
        confirmButtonText={this.resources.yesDeleteTrigger}
        cancelButtonText={this.resources.noKeepTrigger}
        onCancel={this.onDeleteCancel}
        onConfirm={this.onDeleteConfirm}
      />
      {/* Documentation: https://react-table.tanstack.com/docs/api/overview */}
      <ReactTable
        // defaultPageSize={10}
        // Let's just show all the records. There's not that much information and I always end up selecting pageSize 100, leading to it displaying about 75 empty rows!
        pageSize={this.props.triggers.length}
        showPagination={false}
        loading={this.state.loading}
        data={this.props.triggers}
        defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().includes(filter.value.toLowerCase())}
        columns={[
          {
            Header: this.resources.name,
            accessor: 'name', // String-based value accessors!
            filterable: true
          },
          {
            Header: this.resources.numVehiclesAssigned,
            accessor: 'vehicleCount' // String-based value accessors!
          },
          {
            Header: this.resources.lastModified,
            accessor: 'dateModified', // String-based value accessors!
            Cell: this.dateCell
          },
          {
            Header: this.resources.enabled,
            id: 'isEnabled',
						accessor: trigger => (
              <input type="checkbox" className="checkbox" checked={trigger.isEnabled === true} onChange={() => this.onChangeIsEnabled(trigger)} />
            ),
          },
          {
            Header: this.resources.edit,
            id: 'EditTrigger',
            accessor: 'triggerId',  // Custom cell components!
            Cell: this.optionsCell
          }]} />
          </React.Fragment>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      triggers: state.triggers.triggers
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      triggerActions: bindActionCreators(triggerActions, dispatch)
    };
  }

  export {TriggerGrid as TriggerGridNoRedux};

  const ConnectedTriggerGrid = connect(mapStateToProps, mapDispatchToProps)(TriggerGrid);
  export default ConnectedTriggerGrid;
