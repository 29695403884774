import axios from 'axios';
import rddApiConfig from './rddApiConfig';
// "ReferenceError: regeneratorRuntime is not defined" when used async/await: https://github.com/babel/babel/issues/5085
// in the form "async => await () => ..."
// This page suggests it can be done without including the polyfill: https://www.valentinog.com/blog/how-async-await-in-react/
import '@babel/polyfill';

const triggersApi = {}

// https://medium.com/codingthesmartway-com-blog/getting-started-with-axios-166cb0035237
// https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index

// triggersApi.postTriggersGrid = async () => {
//     const response = await axios.put('trigger/getTriggersGrid', requestBody ,rddApiConfig);
//     return response.data;
//   }

triggersApi.getAll = async () => {
  const response = await axios.get('trigger', rddApiConfig);
  return response.data;
}

triggersApi.getById = async (triggerId) => {
  const response = await axios.get('trigger/' + triggerId, rddApiConfig);
  return response.data;
}

triggersApi.getAllTriggerInformation = async () => {
  const response = await axios.get('trigger/trigger-information', rddApiConfig);
  return response.data;
}

triggersApi.getEvaluationTypeReferences = async () => {
  const response = await axios.get('trigger/evaluation-type-reference', rddApiConfig);
  return response.data;
}

triggersApi.getOperatorReferences = async () => {
  const response = await axios.get('trigger/operator-reference', rddApiConfig);
  return response.data;
}

triggersApi.addEditTrigger = async (trigger) => {
  const response = await axios.put('trigger/add-edit-trigger', trigger, rddApiConfig)
  return response;
}

triggersApi.delete = async (triggerId) => {
  const response = await axios.delete(`trigger/${triggerId}`, rddApiConfig)
  return response;
}

triggersApi.setIsEnabled = async (triggerId, isEnabled) => {
  const response = await axios.put('trigger/set-is-enabled', {triggerId, isEnabled}, rddApiConfig)
  return response;
}

export default triggersApi;
