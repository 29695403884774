import React from 'react';
import Resources from 'utilities/Resources';
import 'styles/_no-data-panel.scss';

const NoDataPanel = () => {
  const resources = Resources.localizedString;
  return (
    <div className="no-data-panel">
      <span className="no-data-panel__text">
        <span className="block">{resources.noDataForThisPeriodOfTime}</span>
        <span className="block">{resources.pleaseAdjustTimeRangeTryAgain}</span>
      </span>
    </div>
  );
};

export default NoDataPanel;