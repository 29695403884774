import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Resources from 'utilities/Resources';
import DropDown from 'components/DropDown';
import {getDygraphsArrayWithDigitalGraphsFirst} from 'utilities/dygraphsFunctions';
import {copy, searchComparisonValues} from 'utilities/graphSettings';
import * as searchActions from 'actions/advancedSearchActions';
import * as searchComparisons from 'constants/searchComparisons';
import moment from 'moment';
import * as R from 'ramda';
import * as channelTypes from 'constants/channelTypes';
import 'styles/advanced-search.scss';

class AdvancedSearch extends React.Component {
  static propTypes = {
    graphs: PropTypes.array.isRequired,
    scrollContainerId: PropTypes.string.isRequired,
    channels: PropTypes.array.isRequired,
    searchPoints: PropTypes.array.isRequired,
    searchActions: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.defaultText = Resources.localizedString.pleaseSelect;
    this.channelInput = React.createRef();

    this.selectedChannels = [...R.pluck('channels', this.props.graphs)].flat();
    this.selectedChanelIds = [...R.pluck('channelId', this.selectedChannels)];
    this.channels = R.innerJoin(
      (channel, channelId) => channel.channelId === channelId,
      this.props.channels,
      this.selectedChanelIds
    );

    this.state = {
      channels: R.sortBy(R.compose(R.toLower, R.prop('name')))(this.channels),
      comparisons: copy(searchComparisonValues),
      filteredChannels: [],
      open: false,
      channelValid: true,
      valueValid: true,
      valueErrorMessage: '',
      value: '',
      comparisonValid: true,
      comparison: null,
      selectedChannel: null,
      fixedPosition: true,
      results: [],
      searchPoints: this.props.searchPoints,
      requested: false
    };

    this.scrollElementId = "advanced-search";
    this.submit = this.submit.bind(this);
    this.initialState = copy(this.state);
    this.resultRowsToShow = 7;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.graphs) != JSON.stringify(prevProps.graphs) ||
        JSON.stringify(this.props.channels) != JSON.stringify(prevProps.channels)) {
      const selectedChannels = [...R.pluck('channels', this.props.graphs)].flat();
      const selectedChanelIds = [...R.pluck('channelId', selectedChannels)];
      const channels = R.innerJoin(
        (channel, channelId) => channel.channelId === channelId,
        this.props.channels,
        selectedChanelIds
      );
      
      let selectedChannel = this.state.selectedChannel;
      let comparison = this.state.comparison;
      let comparisons = this.state.comparisons;
      // check that the selected channel is in the updated channel array.
      // If not remove selected and set to null, and set the comparison options if selected was digital
      if (!R.includes(selectedChannel, channels)) {
        if (selectedChannel && selectedChannel.channelType === channelTypes.DIGITAL_CHANNEL) {
          comparison = null;
          comparisons = copy(searchComparisonValues);
        }
        selectedChannel = null;
      }

      let points = copy(this.state.searchPoints);
      // Need to check if point channel is is updated lista nd remove point if not in
      for (let i = 0; i < points.length; i++) {
        if (channels.filter(c => c.channelId === points[i].channelId).length === 0) {
          //  remove the search point
          const pointIndex = R.indexOf(points[i], points);
          points.splice(pointIndex, 1);
        }
      }

      this.setState({
        channels: R.sortBy(R.compose(R.toLower, R.prop('name')))(channels),
        selectedChannel,
        comparison,
        comparisons,
        searchPoints: points
      }, () => {
        this.props.searchActions.updateSearchPoints(points);
        this.initialState.channels = this.state.channels;
      });
    }

    if (JSON.stringify(this.props.searchPoints) != JSON.stringify(prevProps.searchPoints)) {
      this.setState({
        searchPoints: [...this.props.searchPoints]
      });
    }
  }

  setInitialState = () => {
    this.initialState = copy(this.state);
  }

  toggleOpen = () => {
    this.setState({
      open: !this.state.open
    });
  }

  valueInputChange = (e) => {
    if (e.target.value) {
      this.setState({
        value: e.target.value
      });
    } else {
      this.setState({
        value: '',
      });
    }
  }
  
  listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    //get position of advanced search element bottom
    const element = document.getElementById(this.props.scrollContainerId);
    const rect = element.getBoundingClientRect();
    const elementScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const offset = rect.bottom + elementScrollTop;

    if(winScroll + document.documentElement.clientHeight <= offset) {
      this.setState({
        fixedPosition: true
      });
    } else {
      this.setState({
        fixedPosition: false
      });
    }
  }

  validate = () => {
    let valid = true;
    let valueValid = true;
    let stateCopy = {...this.state};
    const numberRegex = /\d+/;
    const digitalRegex = /[0-1]{1}/;
    const value = parseInt(this.state.value.trim());

    if (!this.state.selectedChannel) {
      stateCopy.channelValid = false;
      valid = false;
    } else {
      valid = true;
    }

    if (!numberRegex.test(value)) {
      valueValid = false;
      stateCopy.valueErrorMessage = Resources.localizedString.pleaseEnterValue;
      valid = false;
    }

    if (this.state.selectedChannel && this.state.selectedChannel.channelType == channelTypes.DIGITAL_CHANNEL) {
      if (!digitalRegex.test(value)) {
        valueValid = false;
        stateCopy.valueErrorMessage = Resources.localizedString.pleaseEnterValueOffOrOn;
        valid = false;
      }
    }

    if (!this.state.comparison) {
      stateCopy.comparisonValid = false;
      valid = false;
    }

    stateCopy.valueValid = valueValid;
    
    this.setState(stateCopy);

    return valid;
  }

  submit = async function () {
    if (this.validate()) {

      // go get the values from the data points on the graph
      const dygraphs = getDygraphsArrayWithDigitalGraphsFirst();
      for (let i = 0; i < dygraphs.length; i++) {
        const channels = dygraphs[i].channels;
        // check to see which dygraph the search point channel is in 
        if (channels.filter(c => c.channelId === this.state.selectedChannel.channelId).length > 0) {
          const channel = channels.filter(c => c.channelId === this.state.selectedChannel.channelId)[0];
          const channelIndex = R.indexOf(channel, channels);
          //gets the column index in the data row for the given channel name
          const columnIndex = dygraphs[i].indexFromSetName(this.state.selectedChannel.shortName);
          let dataPoints = [];
          const parsedValue = parseFloat(this.state.value).toFixed(2);

          if (dygraphs[i].rawData_) {
            for (let dataIndex = 0; dataIndex < dygraphs[i].rawData_.length; dataIndex++) {
              const channelDataPoint = dygraphs[i].rawData_[dataIndex][columnIndex];
              if (channelDataPoint) {
                // Logic to change the check depending on what they search for ???
                let value = null;

                if (this.state.comparison.value === searchComparisons.GREATER_THAN) {
                  if (channelDataPoint[2] > parsedValue) {
                    value = channelDataPoint[2];
                  }
                } else if (this.state.comparison.value === searchComparisons.EQUAL_TO) {
                  if (this.state.selectedChannel.channelType == channelTypes.DIGITAL_CHANNEL) {
                    if (parseFloat(Math.round(channelDataPoint[2] - (channels.length - channelIndex - 1))).toFixed(2) === parsedValue) {
                      value = Math.round(channelDataPoint[2] - (channels.length - channelIndex - 1));
                    }
                    if (parseFloat(Math.round(channelDataPoint[0] - (channels.length - channelIndex - 1))).toFixed(2) === parsedValue) {
                      value = Math.round(channelDataPoint[0] - (channels.length - channelIndex - 1));
                    }
                  } else {
                    if (parseFloat(channelDataPoint[2]).toFixed(2) === parsedValue) {
                      value = channelDataPoint[2];
                    }
                  }
                } else if (this.state.comparison.value === searchComparisons.LESS_THAN) {
                  if (channelDataPoint[2] < parsedValue) {
                    value = channelDataPoint[2];
                  }
                }

                if (value !== null) {
                  const roundedValue = parseFloat(value).toFixed(2);
                  dataPoints.push({rmdDateTime: dygraphs[i].rawData_[dataIndex][0], value: roundedValue});
                }
              }
            }
          }

          this.setState({
            requested: true,
            results: dataPoints
          });
        }
      }
    }
  };

  reset = () => {
    this.props.searchActions.removeSearchPoints();
    this.initialState.requested = false;
    this.initialState.value = '';
    this.initialState.open = true;
    this.initialState.comparison = null;
    this.initialState.selectedChannel = null;
    this.setState(this.initialState);
  };

  addValueHairline = (event, resultDateTime, index) => {
    const newPoint = {
      xval: resultDateTime,
      channelId: this.state.selectedChannel.channelId
    };

    // Don't add the search point if already in the array of search points
    if (!R.includes(newPoint, this.state.searchPoints)) {
      const allPoints = [...this.state.searchPoints, newPoint];
      this.props.searchActions.updateSearchPoints(allPoints);
    }
  }

  onComparisonChange = (comparison) => {
    if (comparison) {
      this.setState({
        comparison,
        comparisonValid: true
      });
    } else {
      this.setState({
        comparison: null,
        comparisonValid: false
      });
    }
  }

  onChannelChange = (channel) => {
    if (channel) {
      let value = this.state.value;
      let comparison = this.state.comparison;
      let comparisons = copy(searchComparisonValues);
      // Set selected comparison to EqualTo, and comparison options to only include EqualTo if digital channel
      if (channel.channelType == channelTypes.DIGITAL_CHANNEL) {
        comparison = searchComparisonValues.filter(c => c.value == searchComparisons.EQUAL_TO)[0];
        comparisons = searchComparisonValues.filter(c => c.value == searchComparisons.EQUAL_TO);
        value = '1';
      }
      this.setState({
        selectedChannel: channel,
        channelValid: true,
        comparison,
        comparisons,
        value
      });
    } else {
      this.setState({
        selectedChannel: null,
        channelValid: false
      });
    }
  }

  render() {
    const resources = Resources.localizedString;
    let cssClass = "advanced-search-wrapper";

    if (this.state.fixedPosition) {
      cssClass += " fixed";
    }

    let paddingRows = [];
    // requested results and work out if less than rows shown in the results table
    if ((this.state.results.length < this.resultRowsToShow) && this.state.requested) {
      for (let i = this.state.results.length; i < this.state.results.length + (this.resultRowsToShow - this.state.results.length); i++) {
          paddingRows.push(<div key={i} className="advanced-search__results-row"></div>);
      }
    }

    return(
          <div className={cssClass}>
            {this.state.open &&
              <div className="cover" onClick={ this.toggleOpen }/> 
            }
            <span onClick={this.toggleOpen} className={this.state.open ? "advanced-search-handle open" : "advanced-search-handle"}>Advanced Search</span>
            {this.state.open &&
              <div className="advanced-search overflow-hidden">
                <span className="block">{resources.searchForInstancesWhere}</span>
                <div className="preset-select-wrapper margin-bottom padded-vertical">
                  <DropDown inputName="channel-search" selected={this.state.selectedChannel} labelText={resources.channel} options={this.state.channels} valid={this.state.channelValid} onChange={this.onChannelChange} />
                </div>
                <div className="padded-vertical margin-bottom">
                  <DropDown inputName="comparison" centerText={true} small={true} selected={this.state.comparison} labelText={resources.comparison} options={this.state.comparisons} valid={this.state.comparisonValid} onChange={this.onComparisonChange} />
                </div>
                <div className="padded-vertical margin-bottom overflow-hidden">
                  <label htmlFor="value">{resources.value}</label>
                  <input type="number" name="value" min="0" id="value" className={!this.state.valueValid ? "margin-right numerical-input left error" : "margin-right left numerical-input"} value={this.state.value} onChange={this.valueInputChange} />
                  {!this.state.valueValid &&
                  <span className="message message--error block advanced-search__value-error">{this.state.valueErrorMessage}</span>
                  }
                </div>
                <div className="padded-bottom margin-bottom">
                  <button onClick={this.reset} className="button button--secondary right">Reset</button>
                  <button onClick={this.submit} className="button">{resources.search}</button>
                </div>
                {this.state.requested &&
                  <React.Fragment>
                    <div className="margin-bottom">
                      {/* potential to add prev and next arrows here */}
                      <div className="">
                        <div className="advanced-search__results-header-container">
                          <span className="advanced-search__results-header advanced-search__results-column--start-time">{resources.startTime}</span>
                          <span className="advanced-search__results-header advanced-search__results-column--value">{resources.value}</span>
                          <span className="advanced-search__results-header advanced-search__results-column--view-on-graph">{resources.viewOnGraph}</span>
                        </div>
                        <div className="advanced-search__results-container relative">
                          {/* loop through the results in here */}
                            {this.state.results.map((result, i) => {
                              return (<div className="advanced-search__results-row advanced-search__results-row--selectable" key={i} onClick={(event) => {this.addValueHairline(event, result.rmdDateTime, i)}}><span className="advanced-search__results-column advanced-search__results-column--start-time">{moment(result.rmdDateTime).format(resources.fullDateTimeFormat).toString()}</span>
                              <span className="advanced-search__results-column advanced-search__results-column--value">{result.value}</span>
                              <span className="advanced-search__results-column advanced-search__results-column--view-on-graph">{resources.view}</span></div>);
                            })
                            }
                            {paddingRows}
                          {/* Error message and loading panel in here */}
                          {this.state.loading && 
                            <div className="graph-loading"></div>
                          }
                        </div>
                      </div>
                    </div>
                    <span className="right">{this.state.results.length + ' ' + resources.resultsFound}</span>
                  </React.Fragment>
                }
              </div>
            }
          </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    searchPoints: [...state.graphSettings.advancedSearchPoints],
    channels: copy(state.referenceData.channels),
    graphs: [...copy(state.graphSettings.analogueGraphs), ...copy(state.graphSettings.digitalGraphs)]
  };
}

function mapDispatchToProps(dispatch) {
  return {
    searchActions: bindActionCreators(searchActions, dispatch)
  };
}

export {AdvancedSearch as AdvancedSearchNoRedux};

const ConnectedAdvancedSearch = connect(mapStateToProps, mapDispatchToProps)(AdvancedSearch);
export default ConnectedAdvancedSearch;