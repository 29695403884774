import * as React from 'react'
import {PropTypes} from 'prop-types';
import { SwatchesPicker } from 'react-color';
import objectAssign from 'object-assign';
import {getChannelColour} from 'utilities/graphSettings';
import { DragSource, DropTarget } from 'react-dnd';
import * as dragDropTypes from 'constants/dragDropTypes';

const channelSource = {
  beginDrag(props) {
    props.channel.graphId = props.graphId;
    props.channel.index = props.index;
    return props.channel;
  },
};

const channelTarget = {
  hover(props, monitor, component) {
    if (!component) {
      return null
    }
    // Don't try and re=order if from another graph, as the index it has will be incorrect for current graph channels
    if (props.graphId == monitor.getItem().graphId) {
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const element = document.getElementById("selected-channel-" + monitor.getItem().channelId);
      const hoverBoundingRect = element.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      props.moveChannel(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
    }
  },
};

function sourceCollect(connect) {
  return {
    connectDragSource: connect.dragSource()
  };
}

function targetCollect(connect) {
  return {
    connectDropTarget: connect.dropTarget()
  };
}

class SelectedGraphChannel extends React.Component {
  static propTypes = {
    onChannelColourChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    channel: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    moveChannel: PropTypes.func.isRequired,
    graphId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    // Injected by React DnD:
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
  };
  
  constructor(props, context) {
    super(props, context);
    this.state = {
      displayColorPicker: false,
      displayChannelMenu: false,
      channel: this.props.channel,
      index: this.props.index
    };
    this.myRef = React.createRef();
  }
      
  colorHandleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  colorHandleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  channelMenuClick = () => {
    this.setState({ displayChannelMenu: !this.state.displayChannelMenu })
  };

  channelMenuClose = () => {
    this.setState({ displayChannelMenu: false })
  };

  componentDidUpdate(prevProps) {
    if (this.props.channel !== prevProps.channel) {
      this.setState({
        channel: this.props.channel
      });
    }
  }

  remove = () => {
    let channel = objectAssign({}, this.state.channel);
    channel.graphId = this.props.graphId;
    this.props.onRemove(channel);
    this.channelMenuClose();
  }
  
  onColourSelect = (color) => {
    let updatedChannel = objectAssign({}, this.state.channel);
    updatedChannel.color = color.hex;
    this.setState({
      channel: updatedChannel,
      displayColorPicker: false
    }, this.props.onChannelColourChange(updatedChannel, this.props.graphId));
  }

  resetColour = () => {
    let updatedChannel = objectAssign({}, this.state.channel);
    updatedChannel.color = '';
    this.setState({
      channel: updatedChannel,
      displayChannelMenu: false
    }, this.props.onChannelColourChange(updatedChannel, this.props.graphId));
  }
  
  render() {
      const connectDragSource = this.props.connectDragSource;
      const connectDropTarget = this.props.connectDropTarget;
      return connectDragSource(connectDropTarget(
      <div id={"selected-channel-" + this.props.channel.channelId} className="selected-channels-drop__channel">
        <div className="selected-channels-drop__channel-name-wrapper">
          <span className="selected-channels-drop__channel-name">{this.state.channel.name}</span>
        </div>
        <span className="selected-channels-drop__channel-color-pick" onClick={this.colorHandleClick}>
          <span className="selected-channels-drop__channel-color" style={{backgroundColor: getChannelColour(this.state.channel)}}></span>
          <span className="selected-channels-drop__channel-color-arrow"></span>
        </span>
        <span onClick={this.channelMenuClick} className="selected-channels-drop__channel-menu-icon"></span>
        {
          this.state.displayChannelMenu ?
            <div>
            <div className="cover" onClick={ this.channelMenuClose }/>
            <div className="selected-channels-drop__channel-menu">
            {
              this.state.channel.color && this.state.channel.color.length> 0 ?
                <button className="selected-channels-drop__channel-menu-item" onClick={this.resetColour}>Reset colour</button>
              :
                null
              } 
            <button className="selected-channels-drop__channel-menu-item" onClick={this.remove}>Remove Channel</button>
            </div></div>
          :
          null
        }
        { this.state.displayColorPicker ? <div className="color-picker-popout">
          <div className="cover" onClick={ this.colorHandleClose }/>
          <SwatchesPicker width="235" onChangeComplete={this.onColourSelect} />
        </div> : null }
      </div>
    ));
  }
}

export default DropTarget(dragDropTypes.SELECTED_CHANNEL, channelTarget, targetCollect)(DragSource(dragDropTypes.SELECTED_CHANNEL, channelSource, sourceCollect)(SelectedGraphChannel));