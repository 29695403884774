import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {getChannelColour} from 'utilities/graphSettings';
import * as channelValueTypes from 'constants/channelValueTypes';
import {metresInMile} from 'utilities/distanceUtilities';
import WheelDiameter from 'components/WheelDiameter';
import Resources from 'utilities/Resources';
import * as R from 'ramda';

const getLabel = channel => {
  return channel.unitOfMeasure
          ? `${channel.name} (${channel.unitOfMeasure})`
          : channel.name;
};

class GraphSummary extends React.Component {
  static propTypes = {
    graphSettings: PropTypes.object.isRequired,
    sidebarValues: PropTypes.object.isRequired
  };

  showChannelData = (channel, index) => {
    return (
      <div key={index} className="graph-summary__item">
        <span className="graph-summary__item-label">{getLabel(channel)}</span>
        {/* <span className="graph-summary__item-value">{channel.hasOwnProperty('value') ? channel.value : channelValueTypes.EMPTY_CHANNEL_VALUE}</span> */}
        <span className="graph-summary__item-value"
              dangerouslySetInnerHTML={{__html: channel.hasOwnProperty('value') ? channel.value : channelValueTypes.EMPTY_CHANNEL_VALUE}}></span>
        <span className="graph-summary__item-channel-colour-indicator" style={{backgroundColor: getChannelColour(channel)}}></span>
      </div>
    )
  };

  render() {
    const resources = Resources.localizedString;
    const distanceDisplayString =
      this.props.sidebarValues.distance && !R.isEmpty(this.props.sidebarValues.distance) ?
      this.props.sidebarValues.distance.toLocaleString(undefined, {maximumFractionDigits: 2}) :
      channelValueTypes.EMPTY_CHANNEL_VALUE;
    const distanceMilesDisplayString =
      this.props.sidebarValues.distance && !R.isEmpty(this.props.sidebarValues.distance) ?
      (this.props.sidebarValues.distance / metresInMile).toLocaleString(undefined, {maximumFractionDigits: 2}) :
      channelValueTypes.EMPTY_CHANNEL_VALUE;

    return (
      <div className="graph-summary">
        <div className="graph-summary__title">{resources.summary}</div>
          <div className="graph-summary__item graph-summary__item--timestamp clearfix">
            <span className="graph-summary__item-label">{resources.logStart}</span>
            <span className="graph-summary__item-value">{this.props.graphSettings.rangeStart.format("ddd, DD MMM YYYY").toString()}</span>
            <span className="graph-summary__item-value">{this.props.graphSettings.rangeStart.format("HH:mm:ss").toString()}</span>
          </div>
          <div className="graph-summary__item graph-summary__item--timestamp clearfix">
            <span className="graph-summary__item-label">{resources.logEnd}</span>
            <span className="graph-summary__item-value">{this.props.graphSettings.rangeEnd.format("ddd, DD MMM YYYY").toString()}</span>
            <span className="graph-summary__item-value">{this.props.graphSettings.rangeEnd.format("HH:mm:ss").toString()}</span>
          </div>
          <div className="graph-summary__item graph-summary__item--timestamp clearfix">
            <span className="graph-summary__item-label">{resources.current}</span>
            <span className="graph-summary__item-value">{this.props.sidebarValues.currentTimestamp ? this.props.sidebarValues.currentTimestamp.format("ddd, DD MMM YYYY").toString() : channelValueTypes.EMPTY_CHANNEL_VALUE}</span>
            <span className="graph-summary__item-value">{this.props.sidebarValues.currentTimestamp ? this.props.sidebarValues.currentTimestamp.format("HH:mm:ss.SSS").toString() : channelValueTypes.EMPTY_CHANNEL_VALUE}</span>
          </div>
          <div className="graph-summary__item graph-summary__item--timestamp clearfix">
            <span className="graph-summary__item-label">{resources.distance}&nbsp;(m)</span>
            <span className="graph-summary__item-value">{distanceDisplayString}</span>
            <span className="graph-summary__item-label">{resources.distance}&nbsp;(miles)</span>
            <span className="graph-summary__item-value">{distanceMilesDisplayString}</span>
          </div>

          <div className="graph-summary__item clearfix"></div>
          <WheelDiameter />
          <div className="graph-summary__item clearfix"></div>

          {this.props.sidebarValues.channelValues.map(this.showChannelData)}
      </div>
    );
  }
}

// function mapStateToProps(state, ownProps) {
function mapStateToProps(state) {
  return {
    // DC: This give the component the "this.props.graphSettings" property.
    graphSettings: state.graphSettings,
    sidebarValues: state.sidebarValues
  };
}

// These names used in redux docs but could use different ones.
export default connect(mapStateToProps)(GraphSummary);
