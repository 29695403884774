import React from 'react';
import squaredLogo from 'images/3squared_logo.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="padded-horizontal">
        <div className="padded-horizontal">
          <div className="footer__meta-section">
            <a href="https://3squared.com" className="logo-link logo" target="_blank"               rel="noopener noreferrer">
              <img runat="server" src={squaredLogo} alt="3Squared - Software Development Specialists" height="14" width="91" />
            </a>
          </div>
          <div className="footer__meta-section">
            <span className="half-margin-right">Version</span>
            <span>{process.env.PUBLIC_VERSION}</span>
          </div>

          {/* <span className="footer__meta-section footer__meta-section--right">
            <a href="">Link 3</a>
          </span>
          <span className="footer__meta-section footer__meta-section--right">
            <a href="">Link 2</a>
          </span>
          <span className="footer__meta-section footer__meta-section--right">
            <a href="">Link 1</a>
          </span> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
