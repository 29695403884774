import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';
import queryString from 'query-string'
import * as graphSettingsActions from 'actions/graphSettingsActions';
import * as journeysActions from 'actions/journeysActions';
import * as eventsActions from 'actions/eventsActions';
import * as sharingActions from 'actions/sharingActions';
import * as triggerProblemStateActions from 'actions/triggerProblemStateActions'
import GraphPage from 'components/GraphPage';
import PageLayout from 'components/PageLayout';
import {copy} from 'utilities/graphSettings';
import objectUtilities from 'utilities/objectUtilities';

class Main extends React.Component {
  static propTypes = {
    // DC: This is related to react router.
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    journeysActions: PropTypes.object.isRequired,
    sharingActions: PropTypes.object.isRequired,
    eventsActions: PropTypes.object.isRequired,
    graphSettingsActions: PropTypes.object.isRequired,
    triggerProblemStateActions: PropTypes.object.isRequired,
    analogueGraphs: PropTypes.array,
    digitalGraphs: PropTypes.array
  };

  constructor(props, context) {
    super(props, context);

    if (this.props.match.params.vehicleCode) {
      this.props.graphSettingsActions.updateSelectedVehicle(this.props.match.params.vehicleCode);
    }

    if (this.props.location.search) {
      const qs = queryString.parse(this.props.location.search);
      const values = objectUtilities.propertiesToLower(qs);

      if (values.shareid) {
        this.props.sharingActions.getById(values.shareid);
      }
      if (values.journeyid) {
        this.props.journeysActions.getById(values.journeyid);
      }
      if (values.eventid) {
        this.props.eventsActions.getById(values.eventid);
      }
      if (values.triggerproblemstateid) {
        this.props.triggerProblemStateActions.getById(values.triggerproblemstateid, copy(this.props.analogueGraphs), copy(this.props.digitalGraphs));
      }
    }
  }

  render() {
    return (
      <GraphPage />
    );
  }
}

function mapStateToProps(state) {
  return {
    analogueGraphs: state.graphSettings.analogueGraphs,
    digitalGraphs: state.graphSettings.digitalGraphs
  };
}

function mapDispatchToProps(dispatch) {
  return {
    graphSettingsActions: bindActionCreators(graphSettingsActions, dispatch),
    journeysActions: bindActionCreators(journeysActions, dispatch),
    eventsActions: bindActionCreators(eventsActions, dispatch),
    triggerProblemStateActions: bindActionCreators(triggerProblemStateActions, dispatch),
    sharingActions: bindActionCreators(sharingActions, dispatch),
  };
}

export {Main as MainNoRedux};

export default PageLayout(connect(mapStateToProps, mapDispatchToProps)(Main));
