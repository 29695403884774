import ReduxState from 'index';
import * as R from 'ramda';

export const getDistanceBetweenTwoPoints = (endDistanceTime, startDistanceTime) => {
  const distanceStart = getDistanceFromClosestPoints(startDistanceTime);
  const distanceEnd = getDistanceFromClosestPoints(endDistanceTime);
  return distanceEnd - distanceStart;
}

const getDistanceFromClosestPoints = (time) => {
  let distances = ReduxState().distances;
  let splitDistances = R.splitWhen(x => x.rmdDateTime >= time, [...distances]);
  let previousDistance = splitDistances[0][splitDistances[0].length - 1];
  var followingDistance = splitDistances[1][0];

  if (!previousDistance) {
    // Hairline is at the very beginning of the graph
    previousDistance = {rmdDateTime: time, distance: 0};
  }

  if (!followingDistance) {
    // Hairline is at the very end of the graph and has not following data point.
    // This should just use the last data point in the current array of distance points
    const lastDistancePointIndex = R.findLastIndex(d => d.rmdDateTime <= time)(distances);
    const lastDistancePoint = distances[lastDistancePointIndex];
    return lastDistancePoint ? lastDistancePoint.distance : 0;
  }

  return interpolateDistance(previousDistance, followingDistance, time);
}

const interpolateDistance = (p1, p2, t) => {
  // There is a possibility the p1 and p2 have the same time. In that case we want to avoid a division by zero, causing us to see NaN distances.
  const p2p1TimeDifference = p2.rmdDateTime - p1.rmdDateTime;
  if (p2p1TimeDifference == 0) {
    return p1.distance;
  }

  const p2p1DistanceDifference = p2.distance - p1.distance;
  return p1.distance + p2p1DistanceDifference * (t - p1.rmdDateTime) / p2p1TimeDifference;
}

export const metresInMile = 1609.344;

export function getDistanceSidebarValue(pointTimestamp) {
  // get redux store value
  let distances = Object.assign([], ReduxState().distances);
  const dataRowIndex = R.findLastIndex(x => x.rmdDateTime <= pointTimestamp)(distances);
  if (dataRowIndex != -1) {
    return distances[dataRowIndex].distance;
  } else {
    return null;
  }
}
