import React from 'react';
import {PropTypes} from 'prop-types';
import Resources from 'utilities/Resources';
import {filterByName} from 'utilities/graphSettings';
import * as R from 'ramda';
import 'styles/_dropdown.scss';

class DropDown extends React.Component {
  static propTypes = {
    associatedIdentifier: PropTypes.object,
    selected: PropTypes.object,
    options: PropTypes.array.isRequired,
    defaultText: PropTypes.string,
    labelText: PropTypes.string,
    valid: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    inputName: PropTypes.string.isRequired,
    orderBy: PropTypes.string,
    small: PropTypes.bool,
    centerText: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);

    this.defaultText = Resources.localizedString.pleaseSelect;
    this.input = React.createRef();

    const defaultProps = {
      defaultText: this.defaultText,
      small: false,
      centerText: false,
      orderBy: 'name'
    };

    const mergedProps = Object.assign({}, defaultProps, this.props);

    if (mergedProps.selected){
      mergedProps.defaultText = mergedProps.selected.name;
    }

    this.state = {
      options: R.sortBy(R.prop(mergedProps.orderBy))(mergedProps.options),
      filteredOptions: [],
      open: false,
      searchText: '',
      selectedOption: mergedProps.selected,
      textValue: mergedProps.defaultText
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options)) {
      this.setState({
        options: R.sortBy(R.compose(R.toLower, R.prop('name')))(this.props.options)
      });
    }

    if (JSON.stringify(this.props.selected) !== JSON.stringify(this.state.selectedOption)) {
      let value = this.defaultText;
      if (this.props.selected){
        value = this.props.selected.name;
      }
      this.setState({
        searchText: '',
        textValue: value,
        selectedOption: this.props.selected
      });
    }
  }

  toggleOpen = () => {
    this.setState({
      open: !this.state.open
    });
  }

  inputChange = (e) => {
    if (e.target.value) {
      this.setState({
        searchText: e.target.value,
        textValue: e.target.value,
        filteredOptions: filterByName(this.state.options, e.target.value)
      });
    } else {
      this.setState({
        searchText: '',
        textValue: '',
        filteredOptions: []
      });
    }
  }

  onChannelInputFocus = (e) => {
    this.setState({
      open: true,
      searchText: '',
      textValue: ''
    })
  }

  closeSelect = () => {
    let value = this.defaultText;
    if (this.state.selectedOption){
      value = this.state.selectedOption.name;
    }
    this.setState({
      open: false,
      searchText: '',
      textValue: value,
      filteredOptions: []
    })
  }

  updateSelectedOption = (e, option) => {
    this.setState({
      selectedOption: option,
      textValue: option.name
    }, () => {
      this.closeSelect();
      if (this.props.associatedIdentifier) {
        this.props.onChange(this.state.selectedOption, this.props.associatedIdentifier);
      } else {
        this.props.onChange(this.state.selectedOption);
      }
    })
  }

  Option = (option, index) => {
    let selectedCssClass = ''
    if (this.state.selectedOption && this.state.selectedOption == option){
      selectedCssClass = " dropdown-select__option--selected";
    }
    return <span key={index} onClick={(event) => this.updateSelectedOption(event, option)} className={"dropdown-select__option" + selectedCssClass}>{option.name}</span>
  }

  onInputFocus = (e) => {
    this.setState({
      open: true,
      searchText: '',
      textValue: ''
    })
  }

  render() {
    const filteredOptions = this.state.filteredOptions.length > 0;
    let className = "dropdown-select";
    if (this.state.open) {
      className += " dropdown-select--open";
    }

    if (this.props.small){
      className += " dropdown-select--small";
    }

    if (this.props.centerText && this.state.selectedOption){
      className += " dropdown-select--centered";
    }

    if (!this.props.valid) {
      className += " dropdown-select--invalid";
    }

    return(
      <React.Fragment>
        {this.props.labelText ?
          <label htmlFor={this.props.inputName} className="">{this.props.labelText}</label>
          : null
        }
        <div className={className}>
          <input type="text" ref={this.input} value={this.state.textValue} name={this.props.inputName} autoComplete="off" id={this.props.inputName} onFocus={this.onInputFocus} onChange={this.inputChange} className="" />
          <span onClick={this.toggleOpen} className="dropdown-select__arrow"></span>
          { this.state.open ?
            <React.Fragment><div onClick={this.closeSelect} className="cover" />
              <div className="dropdown-select__option-wrapper">
                <div className="dropdown-select__option-panel">
                  {filteredOptions ? this.state.filteredOptions.map(this.Option) : this.state.options.map(this.Option)}
                </div>
              </div>
            </React.Fragment>
            : null
          }
        </div>
      </React.Fragment>
    );
  }
}

export default DropDown;