import * as types from 'constants/actionTypes';

export function updateUserAdjustedWheelDiameter(newDiameter) {
  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_GRAPH_SETTINGS,
      newSettings: {userAdjustedWheelDiameterMillimetres: newDiameter},
    });
  };
}

export function resetUserAdjustedWheelDiameter() {
  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_GRAPH_SETTINGS,
      newSettings: {userAdjustedWheelDiameterMillimetres: null},
    });
  };
}

export function updateWheelDiameterAtLogStart(newDiameter) {
  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_GRAPH_SETTINGS,
      newSettings: {wheelDiameter: newDiameter},
    });
  };
}
