// import * as actionTypes from 'constants/actionTypes';
// import objectAssign from 'object-assign';
import initialState from './initialState';

export default function resourcesReducer(state = initialState.resources, action) {
  switch (action.type) {
    // This is actually in the userSettingsReducer.
    // case actionTypes.UPDATE_SELECTED_CULTURE:
    //   return objectAssign({}, state, {vehicleSelectOptions});

    default:
      return state;
  }
}
