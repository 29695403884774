import * as React from 'react'
import {PropTypes} from 'prop-types';
import { DragSource } from 'react-dnd';
import * as dragDropTypes from 'constants/dragDropTypes';

const channelSource = {
  beginDrag(props) {
    return props.channel;
  }
};

function collect(connect) {
  return {
    connectDragSource: connect.dragSource()
  };
}

class UnselectedGraphChannel extends React.Component {
  static propTypes = {
    channel: PropTypes.object.isRequired,
    // Injected by React DnD above:
    connectDragSource: PropTypes.func.isRequired
  };

  render() {
    const { connectDragSource } = this.props;
    return connectDragSource(
        <div className={"channel-list__item"}>
          <span className="channel-list__item-label">{this.props.channel.name}</span>
          <span className="channel-list__item-short-label">({this.props.channel.shortName})</span>
          <span className="channel-list__item-channel-colour-indicator" style={{backgroundColor: this.props.channel.defaultColor}}></span>
        </div>
    );
  }
}

export default DragSource(dragDropTypes.UNSELECTED_CHANNEL, channelSource, collect)(UnselectedGraphChannel);