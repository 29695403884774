const string = {};

string.format = function (str) {
  var args = Array.prototype.slice.call(arguments, 1);        // arguments[0] is the format string here.
  return str.replace(/{(\d+)}/g, function (match, number) {   // number is the value of the 1st capture.
    return typeof args[number] != 'undefined'
                  ? args[number]
                  : match;
  });
};

// DC: This was the original one I used to use that added it to the prototype of String.
// Sample usage:    "Welcome, {0} {1}".format(firstName, lastName);
// if (!String.prototype.format) {
//   String.prototype.format = function () {
//       var args = arguments;
//       // NOTE: For proper javascript documentation see:
//       //       https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#Specifying_a_function_as_a_parameter
//       return this.replace(/{(\d+)}/g, function (match, number) {   // number is the value of the 1st capture.
//         return typeof args[number] != 'undefined'
//                       ? args[number]
//                       : match;
//       });
//   };
// }

export default string;
