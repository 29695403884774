import React from 'react';
import {PropTypes} from 'prop-types';
import {bindActionCreators} from 'redux';
import * as graphSettingsActions from 'actions/graphSettingsActions';
import * as dialogueActions from 'actions/dialogueActions';
import * as presetActions from 'actions/presetActions';
import * as permissions from 'constants/permissions';
import { isAllowed } from 'utilities/authorization';
import {Link} from 'react-router-dom';
import routes from 'routes';
import Resources from 'utilities/Resources';
import {connect} from 'react-redux';
import {filterPresets, getPresetById, copy} from 'utilities/graphSettings';
import 'styles/_preset-select.scss';

class PresetSelection extends React.Component {
  static propTypes = {
    presets: PropTypes.array.isRequired,
    graphSettings: PropTypes.object.isRequired,
    graphSettingsActions: PropTypes.object.isRequired,
    dialogueActions: PropTypes.object.isRequired,
    presetActions: PropTypes.object.isRequired,
    selectedPresetId: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.defaultText = Resources.localizedString.defaultValue;
    this.searchInput = React.createRef();

    const selected = getPresetById(this.props.selectedPresetId, this.props.presets);
    const value = this.getPresetSelectionValue(selected, this.props.presets);

    this.state = {
      graphDefaults: {analogueGraphs: copy(this.props.graphSettings.analogueGraphs), digitalGraphs: copy(this.props.graphSettings.digitalGraphs)},
      presets: copy(this.props.presets),
      filteredPresets: [],
      searchText: '',
      value: value,
      open: false,
      selected: getPresetById(this.props.selectedPresetId, this.props.presets),
      focused: false
    };
    this.user = this.props.user;
  }

  searchKeyUp = (e) => {
    if (e.target.value) {
      this.setState({
        searchText: e.target.value,
        value: e.target.value,
        filteredPresets: filterPresets(this.state.presets, e.target.value)
      });
    } else {
      this.setState({
        searchText: '',
        value: '',
        filteredPresets: []
      });
    }
  }

  addNewPreset = () => {
    this.props.presetActions.setSaveNew(true);
    this.props.dialogueActions.openChannelsDialogue();
    this.close();
  }

  onFocus = (e) => {
    this.setState({
      open: true,
      searchText: '',
      value: ''
    })
  }

  toggleOpen = (e) => {
    let value = this.state.value;
    if(!this.state.open) {
      value = '';
    }

    this.setState({
      open: !this.state.open,
      value
    }, () => {
      if(this.state.open) {
        this.searchInput.current.focus();
      }
    })
  }

  close = () => {
    let value = this.defaultText;
    if (this.state.selected){
      value = this.state.selected.name;
    }
    this.setState({
      open: false,
      searchText: '',
      value: value,
      filteredPresets: []
    })
  }

  updateGraphSettings = (e, preset) => {
    this.setState({
      selected: preset,
      value: preset.name
    }, () => {
      this.close();
      this.props.presetActions.setSelectedId(preset.presetId);
      const graphConfiguration = JSON.parse(preset.configuration);
      this.props.graphSettingsActions.updateGraphSettings(graphConfiguration);
    })
  }

  resetDefaults = () => {
    this.setState({
      selected: null,
      searchText: '',
      value: this.defaultText,
      filteredPresets: [],
      open: false
    }, () => {
      this.props.presetActions.setSelectedId('');
      this.props.graphSettingsActions.updateGraphSettings(this.state.graphDefaults)
    });
  }

  Option = (preset) => {
    let selectedCssClass = ''
    if(this.state.selected && this.state.selected.presetId === preset.presetId){
      selectedCssClass = " preset-select__option--selected";
    }
    return <span key={preset.presetId} onClick={(event) => this.updateGraphSettings(event, preset)} className={"preset-select__option" + selectedCssClass}>{preset.name}</span>
  }

  getPresetSelectionValue = (selected) => {
    let value = this.defaultText;
    if (selected){
      value = selected.name;
    }
    return value;
  }

  componentDidUpdate(prevProps) {
    if (this.props.presets != prevProps.presets) {
      const selected = getPresetById(this.props.selectedPresetId, this.props.presets);
      const value = this.getPresetSelectionValue(selected, this.props.presets);

      this.setState({
        presets: this.props.presets,
        selected,
        value
      });
    }

    if (this.props.selectedPresetId != prevProps.selectedPresetId) {
      const selected = getPresetById(this.props.selectedPresetId, this.props.presets);
      const value = this.getPresetSelectionValue(selected, this.props.presets);

      this.setState({
        selected,
        value
      });
    }
  }

  render() {
    const filtered = this.state.searchText.length > 0;
    const resources = Resources.localizedString;
    return (
      <div className="flex-end padded">
        <div className="preset-select-wrapper">
            <label htmlFor="presetSearch" className="">{resources.presets}</label>
            <div className={this.state.open ? "preset-select preset-select--open" : "preset-select"}>
              <input type="text" ref={this.searchInput} value={this.state.value} name="presetSearch" autoComplete="off" id="presetSearch" onFocus={this.onFocus} onChange={this.searchKeyUp} className="" />
              <span onClick={this.toggleOpen} className="preset-select__arrow"></span>
              { this.state.open ?
                <React.Fragment><div onClick={this.close} className="cover" />
                  <div className="preset-select__option-wrapper">
                    <div className="preset-select__option-panel">
                      {filtered ? this.state.filteredPresets.map(this.Option) : this.state.presets.map(this.Option)}
                    </div>
                    <div className="preset-select__link-wrapper">
                      <button className="preset-select__link" onClick={this.resetDefaults}>{resources.returnGraphToDefaultSettings}</button>
                      {isAllowed(this.user, [permissions.RDD_CAN_CONFIGURE]) &&
                        <React.Fragment>
                          <Link to={routes.admin.presets.index} className="preset-select__link">{resources.managePresets}</Link>
                          <button className="preset-select__link" onClick={this.addNewPreset}>{resources.addNewPreset}</button>
                        </React.Fragment>
                      }
                    </div>
                  </div>
                </React.Fragment>
                : null
            }
            </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    graphSettings: copy(state.graphSettings),
    presets: state.referenceData.enabledPresets,
    selectedPresetId: state.preset.selectedId,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    graphSettingsActions: bindActionCreators(graphSettingsActions, dispatch),
    dialogueActions: bindActionCreators(dialogueActions, dispatch),
    presetActions: bindActionCreators(presetActions, dispatch)
  };
}

export {PresetSelection as PresetSelectionNoRedux};

const ConnectedPresetSelection = connect(mapStateToProps, mapDispatchToProps)(PresetSelection);
export default ConnectedPresetSelection;
