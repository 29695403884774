import axios from 'axios';
import rddApiConfig from './rddApiConfig';
// "ReferenceError: regeneratorRuntime is not defined" when used async/await: https://github.com/babel/babel/issues/5085
// in the form "async => await () => ..."
// This page suggests it can be done without including the polyfill: https://www.valentinog.com/blog/how-async-await-in-react/
import '@babel/polyfill';

const sharingApi = {};

sharingApi.saveSettings = async (settingsRequest) => {
    const response = await axios.post('sharing', settingsRequest, rddApiConfig);
    return response.data;
}

sharingApi.getById = async (shareId) => {
  const response = await axios.get(`sharing/${shareId}`, rddApiConfig);
  return response.data;
}

export default sharingApi;