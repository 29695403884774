import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dialogueActions from 'actions/dialogueActions';
import * as graphHairlinesActions from 'actions/graphHairlinesActions';
import * as graphSettingsActions from 'actions/graphSettingsActions';
import * as distanceActions from 'actions/distanceActions';
import * as wheelDiameterActions from 'actions/wheelDiameterActions';
import distanceApi from 'api/distanceApi';
import vehiclesApi from 'api/vehiclesApi';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import dateTime from 'utilities/dateTimeUtilities';
import functions from 'utilities/functionUtilities';
import GraphDateRange from 'components/GraphDateRange';
import ZoomLevel from 'components/ZoomLevel';
import GraphSummary from 'components/GraphSummary';
import AdvancedSearch from 'components/AdvancedSearch';
import PresetSelection from 'components/PresetSelection';
import AnalogueGraph from 'components/AnalogueGraph';
import DigitalGraph from 'components/DigitalGraph';
import ChannelSelectDialogue from 'components/ChannelSelectDialogue';
import ExportDialogue from 'components/ExportDialogue';
import ShareDialogue from 'components/ShareDialogue';
import { spliceRangeAndDetail } from 'utilities/graphZoom.js';
import * as R from 'ramda';
import 'styles/rdd.scss';
import 'styles/graph-page.scss';
import demoPageLayout from 'components/demo/demoPageLayout';
import GraphDataErrorDialogue from 'components/GraphDataErrorDialogue';
import InvalidVehicleErrorDialogue from 'components/InvalidVehicleErrorDialogue';

class GraphPage extends React.Component {
  static propTypes = {
    analogueGraphs: PropTypes.array.isRequired,
    digitalGraphs: PropTypes.array.isRequired,
    dialogueActions: PropTypes.object.isRequired,
    graphHairlinesActions: PropTypes.object.isRequired,
    graphSettingsActions: PropTypes.object.isRequired,
    vehicleCode: PropTypes.string,
    graphSettings: PropTypes.object.isRequired,
    distanceActions: PropTypes.object.isRequired,
    wheelDiameterActions: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      distanceHairlines: false,
      channelHairlines: false,
      clearChannelHairlines: false,
      graphDataApiError: false,
      erroredGraphs: [],
      invalidVehicleCode: false
    };

    this.searchScrollContainerId = "advanced-search-scroll-container";
    this.rangeDistances = [];
  }

  async componentDidMount() {
    const rangeStart = moment(this.props.graphSettings.rangeStart);
    const rangeEnd = moment(this.props.graphSettings.rangeEnd);

    this.getWheelDiameterAtLogStart(rangeStart, rangeEnd);
    this.getRangeDistanceValues(rangeStart, rangeEnd);
  }

  refreshWithLatestData = () => {
    const { newRangeStart: rangeStart, newRangeEnd: rangeEnd } =
      dateTime.generateRangeOfSameDurationWhichEndsNow(this.props.graphSettings.rangeStart, this.props.graphSettings.rangeEnd);

    this.props.graphSettingsActions.updateGraphSettings({ rangeStart, rangeEnd, detailQuery: false });
  };

  debouncedRefreshWithLatestData = functions.debounce(this.refreshWithLatestData);

  toggleDistanceHairlines = () => {
    this.setState({
      distanceHairlines: !this.state.distanceHairlines
    }, () => {
      if (this.state.distanceHairlines) {
        this.turnOffChannelHairlines();
      }
    });
  };

  turnOffDistanceHairlines = () => {
    this.setState({
      distanceHairlines: false
    });
  };

  clearAllDistanceHairlinesClicked = () => {
    this.props.graphHairlinesActions.deleteAllDistanceHairlines();
  };

  toggleHairlines = () => {
    this.setState({
      channelHairlines: !this.state.channelHairlines
    }, () => {
      if (this.state.channelHairlines) {
        this.turnOffDistanceHairlines();
      }
    });
  };

  turnOffChannelHairlines = () => {
    this.setState({
      channelHairlines: false
    });
  };

  turnOffClearChannelHairlines = () => {
    this.setState({
      clearChannelHairlines: false
    });
  };

  clearAllChannelHairlinesClicked = () => {
    this.setState({
      clearChannelHairlines: true
    });
  };

  async getDetailDistanceValues(startDate, endDate) {
    if (this.rangeDistances.length > 0) {
      let requestBaseDistance = 0;
      let requestStartDateTime = startDate;
      let distances = [];
      const baseDistanceIndex = R.findLastIndex(d => d.rmdDateTime < startDate.valueOf())(this.rangeDistances);
      const baseDistance = this.rangeDistances[baseDistanceIndex];

      if (baseDistance) {
        requestBaseDistance = baseDistance.distance;
        requestStartDateTime = baseDistance.rmdDateTime;
      }

      const newDistances = await distanceApi.getByVehicleCode(this.props.graphSettings.selectedVehicleCode,
                                                              moment(requestStartDateTime).toISOString(),
                                                              moment(endDate).toISOString(),
                                                              this.props.graphSettings.detailResolution,
                                                              requestBaseDistance,
                                                              this.props.graphSettings.userAdjustedWheelDiameterMillimetres);

      newDistances.forEach((distance) => {
        distance.rmdDateTime = moment.utc(distance.rmdDateTime).valueOf();
      })

      if (this.props.graphSettings.detailQuery) {
        distances = spliceRangeAndDetail([...this.rangeDistances], newDistances, startDate.valueOf(), endDate.valueOf());
      }
      this.props.distanceActions.updateDistances(distances);
    }
  }

  async getRangeDistanceValues(startDate, endDate) {
    let baseDistanceValue = 0;
    let distances = await distanceApi.getByVehicleCode(this.props.graphSettings.selectedVehicleCode,
                                                       moment(startDate).toISOString(),
                                                       moment(endDate).toISOString(),
                                                       this.props.graphSettings.detailResolution,
                                                       baseDistanceValue,
                                                       this.props.graphSettings.userAdjustedWheelDiameterMillimetres);
    distances.forEach((distance) => {
      distance.rmdDateTime = moment.utc(distance.rmdDateTime).valueOf();
    })
    this.rangeDistances = [...distances];
    this.props.distanceActions.updateDistances(distances);
  }

  async getWheelDiameterAtLogStart(startDate, endDate) {
    let wheelDiameterAtLogStart =
      await vehiclesApi.getWheelDiameterAt(this.props.graphSettings.selectedVehicleCode,
                                           moment(startDate).toISOString());

    this.props.wheelDiameterActions.updateWheelDiameterAtLogStart(wheelDiameterAtLogStart);
  }

  async componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props) != JSON.stringify(prevProps)){
      const rangeStart = moment(this.props.graphSettings.rangeStart);
      const rangeEnd = moment(this.props.graphSettings.rangeEnd);

      if (this.props.graphSettings.rangeStart.valueOf() !== prevProps.graphSettings.rangeStart.valueOf() ||
            this.props.graphSettings.rangeEnd.valueOf() !== prevProps.graphSettings.rangeEnd.valueOf()) {
        this.getWheelDiameterAtLogStart(rangeStart, rangeEnd);
        this.getRangeDistanceValues(rangeStart, rangeEnd);
        this.props.graphHairlinesActions.selectedRangeChanged(rangeStart.valueOf(), rangeEnd.valueOf());
        return;
      }

      if (this.props.graphSettings.userAdjustedWheelDiameterMillimetres !== prevProps.graphSettings.userAdjustedWheelDiameterMillimetres) {
        this.getRangeDistanceValues(rangeStart, rangeEnd);
        return;
      }

      if (this.props.graphSettings.detailQuery !== prevProps.graphSettings.detailQuery && !this.props.graphSettings.detailQuery ||
          this.props.graphSettings.userAdjustedWheelDiameterMillimetres !== prevProps.graphSettings.userAdjustedWheelDiameterMillimetres) {
        this.getRangeDistanceValues(rangeStart, rangeEnd);
        return;
      }

      if ((this.props.graphSettings.detailRangeStart.valueOf() !== prevProps.graphSettings.detailRangeStart.valueOf() ||
           this.props.graphSettings.detailRangeEnd.valueOf() !== prevProps.graphSettings.detailRangeEnd.valueOf()) && this.props.graphSettings.detailQuery ||
          this.props.graphSettings.userAdjustedWheelDiameterMillimetres !== prevProps.graphSettings.userAdjustedWheelDiameterMillimetres) {
        this.getDetailDistanceValues(moment(this.props.graphSettings.detailRangeStart), moment(this.props.graphSettings.detailRangeEnd));
        return;
      }
    }
  }

  showGraphDataFetchErrorDialogue = (graphId) => {
    this.setState({
      graphDataApiError: true,
      erroredGraphs: [...new Set([...this.state.erroredGraphs, graphId])]
    });
  }

  closeGraphDataFetchErrorDialogue = () => {
    this.setState({
      graphDataApiError: false,
      erroredGraphs: []
    });
  }

  showVehicleDoesntExistError = () => {
    this.setState({
      invalidVehicleCode: true
    });
  }

  closeVehicleDoesntExistError = () => {
    this.setState({
      invalidVehicleCode: false
    });
  }

  render() {
    // alert(process.env.NODE_ENV === 'production' ? 'configureStoreProd' : 'configureStoreDev');
    // alert(process.env.NODE_ENV);
    // console.dir(process.env);      // Empty object was logged. Maybe some sort of proxy is involved when accessing properties of env.
    // alert(process.env.API_URL);
    return (
      <div className="page-content">
        <GraphDateRange />
        <ChannelSelectDialogue />
        <ExportDialogue />
        <ShareDialogue />
        <GraphDataErrorDialogue isOpen={this.state.graphDataApiError} graphs={this.state.erroredGraphs} onClose={this.closeGraphDataFetchErrorDialogue} />
        <InvalidVehicleErrorDialogue isOpen={this.state.invalidVehicleCode} vehicleCode={this.props.graphSettings.selectedVehicleCode} onClose={this.closeVehicleDoesntExistError} />
        <Tabs className="graphtab-wrapper">
          <TabList>
            <Tab>Graph View</Tab>
            {/* <Tab>Table View</Tab>
            <Tab>Reports</Tab> */}
          </TabList>
          <TabPanel>
            <div className="graph-tab-title">
              <a className="action-button action-button--share margin-left right" onClick={this.props.dialogueActions.openShareDialogue}>
                <span className="action-button__icon"></span>
                <span className="action-button__action">Share</span>
              </a>
              <a className="action-button action-button--export margin-left right" onClick={this.props.dialogueActions.openExportDialogue}>
                <span className="action-button__icon"></span>
                <span className="action-button__action">Export</span>
              </a>
              <a className="action-button action-button--chart right" onClick={this.props.dialogueActions.openChannelsDialogue}>
                <span className="action-button__icon"></span>
                <span className="action-button__action">Set Data Channels</span>
              </a>
              <h1>Locomotive {this.props.vehicleCode}</h1>
            </div>
            <div className="flexbox" id={this.searchScrollContainerId}>
              <GraphSummary />
              <AdvancedSearch scrollContainerId={this.searchScrollContainerId} />
              <div className="chart-section overflow-hidden">
                <div className="graph-navigation flexbox relative">
                  <ZoomLevel additionalClassName="left" />
                  <div className="actions-toolbar">
                    <div className="half-margin-bottom">
                      <button className={"button button--hairline-action button--calculate-distance" + (this.state.distanceHairlines ? " on" : "")} onClick={this.toggleDistanceHairlines}>Calculate Distance</button>
                      <button className="link-button margin-left" onClick={this.clearAllDistanceHairlinesClicked}>Clear All</button>
                    </div>
                    <div>
                      <button className={"button button--hairline-action button--add-hairline" + (this.state.channelHairlines ? " on" : "")} onClick={this.toggleHairlines}>Add Hairline</button>
                      <button className="link-button margin-left" onClick={this.clearAllChannelHairlinesClicked}>Clear All</button>
                    </div>
                  </div>
                  <div className="actions-toolbar flex-end">
                    <button className="button button--secondary range-search-button right" onClick={this.debouncedRefreshWithLatestData}>
                      Go to Now
                      </button>
                  </div>
                  <PresetSelection />
                </div>
                <div className="graphs-container">
                  {this.props.digitalGraphs.map((graph) => {
                    if (graph.channels.length > 0) {
                      return (
                        <DigitalGraph key={graph.graphId}
                          graphId={graph.graphId}
                          clickingAddsDistanceHairline={this.state.distanceHairlines}
                          turnOffDistanceHairlines={this.turnOffDistanceHairlines}
                          clickingAddsChannelHairline={this.state.channelHairlines}
                          turnOffChannelHairlines={this.turnOffChannelHairlines}
                          clearChannelHairlines={this.state.clearChannelHairlines}
                          turnOffClearChannelHairlines={this.turnOffClearChannelHairlines}
                          showGraphDataFetchingError={this.showGraphDataFetchErrorDialogue}
                          showVehicleDoesntExistError={this.showVehicleDoesntExistError}
                          className="graph" />
                      );
                    }
                  })}
                  {this.props.analogueGraphs.map((graph) => {
                    if (graph.channels.length > 0) {
                      return (
                        <AnalogueGraph key={graph.graphId}
                          graphId={graph.graphId}
                          clickingAddsDistanceHairline={this.state.distanceHairlines}
                          turnOffDistanceHairlines={this.turnOffDistanceHairlines}
                          clickingAddsChannelHairline={this.state.channelHairlines}
                          turnOffChannelHairlines={this.turnOffChannelHairlines}
                          clearChannelHairlines={this.state.clearChannelHairlines}
                          turnOffClearChannelHairlines={this.turnOffClearChannelHairlines}
                          showGraphDataFetchingError={this.showGraphDataFetchErrorDialogue}
                          showVehicleDoesntExistError={this.showVehicleDoesntExistError}
                          className="graph" />
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </TabPanel>
          {/* <TabPanel>
            <h2>Table View</h2>
          </TabPanel>
          <TabPanel>
            <h2>Reports</h2>
          </TabPanel> */}
        </Tabs>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    analogueGraphs: state.graphSettings.analogueGraphs,
    digitalGraphs: state.graphSettings.digitalGraphs,
    vehicleCode: state.graphSettings.selectedVehicleCode,
    graphSettings: state.graphSettings,
  };
  // If do anything expensive in here, introduce memoization using a library like Reselect.
}

function mapDispatchToProps(dispatch) {
  return {
    dialogueActions: bindActionCreators(dialogueActions, dispatch),
    graphHairlinesActions: bindActionCreators(graphHairlinesActions, dispatch),
    graphSettingsActions: bindActionCreators(graphSettingsActions, dispatch),
    distanceActions: bindActionCreators(distanceActions, dispatch),
    wheelDiameterActions: bindActionCreators(wheelDiameterActions, dispatch)
  };
}

export { GraphPage as GraphPageNoRedux };

const ConnectedGraphPage = connect(mapStateToProps, mapDispatchToProps)(GraphPage);
export default ConnectedGraphPage;

const GraphPageInDemoLayout = demoPageLayout(ConnectedGraphPage, 'orange');
export { GraphPageInDemoLayout };
