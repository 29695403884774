import React from 'react';
import {PropTypes} from 'prop-types';
import * as R from 'ramda';
import {connect} from 'react-redux';
import {DragDropContext} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import {getAnalogueChannels, getDigitalChannels, filterChannels, filterSelectedChannels, filterSelectedChannel, getGraphFromGraphs, sortByNameCaseInsensitive, copy} from 'utilities/graphSettings';
import GraphSelected from 'components/GraphSelected';
import UnselectedChannelList from 'components/UnselectedChannelList';
import objectAssign from 'object-assign';
import * as channelTypes from 'constants/channelTypes';
import 'styles/_channel-select.scss';

class ChannelSelection extends React.Component {
  static propTypes = {
    channelType: PropTypes.string.isRequired,
    onGraphUpdate: PropTypes.func.isRequired,
    graphs: PropTypes.array.isRequired,
    channels: PropTypes.array.isRequired
  };

  constructor(props, context) {
    super(props, context);

    let unselectedChannels = [];
    this.graphType = '';

    if (this.props.channelType === channelTypes.DIGITAL_CHANNEL) {
      unselectedChannels = [...filterSelectedChannels(getDigitalChannels(this.props.channels), R.pluck('channels', this.props.graphs))];
      this.graphType = channelTypes.DIGITAL_CHANNEL;
    }

    if (this.props.channelType === channelTypes.ANALOGUE_CHANNEL) {
      unselectedChannels = [...filterSelectedChannels(getAnalogueChannels(this.props.channels), R.pluck('channels', this.props.graphs))];
      this.graphType = channelTypes.ANALOGUE_CHANNEL;
    }

    this.state = {
      graphs: copy(this.props.graphs),
      unselectedChannels: [...sortByNameCaseInsensitive(unselectedChannels)],
      filteredChannels: [],
      searchText: ''
    };
  }

  searchKeyUp = (e) => {
    if (e.target.value) {
      this.setState({
        searchText: e.target.value,
        filteredChannels: filterChannels(this.state.unselectedChannels, e.target.value)
      });
    } else {
      this.setState({
        searchText: '',
        filteredChannels: []
      });
    }
  }

  onDragDrop = (channel, graphId) => {
    // clone graphs
    let graphs = copy(this.state.graphs);
    let graph = getGraphFromGraphs(graphId, graphs);

    if (graph.channels.filter(c => c.channelId == channel.channelId) == 0) {
      graph.channels.push(channel);
      // channel.graphId only available if from another graph
      // that channel needs removing from origin graph after being added to graph above
      if (channel.graphId) {
        graphs = this.getGraphsWithChannelRemoved(graphs, channel);
      }

      // clone selected channels
      let unselectedChannels = objectAssign([], this.state.unselectedChannels);
      const updatedUnselectedChannels = filterSelectedChannel(unselectedChannels, channel);
      this.setState({
        unselectedChannels: updatedUnselectedChannels,
        filteredChannels: filterChannels(updatedUnselectedChannels, this.state.searchText),
        graphs: graphs
      }, this.props.onGraphUpdate(graphs, this.props.channelType));
    }
  }

  onMove = (channels, graphId) => {
    // clone graphs
    let graphs = copy(this.state.graphs);
    let graph = getGraphFromGraphs(graphId, graphs);
    let graphIndex = R.indexOf(graph, graphs);

    if (graphIndex != -1) {
      graphs[graphIndex].channels = channels;
      this.setState({
        graphs: graphs
      }, this.props.onGraphUpdate(graphs, this.props.channelType));
    }
  }

  onRemove = (channel) => {
    let graphs = copy(this.state.graphs);
    const graphsChannelRemoved = this.getGraphsWithChannelRemoved(graphs, channel);
    // Remove selected colour if set
    channel.color = null;

    // sort the below array assigning
    const beforeChannels = [...this.state.unselectedChannels];
    beforeChannels.push(channel);
    const unselectedChannels = sortByNameCaseInsensitive(beforeChannels);
    this.setState({
      unselectedChannels: unselectedChannels,
      filteredChannels: filterChannels(unselectedChannels, this.state.searchText),
      graphs: graphsChannelRemoved
    }, this.props.onGraphUpdate(graphsChannelRemoved, this.props.channelType));
  }

  onChannelColorChange = (channel, graphId) => {
    // set channel again
    let graphs = copy(this.state.graphs);
    let graph = getGraphFromGraphs(graphId, graphs);
    var index = graph.channels.findIndex(x => x.channelId === channel.channelId);
    if (index > -1) {
      graph.channels[index] = copy(channel);
    }
    this.setState({
      graphs: graphs
    }, this.props.onGraphUpdate(graphs, this.props.channelType));
  }

  onClear = (graphId) => {
    // remove channels from graph
    let graphs = copy(this.state.graphs);
    const index = graphs.findIndex(x => x.graphId === graphId);
    const graphChannels = objectAssign([], graphs[index].channels);
    // Remove selected colour if set
    graphs[index].channels.forEach((channel) => { delete channel.color });
    graphs[index].channels = [];

    // sort the below array assigning as it's not returning the correct list
    const beforeChannels = [...this.state.unselectedChannels];
    const unselectedChannels = sortByNameCaseInsensitive(beforeChannels.concat(graphChannels));
    this.setState({
      unselectedChannels: unselectedChannels,
      filteredChannels: filterChannels(unselectedChannels, this.state.searchText),
      graphs: graphs
    }, this.props.onGraphUpdate(graphs, this.props.channelType));
  }

  getGraphsWithChannelRemoved = (graphs, channel) => {
    let graph = getGraphFromGraphs(channel.graphId, graphs);
    const index = graph.channels.findIndex(x => x.channelId === channel.channelId);
    if (index > -1) {
      graph.channels.splice(index, 1);
    }
    delete channel.graphId;

    return graphs;
  }

  componentDidUpdate(prevProps) {
    if(this.props.channels !== prevProps.channels) {
      let unselectedChannels = [];
      this.graphType = '';

      if (this.props.channelType === channelTypes.DIGITAL_CHANNEL) {
        unselectedChannels = [...filterSelectedChannels(getDigitalChannels(this.props.channels), R.pluck('channels', this.state.graphs))];
        this.graphType = channelTypes.DIGITAL_CHANNEL;
      }

      if (this.props.channelType === channelTypes.ANALOGUE_CHANNEL) {
        unselectedChannels = [...filterSelectedChannels(getAnalogueChannels(this.props.channels), R.pluck('channels', this.state.graphs))];
        this.graphType = channelTypes.ANALOGUE_CHANNEL;
      }

      if (this.state.searchText) {
        this.setState({
          unselectedChannels: [...sortByNameCaseInsensitive(unselectedChannels)],
          filteredChannels: filterChannels(unselectedChannels, this.state.searchText)
        });
      } else {
        this.setState({
          unselectedChannels: [...sortByNameCaseInsensitive(unselectedChannels)],
          filteredChannels: unselectedChannels
        });
      }
    }
  }

  render() {
    const filtered = this.state.searchText.length > 0;
    const graphs = objectAssign([], this.state.graphs);
    return (
      <div>
        <div className="left channel-list__wrapper">
          <h3 className="channel-list__title">{this.graphType} Channel Search</h3>
          <input type="text" onKeyUp={this.searchKeyUp} className="text channel-list__search-input margin-bottom" defaultValue={this.state.searchText} />
          <UnselectedChannelList onRemove={item => this.onRemove(item)} channels={filtered ? this.state.filteredChannels : this.state.unselectedChannels} />
        </div>
        <div className="left">
          {graphs.map((graph) => (
            <GraphSelected graph={graph} key={graph.graphId} onClear={this.onClear} onChannelColourChange={this.onChannelColorChange}
                           onRemove={this.onRemove} onMove={channels => this.onMove(channels, graph.graphId)} onDrop={item => this.onDragDrop(item, graph.graphId)} />
          ))}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    channels: copy(state.referenceData.channels)
  };
}
const DragDropContextChannelSelection = DragDropContext(HTML5Backend)(ChannelSelection);
export default connect(mapStateToProps)(DragDropContextChannelSelection);