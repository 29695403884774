import moment from 'moment';
import {detailResolution} from 'utilities/graphSettings';
import numbers from 'utilities/numberUtilities';
import * as R from 'ramda';

const maxPoints = 5000;

let resolutionsAvailable = [
  {name: detailResolution.OneSecond, seconds: 1, allowed: false},
  {name: detailResolution.FiveSeconds, seconds: 5, allowed: false},
  //{name: detailResolution.TenSeconds, seconds: 10, allowed: false},
  {name: detailResolution.ThirtySeconds, seconds: 30, allowed: false},
  {name: detailResolution.OneMinute, seconds: 60, allowed: false},
  {name: detailResolution.FiveMinutes, seconds: 300, allowed: false},
  //{name: detailResolution.TenMinutes, seconds: 600, allowed: false},
  {name: detailResolution.ThirtyMinutes, seconds: 1800, allowed: false},
  {name: detailResolution.OneHour, seconds: 3600, allowed: false},
];

function getSecondsInRange(startDate, endDate) {
  const duration = moment.duration(endDate.diff(startDate));
  return duration.asSeconds();
}

export function getAvailableResolutionForRange(startDate, endDate) {
  let minimumFound = false;
  const secondsInRange = getSecondsInRange(startDate, endDate);

  resolutionsAvailable.forEach((resolution) => {
    resolution.pointsInRange = secondsInRange / resolution.seconds;
    resolution.allowed = resolution.pointsInRange <= maxPoints;
  });

  for (let i = 0; i < resolutionsAvailable.length; i++) {
    if (!minimumFound) {
      if (resolutionsAvailable[i].allowed === true) {
        minimumFound = true;
        return resolutionsAvailable[i].name;
      }
    }
  }
}

export function spliceRangeAndDetail(rangeDps, detailDps, detailStart, detailEnd) {
  let splicedDps = [];

  // Last index of range with date less than or = detail window
  const startRangeDetailIndex = R.findIndex(x => x.rmdDateTime >= moment(detailStart).valueOf())(rangeDps);
  const endRangeDetailIndex = R.findLastIndex(x => x.rmdDateTime <= moment(detailEnd).valueOf())(rangeDps);

  const indexDifference = endRangeDetailIndex - startRangeDetailIndex;
  rangeDps.splice(startRangeDetailIndex, indexDifference +1);

  if (rangeDps.length == 0 && detailDps.length == 0) {
    // do nothing, no data
  } else if (detailDps.length == 0) {
    for (let i = 0; i < rangeDps.length; i++) {
      splicedDps.push(rangeDps[i]);
    }
  } else if (rangeDps.length == 0) { //should never happen?
    for (let i = 0; i < detailDps.length; i++) {
      splicedDps.push(detailDps[i]);
    }
  } else {
    const detailStartX = detailDps[0].rmdDateTime;
    const detailEndX = detailDps[detailDps.length - 1].rmdDateTime;

    // Find last data point index in range where-after detail data will be inserted
    const lastRangeIdx = findLastRangeIdxBeforeDetailStart(rangeDps, detailStartX);

    //Insert 1st part of range
    if (lastRangeIdx >= 0) {
      splicedDps.push.apply(splicedDps, rangeDps.slice(0, lastRangeIdx + 1));
    }

    //Insert detail
    splicedDps.push.apply(splicedDps, detailDps.slice(0));

    //Insert last part of range
    let startEndRangeIdx = rangeDps.length;
    for (let i = startEndRangeIdx; i >= lastRangeIdx; i--) {
      if (i <= 1 || rangeDps[i - 1].rmdDateTime <= detailEndX) {
        break;
      } else {
        startEndRangeIdx--;
      }
    }

    if (startEndRangeIdx < rangeDps.length) {
      splicedDps.push.apply(splicedDps, rangeDps.slice(startEndRangeIdx, rangeDps.length));
    }
  }

  return splicedDps;
}

function findLastRangeIdxBeforeDetailStart(rangeDps, firstDetailTime) {
  let minIndex = 0;
  let maxIndex = rangeDps.length - 1;
  let currentIndex;
  let currentElement;

  // Handle out of range cases
  if (rangeDps.length == 0 || firstDetailTime <= rangeDps[0].rmdDateTime) {
    return -1;
  } else if (rangeDps[rangeDps.length-1].rmdDateTime < firstDetailTime) {
    return rangeDps.length - 1;
  }

  // Use binary search to find index of data point in range data that occurs immediately before firstDetailTime
  while (minIndex <= maxIndex) {
    currentIndex = Math.floor((minIndex + maxIndex) / 2);
    currentElement = rangeDps[currentIndex];

    if (currentElement.rmdDateTime < firstDetailTime) {
      minIndex = currentIndex + 1;

      //we want previous point, and will not often have an exact match due to different sampling intervals
      if (rangeDps[minIndex].rmdDateTime > firstDetailTime) {
        return currentIndex;
      }
    }
    else if (currentElement.rmdDateTime > firstDetailTime) {
      maxIndex = currentIndex - 1;

      //we want previous point, and will not often have an exact match due to different sampling intervals
      if (rangeDps[maxIndex].rmdDateTime < firstDetailTime) {
        return currentIndex - 1;
      }

    } else {
      return currentIndex - 1; //if exact match, we use previous range data point
    }
  }

  return -1;
}

const zoomButtons = {};

zoomButtons.getDetailRangeForNextLevelZoomIn = (currentGraphSettings, zoomInStepPercentage) => {
  // If we zoom in with the zoom point at the centre of the interval we will do half the zooming from the start and half of it from the end, e.g. zooming in 20% will
  // increase the starting point by 10% and reduce the ending point by 10% with respect to the current detail range.
  const zoomInFactor = zoomInStepPercentage / 100;
  const zoomInFactorPerSide = zoomInFactor / 2;
  const currentDetailRangeStart = (currentGraphSettings.detailRangeStart || currentGraphSettings.rangeStart).valueOf();
  const currentDetailRangeEnd = (currentGraphSettings.detailRangeEnd || currentGraphSettings.rangeEnd).valueOf();
  const currentDetailRangeInterval = currentDetailRangeEnd - currentDetailRangeStart;
  const newDetailRangeStart = currentDetailRangeStart + currentDetailRangeInterval * zoomInFactorPerSide;
  const newDetailRangeEnd = currentDetailRangeEnd - currentDetailRangeInterval * zoomInFactorPerSide;
  return [true, moment(newDetailRangeStart), moment(newDetailRangeEnd)];
};

zoomButtons.getDetailRangeForNextLevelZoomOut = (currentGraphSettings, zoomInStepPercentage) => {
  let numberOfRangeBoundariesZoomingOutGoesBeyond = 0;
  const zoomInFactor = zoomInStepPercentage / 100;
  // We want to zoom out to a range which, if we then zoomed in would then give us the current range. The zoom out factor is with respect to the current interval,
  // which is smaller than the one we would be zooming in from. if the zoomInFactorPerSide was 1/5, the zoom out factor would need to be 1/4.
  // I also noticed we get a floating point error when use this formula. For 0.1 the compiler tells us the result is 0.11111111111111116 when 1/9 is actually
  // 0.1111111111111111.
  // We will therefore truncate the last digit using strip().
  const zoomOutFactorWithRoundingError = (1 / (1 - zoomInFactor)) - 1;
  const zoomOutFactor = numbers.strip(zoomOutFactorWithRoundingError, 15);
  const zoomOutFactorPerSide = zoomOutFactor / 2;
  const currentDetailRangeStart = (currentGraphSettings.detailRangeStart || currentGraphSettings.rangeStart).valueOf();
  const currentDetailRangeEnd = (currentGraphSettings.detailRangeEnd || currentGraphSettings.rangeEnd).valueOf();
  const currentDetailRangeInterval = currentDetailRangeEnd - currentDetailRangeStart;
  let newDetailRangeStart = currentDetailRangeStart - currentDetailRangeInterval * zoomOutFactorPerSide;
  let newDetailRangeEnd = currentDetailRangeEnd + currentDetailRangeInterval * zoomOutFactorPerSide;

  if (newDetailRangeStart <= currentGraphSettings.rangeStart) {
    newDetailRangeStart = currentGraphSettings.rangeStart;
    numberOfRangeBoundariesZoomingOutGoesBeyond += 1;
  }

  if (newDetailRangeEnd >= currentGraphSettings.rangeEnd) {
    newDetailRangeEnd = currentGraphSettings.rangeEnd;
    numberOfRangeBoundariesZoomingOutGoesBeyond += 1;
  }

  return [
    numberOfRangeBoundariesZoomingOutGoesBeyond < 2,
    moment(newDetailRangeStart),
    moment(newDetailRangeEnd)
  ];
};

export {zoomButtons};
