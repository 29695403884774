import * as actionTypes from 'constants/actionTypes';
import objectAssign from 'object-assign';
import initialState from './initialState';

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function graphHairlinesReducer(state = initialState.graphHairlines, action) {
  switch (action.type) {
    case actionTypes.ADD_DISTANCE_HAIRLINE: {
      const newDistanceHairlines = addIfXvalNotAlreadyThere(state.distanceHairlines, action.xval);
      const newState = objectAssign({}, state, {distanceHairlines: newDistanceHairlines});
      return newState;
    }

    case actionTypes.MOVE_DISTANCE_HAIRLINE: {
      const newDistanceHairlines = moveLastWithXval(state.distanceHairlines, action.oldXval, action.newXval);
      const newState = objectAssign({}, state, {distanceHairlines: newDistanceHairlines});
      return newState;
    }

    case actionTypes.DELETE_DISTANCE_HAIRLINE: {
      const newDistanceHairlines = deleteLastWithXval(state.distanceHairlines, action.xval);
      const newState = objectAssign({}, state, {distanceHairlines: newDistanceHairlines});
      return newState;
    }

    case actionTypes.DELETE_ALL_DISTANCE_HAIRLINES:
      return objectAssign({}, state, {distanceHairlines: []});

    case actionTypes.DISTANCE_HAIRLINES_SELECTED_RANGE_CHANGED: {
      const newDistanceHairlines = adjustDistanceHairlinesForNewRange(state.distanceHairlines, action.rangeStartXval, action.rangeEndXval);
      const newState = objectAssign({}, state, {distanceHairlines: newDistanceHairlines});
      return newState;
    }

    default:
      return state;
  }
}

// There is a chance that the user could add another hairline with exactly the same xval as an existing one but that offers no value so it shouln't be allowed anyway.
function addIfXvalNotAlreadyThere(distanceHairlines, xval) {
  const newDistanceHairlines = [...distanceHairlines];
  if (!newDistanceHairlines.find(x => x.xval == xval)) {
    newDistanceHairlines.push({xval: xval});
  }

  return newDistanceHairlines;
}

function moveLastWithXval(distanceHairlines, oldXval, newXval) {
  const newDistanceHairlines = deleteLastWithXval(distanceHairlines, oldXval);
  if (newDistanceHairlines.length < distanceHairlines.length) {
    // We want the "modified" value to be at the end of the array so it is drawn last and therefore topmost.
    newDistanceHairlines.push({xval: newXval});
  }

  return newDistanceHairlines;
}

function deleteLastWithXval(distanceHairlines, xval) {
  const newDistanceHairlines = [...distanceHairlines];
  const index = findLastIndexWithMatchingXval(newDistanceHairlines, xval);

  if (index > -1) {
    newDistanceHairlines.splice(index, 1);
  }

  return newDistanceHairlines;
}

// Array prototype has a findIndex() but, annoyingly, there is no findLastIndex().
function findLastIndexWithMatchingXval(distanceHairlines, xval) {
  const index = [...distanceHairlines].reverse().findIndex(x => x.xval === xval)
  return index == -1 ? -1 : distanceHairlines.length - 1 - index;
}

function adjustDistanceHairlinesForNewRange(distanceHairlines, rangeStartXval, rangeEndXval) {
  // DC: My idea is that if ALL the distanceHairline xvals fall within the range keep them all, otherwise delete them all.
  //     This should be enough to retain them all if the user just extends the selected range
  //     but not to keep them hanging around if the user moves to a completely different range.
  // Alternatively we could have retained just the ones that still fell within the range, but I have gone for all or nothing.
  return distanceHairlines.every(x => x.xval >= rangeStartXval && x.xval <= rangeEndXval)
          ? distanceHairlines
          : [];
}

// This was the original function used instead of the newDistanceHairlines(). However, it is actually not too difficult to deliberately move hairline so it
// has exactly the same xval as another one. After that, using this function would mean that dragging either of those hairlines would result in both hairlines
// being moved and therefore permananently stuck together until deleted.
// // function moveAnyWithXval(distanceHairlines, oldXval, newXval) {
// //   const nonMatchingElements = distanceHairlines.filter(x => x.xval != oldXval);
// //   const matchingElements = distanceHairlines.filter(x => x.xval == oldXval);

// //   // We want to move any elements that had an xval of oldXval to the end so they get drawn last and so the last one dragged is topmost on all graphs.
// //   // If newXval is the same as oldXval, this effectively just brings a hairline to the top.
// //   const newDistanceHairlines =
// //     nonMatchingElements.map(x => Object.assign({}, {...x})).concat(
// //     matchingElements.map(x => Object.assign({}, {...x, xval: x.xval === oldXval ? newXval : x.xval})));

// //   return newDistanceHairlines;
// // }

// For similar reasons, this function was replaced by deleteLastWithXval().
// // const deleteAnyWithXval = (distanceHairlines, xval) => distanceHairlines.filter(x => x.xval != xval);
