import axios from 'axios';
import rddApiConfig from './rddApiConfig';
// "ReferenceError: regeneratorRuntime is not defined" when used async/await: https://github.com/babel/babel/issues/5085
// in the form "async => await () => ..."
// This page suggests it can be done without including the polyfill: https://www.valentinog.com/blog/how-async-await-in-react/
import '@babel/polyfill';

const presetsApi = {};

// https://medium.com/codingthesmartway-com-blog/getting-started-with-axios-166cb0035237
// https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index
presetsApi.getEnabled = async () => {
  const response = await axios.get('preset/enabled', rddApiConfig);
  return response.data;
}

presetsApi.get = async () => {
  const response = await axios.get('preset', rddApiConfig);
  return response.data;
}

presetsApi.getById = async (presetId) => {
  const response = await axios.get('preset/' + presetId, rddApiConfig);
  return response.data;
}

presetsApi.savePreset = async (presetRequest) => {
  const response = await axios.post('preset', presetRequest, rddApiConfig);
  return response;
}

presetsApi.updatePreset = async (presetRequest) => {
  const response = await axios.put('preset', presetRequest, rddApiConfig);
  return response;
}

presetsApi.setEnabled = async (presetRequest) => {
  const response = await axios.post('preset/set-enabled', presetRequest, rddApiConfig);
  return response;
}

presetsApi.deletePreset = async (presetId) => {
  const response = await axios.delete(`preset/${presetId}`, rddApiConfig);
  return response;
}

export default presetsApi;
