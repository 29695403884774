import {detailResolution, selectOptions} from 'utilities/graphSettings';
import * as channelTypes from 'constants/channelTypes';
import moment from 'moment';
import * as WheelDiamaterConstants from 'constants/wheelDiameter'

export default {
  fuelSavings: {
    newMpg: '',
    tradeMpg: '',
    newPpg: '',
    tradePpg: '',
    milesDriven: '',
    milesDrivenTimeframe: 'week',
    displayResults: false,
    dateModified: null,
    necessaryDataIsProvidedToCalculateSavings: false,
    savings: {
      monthly: 0,
      annual: 0,
      threeYear: 0,
    },
  },
  distances: [],
  graphHairlines: {
    distanceHairlines: [
      // {xval: 1606820400000},
      // {xval: 1606833600000},
      // {xval: 1606844546394.1462},
    ],
  },
  graphSettings: {
    advancedSearchPoints: [],
    selectedVehicleCode: '66748',
    wheelDiameter: WheelDiamaterConstants.DEFAULT_WHEEL_DIAMETER,
    userAdjustedWheelDiameterMillimetres: null,
    displayStepPlots: false,
    shouldFetchPointsFromApi: false,
    detailResolution: detailResolution.FiveSeconds,   // This is correct for the 6 hour range we define below.
    detailQuery: false,
    rangeStart: process.env.INITIAL_RANGE_START ? moment(process.env.INITIAL_RANGE_START) : moment().subtract(6, 'hours'),
    rangeEnd: process.env.INITIAL_RANGE_END ? moment(process.env.INITIAL_RANGE_END) : moment(),
    detailRangeStart: process.env.INITIAL_RANGE_START ? moment(process.env.INITIAL_RANGE_START) : moment().subtract(6, 'hours'),
    detailRangeEnd: process.env.INITIAL_RANGE_END ? moment(process.env.INITIAL_RANGE_END) : moment(),
    // NOTE: This was just a quick way of allowing me to also allow developers to use environment variables in case the data on their machine is stale
    //       and they want to keep refreshing the page without having to then change all the selections afterwards.
    // NOTE: This is quite redundant now as a superior way to achieve this is by creating a share link, which can be refrefreshed over and over again.
    // rangeStart: process.env.INITIAL_RANGE_START ? moment(process.env.INITIAL_RANGE_START) : moment().subtract(34, 'hours'),
    // rangeEnd: process.env.INITIAL_RANGE_END ? moment(process.env.INITIAL_RANGE_END) : moment().subtract(28, 'hours'),
    // rangeStart: process.env.INITIAL_RANGE_START ? moment(process.env.INITIAL_RANGE_START) : moment().subtract(34, 'hours'),
    // rangeEnd: process.env.INITIAL_RANGE_END ? moment(process.env.INITIAL_RANGE_END) : moment().subtract(28, 'hours'),
    journeyId: '',
    event: false,
    shareId: '',
    triggerProblemState: false,
    // OBSERVATION: Dirty - because the graph code started off in test format, the channel information defined below is used for displaying the
    //              initial graphs and graph summary, instead of using information from the database.
    //              Values in database WILL be used for channels that are subsequently added by the user.
    // TODO(DC): We should be using information held within referenceData.channels. It may be that this was attempted but there was some problem
    //           with channels not having initially been populated due to it being populated via an asynchronous API call.
    //           For the moment we will just have to be careful that we have all the information we require and that it matches what is currently
    //           in the database.
    analogueGraphs: [
    {
      graphId: 'A1',
      accepts: channelTypes.ANALOGUE_CHANNEL,
      maxChannels: 4,
      channels: [
        {channelId: 87, name: "Brake Pipe", defaultColor: "#FD92FD", channelType: "Analogue",  color:"", shortName: "BP", unitOfMeasure: "bar"},
        {channelId: 88, name: "Bogie #1", defaultColor: "#4FFC9B", color:"", channelType: "Analogue",  shortName: "BOG1", unitOfMeasure: "bar"},
        {channelId: 89, name: "Bogie #2", defaultColor: "#FD2E2A", color:"", channelType: "Analogue",  shortName: "BOG2", unitOfMeasure: "bar"}
      ]
    },
    {
      graphId: 'A2',
      maxChannels: 4,
      accepts: channelTypes.ANALOGUE_CHANNEL,
      channels: [
        {channelId: 86, name: "Speed", defaultColor: "#6E50EC", color:"", channelType: "Analogue", shortName: "SPD", unitOfMeasure: "mph"}
      ]
    },
    {
      graphId: 'A3',
      maxChannels: 4,
      accepts: channelTypes.ANALOGUE_CHANNEL,
      channels: [
        {channelId: 92, name: "Main Generator Volts", defaultColor: "#0F9A19", color:"", channelType: "Analogue", shortName: "MGV", unitOfMeasure: "V"},
        {channelId: 91, name: "Main Generator Amps", defaultColor: "#FFAE3A", color:"", channelType: "Analogue", shortName: "MGA", unitOfMeasure: "A"}
      ]
    }],
    digitalGraphs: [{
        graphId: 'D1',
        maxChannels: 8,
        accepts: channelTypes.DIGITAL_CHANNEL,
        channels: [
        {
          channelId: 11,
          shortName: "FOR",
          name: "Forward",
          channelType: "Digital",
          unitOfMeasure: "",
          minValue: 0,
          maxValue: 1,
          defaultColor: "#090D43",
        },
        {
          channelId: 12,
          shortName: "REV",
          name: "Reverse",
          channelType: "Digital",
          unitOfMeasure: "",
          minValue: 0,
          maxValue: 1,
          defaultColor: "#905847",
        }],
      }, {
        graphId: 'D2',
        maxChannels: 8,
        accepts: channelTypes.DIGITAL_CHANNEL,
        channels: [],
      }, {
        graphId: 'D3',
        maxChannels: 8,
        accepts: channelTypes.DIGITAL_CHANNEL,
        channels: [],
      }
    ]
  },
  sidebarValues: {
    currentTimestamp: null,
    channelValues: [],
    distance: null
  },
  user: null,
  userSettings: {
    selectedLanguage: 'en-GB',
  },
  preset: {
    saveNew: false,
    saved: false,
    selectedId: '',
    presets:[]
  },
  vehicleGroup: {
    vehicleGroups: [],
    saved: false
  },
  referenceData: {
    vehicles: [],
    // DC: We could add helper methods to fetch the vehicleSelectOptions from vehicles but that probably wouldn't work with the "state change mechanism" that results
    //     in UI updates.
    vehicleSelectOptions: [],
    channels: [],
    channelSelectOptions: [],
    detailResolutionOptions: selectOptions.detailResolutions,
    enabledPresets: [],
    triggerOperators: [],
    triggerEvaluationTypes: [],

    // Test settings.
    languageSelectOptions: [
      {value: 'en-GB', label: 'english'},
      {value: 'it-IT', label: 'italian'},
      {value: 'fr-FR', label: 'french'},
      {value: 'de-DE', label: 'german'},
    ],
  },
  graphConfigurationDialogue: {
    show: false
  },
  exportDialogue: {
    show: false
  },
  shareDialogue: {
    show: false
  },
  triggers: {
    show: false,
    triggers: [],
    saved: false
  },
  resources: {
    'en-GB': {
      addNewPreset: 'Add New Preset',
      addGroup: 'Add Group',
      addStatement: 'Add Statement',
      advancedSearch: 'Advanced Search',
      analogue: 'Analogue',
      and: 'AND',
      andIf: 'and if',
      anErrorOccurredSavingPreset: 'An error has occurred while trying to save your preset.',
      anErrorOccurredSavingTrigger: 'An error has occurred while trying to save your trigger.',
      anErrorOccurredSavingVehicleGroup: 'An error has occurred while trying to save your vehicle group.',
      applyChanges: 'Apply Changes',
      appNameIfm: 'IFM',
      appNameEds: 'EDS',
      averageEvaluationTypeNotice: 'When using an evaluation type of Average duration, please ensure you add another trigger statement with a different evaluation type before it.',
      cancel: 'Cancel',
      channel: 'Channel',
      channels: 'Channels',
      channelData: 'Channel Data',
      clearSelection: 'Clear Selection',
      close: 'Close',
      comparison: 'Comparison',
      confirmTriggerDeletion: 'Removing this Trigger will permanently remove it from the RailSmart RDD system.',
      copyInstruction: 'Copy and paste the link below to share the data that you\'re currently viewing.',
      createdBy: 'Created By',
      createPreset: 'Create Preset',
      createTrigger: 'Create Trigger',
      csv: 'CSV',
      current: 'Current',
      date: 'Date',
      defaultValue: 'Default Value',
      delete: 'Delete',
      deletePreset: 'Delete Preset?',
      deletingPresetWillPermanentlyRemoveIt: 'Deleting a Preset will permanently remove it from the RailSmart RDD system for all users.',
      deleteTrigger: 'Delete Trigger?',
      deleteVehicleGroup: 'Delete Vehicle Group?',
      deleteVehicleGroupDialogText: 'Removing this Vehicle Group will permanently remove it from the RailSmart RDD system for all users.',
      deleteVehicleGroupWithTriggersDialogText: 'This means that it will also be removed from the following triggers.',
      digital: 'Digital',
      disabled: 'Disabled',
      distance: 'Distance',
      distributionLists: 'Distribution Lists',
      edit: 'Edit',
      emailExportTo: 'Email export to:',
      enable: 'Enable',
      enabled: 'Enabled',
      enterWheelDiameterValue: 'Enter Wheel Diameter Value',
      entityNameSuccessfullyDisabledFormat: '{0} successfully disabled',
      entityNameSuccessfullyEnabledFormat: '{0} successfully enabled',
      errorDisablingEntityNameFormat: 'Error disabling {0}',
      errorEnablingEntityNameFormat: 'Error enabling {0}',
      evaluationDuration: 'Evaluation Duration',
      evaluationType: 'Evaluation Type',
      export: 'Export',
      exportFailed: 'Export Failed',
      for: 'for',
      fourZeroFourPageNotFound: '404 Page Not Found',
      fullDateTimeFormat: 'DD/MM/YYYY HH:mm:ss',
      goBackToHomepage: 'Go back to the homepage',
      graph: 'Graph',
      graphTimeoutError: 'The server took too long to complete your request for the following graphs.',
      groupName: 'Group Name',
      if: 'If',
      is: 'is',
      lastModified: 'Last Modified',
      leavePresetEditPageConfirmationText: 'You have made some changes to the preset while on this page. Are you sure you want to leave without saving?',
      leaveVehicleGroupEditPageConfirmationText: 'You have made some changes to the vehicle group while on this page. Are you sure you want to leave without saving?',
      leaveTriggerEditPageConfirmationText: 'You have made some changes to the trigger while on this page. Are you sure you want to leave without saving?',
      logStart: 'Log Start',
      logEnd: 'Log End',
      locoNumber: 'Loco Number',
      locos: 'Locos',
      managePresets: 'Manage Presets',
      millimeters: 'mm',
      modifiedBy: 'Modified By',
      name: 'Name',
      noDataForThisPeriodOfTime: 'There is no data available for this period of time.',
      noKeepPreset: 'No, Keep Preset',
      noKeepTrigger: 'No, Keep Trigger',
      noKeepVehicleGroup: 'No, Keep Vehicle Group',
      numberOfLocos: 'Number of Locos',
      numVehiclesAssigned: 'No. Vehicles', // 'No. Vehicles Assigned',
      of: 'of',
      off: 'Off',
      ok: 'OK',
      on: 'On',
      operator: 'Operator',
      options: 'Options',
      pdfExportText: 'Please wait while your export is being created.',
      pleaseAdjustTimeRangeTryAgain: 'Please adjust the time range you are viewing and try again.',
      pleaseEnterValue: 'Please enter a value',
      pleaseEnterValidEmailAddress: 'Please enter a valid email address',
      pleaseEnterValueOffOrOn: 'Please enter a value which is either 0 (off) or 1 (on)',
      pleasePickSomethingAlongSideAnAverage: 'Please pick something alongside an average',
      pleaseSelect: 'Please Select',
      pleaseSelectValidDateRange: 'Please select a valid date range',
      pleaseTryAgainInFewMinutes: 'Please try again in a few minutes.',
      preset: 'Preset',
      presetCreateError: 'An error has occurred while trying to create your preset.',
      presetFailed: 'Preset Failed',
      presetFailedToDelete: 'Preset failed to delete',
      presetName: 'Preset Name',
      presetSuccessfullyDeleted: 'Preset successfully deleted',
      presetSuccessfullySaved: 'Preset successfully saved',
      presets: 'Presets',
      presetsFailedToLoad: 'Presets failed to load. Please try again in a few minutes.',
      rawData: 'Raw Data',
      resultsFound: 'results found',
      returnGraphToDefaultSettings: 'Return Graph to Default Settings',
      requestTimedOut: 'Request Timed Out',
      saveAndApplyChanges: 'Save and Apply Changes',
      saveAsPreset: 'Save as Preset',
      save: 'Save',
      saveFailed: 'Save Failed',
      search: 'Search',
      searchForInstancesWhere: 'Search for instances where:',
      seconds: 'seconds',
      selectTwentyFourHourPeriodToExport: 'Select a 24 hour period to export:',
      selected: 'selected',
      selectedLocos: 'Selected Locos',
      selectedTriggers: 'Selected Triggers',
      setDataChannels: 'Set Data Channels',
      shareSettingsLinkError: 'An error has occurred while trying to create a shareable link.',
      signIntoRddWith: 'Sign into RDD with',
      startTime: 'Start Time',
      status: 'Status',
      submit: 'Submit',
      success: 'Success!',
      successfullyCreatedPreset: 'You have successfully created a preset.',
      summary: 'Summary',
      thisActionCannotBeUndone: 'This action cannot be undone.',
      triggerConditionValueValidationMessage: 'is a digital channel.  Please enter a value which is either 0 (off) or 1 (on).',
      triggerFailedToDelete: 'Trigger failed to delete',
      triggers: 'Triggers',
      triggerNameExistsErrorMessage: 'A trigger with this name already exists. Please use a unique name.',
      triggerStatement: 'Trigger Statement',
      triggerStarted: 'Trigger Started',
      triggerSuccessfullySaved: 'Trigger successfully saved',
      triggerSuccessfullyDeleted: "Trigger successfully deleted",
      triggerEnded: 'Trigger Ended',
      triggerValue: 'Trigger Value',
      updateGraphs: 'Update Graphs',
      useRecordedWheelDiameterValue: 'Use Recorded Wheel Diameter Value',
      validationDateRangeInPast: 'Please select a date range which is in the past',
      validationDateRangeLimit: 'Please select a date range which is under 3 weeks',
      validationEndDateFormat: 'Please select a valid end date',
      validationEndTimeFormat: 'Please enter an end time in the format hh:mm',
      validationStartDateBeforeEndDate: 'Please select a start date which is before the end date',
      validationStartDateFormat: 'Please select a valid start date',
      validationStartTimeFormat: 'Please enter a start time in the format hh:mm',
      value: 'Value',
      vehicle: 'Vehicle',
      vehicleCode: 'Vehicle Code',
      vehicleGroup: 'Vehicle Group',
      vehicleGroupFailedToDelete: 'Vehicle Group failed to delete',
      vehicleGroupSuccessfullyDeleted: 'Vehicle Group successfully deleted',
      vehicleGroupSuccessfullySaved: 'Vehicle Group successfully saved',
      vehicleGroupNameExistsErrorMessage: 'A vehicle group with this name already exists. Please use a unique name.',
      vehicleGroups: 'Vehicle Groups',
      vehicleGroupsFailedToLoad: 'Vehicle Groups failed to load. Please try again in a few minutes.',
      vehicleInvalidError: 'No vehicle with the following code exists. Please use a valid code.',
      vehicleNotFound: 'Vehicle Not Found',
      vehicles: 'Vehicles',
      vehiclesFailedToLoad: 'Vehicles failed to load. Please try again in a few minutes.',
      view: 'View',
      viewOnGraph: 'View on Graph',
      viewOnMap: 'View on Map',
      wheelDiameter: 'Wheel Diameter',
      wheelDiameterAtLogStart: 'Wheel Diameter at Log Start',
      wheelDiamaterManualChangeNote: 'This will not be a permanent change and does not affect the recorded wheel diameter value stored in the database.',
      youCanNowApplyThisPreset: 'You can now apply this preset to the graphs by selecting it from the drop-down menu at the top right-hand side of the screen.',
      yesDeletePreset: 'Yes, Delete Preset',
      yesDeleteTrigger: 'Yes, Delete Trigger',
      yesDeleteVehicleGroup: 'Yes, Delete Vehicle Group',
      // The properties in here match the members of detailResolutionValues.
      zoomLevelLabels: {
        'OneSecond': 'Raw data',
        'FiveSeconds': '5 seconds',
        'TenSeconds': '10 seconds',
        'ThirtySeconds': '30 seconds',
        'OneMinute': '1 minute',
        'FiveMinutes': '5 minutes',
        'TenMinutes': '10 minutes',
        'ThirtyMinutes': '30 minutes',
        'OneHour': '1 hour',
      },

      // These resources are only used in test components.
      selectTheDataToBeDisplayed: 'Select the data to be displayed:',
      displayInDigitalMode: 'Display in Digital Mode (you will need to refetch the points)',
      boiledEgg: 'Boiled egg',
      bread: 'bread',
      butter: 'butter',
      question: 'I\'d like {0} and {1}, or just {0}',
      english: 'English',
      italian: 'Italian',
      french: 'French',
      german: 'German',
    },
    'it-IT': {
      selectTheDataToBeDisplayed: 'Seleziona i dati da visualizzare:',
      displayInDigitalMode: 'Visualizza in modalità digitale (dovrai recuperare i punti)',
      boiledEgg: 'Uovo sodo',
      butter: 'burro',
      english: 'Inglese',
      italian: 'Italiano',
      french: 'Francese',
      german: 'Tedesco',
    },
    'fr-FR': {},
    'de-DE': {},
  },
};
