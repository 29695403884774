import React from 'react';
import {PropTypes} from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'styles/datepicker.scss';

class DateTimePicker extends React.Component {
  static propTypes = {
    datePickerTitle: PropTypes.string.isRequired,
    updateDateCallback: PropTypes.func.isRequired,
    date: PropTypes.object.isRequired,
    classNames: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      date: moment(this.props.date),
      validDate: true
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.date.valueOf() !== prevProps.date.valueOf()) {
      this.setState({
        date: moment(this.props.date),
        validDate: true,
      });
    }
  }

  updateParentDate = () => {
    this.props.updateDateCallback(this.state.date, this.state.validDate);
  }

  dateChangeEvent = (date) => {
    this.setState({
      date: moment(this.state.date).set({
        date: date.get('date'),
        month: date.get('month'), 
        year: date.get('year')
      }),
      validDate: true
    }, () => {
      this.updateParentDate();
    });
  };

  handleRawDateChange = (rawDate) => {
    // Formats need to be configurable
    var momentdate = moment(rawDate, "DD-MM-YYYY");
    if (momentdate.isValid()){
      this.dateChangeEvent(momentdate);
    } else {
      this.setState({
        validDate: false
      }, () => {
        this.updateParentDate();
      });
    }
  };

  timeChangeEvent = (date) => {
    this.setState({
      date: moment(this.state.date).set({
        hour: date.get('hour'),
        minute: date.get('minute')
      }),
      validDate: true
    }, () => {
      this.updateParentDate();
    });
  };
  
  handleRawTimeChange = (event) => {
    // Formats need to be configurable
    var momentdate = moment(event.target.value, "HH:mm", true);
    if (momentdate.isValid()) {
      this.timeChangeEvent(momentdate);
    } else {
      this.setState({
        validDate: false
      }, () => {
        this.updateParentDate();
      });
    }
  };

  render() {
    return(
      // Formats need to be configurable
      <div className={"datepicker-wrapper " + this.props.classNames}>
        <span className="label">{this.props.datePickerTitle}</span>
        <DatePicker
          className="datepicker margin-right"
          selected={this.state.date}
          onChange={this.dateChangeEvent}
          onChangeRaw={this.handleRawDateChange}
          maxDate={moment()}
          locale="en-gb"
        />
        <DatePicker
          className="timepicker margin-right"
          selected={this.state.date}
          onChange={this.timeChangeEvent}
          onChangeRaw={this.handleRawTimeChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeFormat="HH:mm"
          dateFormat="HH:mm"
          timeCaption="Time"
          locale="en-gb"
        />
        <span className={"message message--error margin-right" + (!this.state.validDate ? ' block' : '')}>Please select a valid date</span>
      </div>
    );
  }
}
export default DateTimePicker;
