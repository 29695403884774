import * as types from 'constants/actionTypes';

export function updateSearchPoints(advancedSearchPoints) {
  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_GRAPH_SETTINGS,
      newSettings: {advancedSearchPoints}
    });
  };
}

export function removeSearchPoints() {
  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_GRAPH_SETTINGS,
      newSettings: {advancedSearchPoints: []}
    });
  };
}