import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import DialogueTitle from 'components/DialogueTitle';
import DialogueButtons from 'components/DialogueButtons';
import Resources from 'utilities/Resources';
import 'styles/exports.scss';

class SavePresetDialogueCompletedContent extends React.Component {
  static propTypes = {
    response: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
  };

  close = () => {
    this.props.onClose();
  };

  render() {
    const resources = Resources.localizedString;
    return (
      <React.Fragment>
        <DialogueTitle title={!this.props.response.error ? resources.success : resources.presetFailed} />
        <div className="padded margin-vertical">
          <div className="padded margin-vertical text-center">
          {!this.props.response.error ? (
            <React.Fragment>
              <span className="status-icon status-icon--success margin-bottom"></span>
              <p className="margin-top">{resources.successfullyCreatedPreset}</p>
              <p>{resources.youCanNowApplyThisPreset}</p>
            </React.Fragment>
           ) : (
            <React.Fragment>
              <span className="status-icon status-icon--error margin-bottom"></span>
              <p className="margin-top">{resources.presetCreateError}</p>
              <p>{resources.pleaseTryAgainInFewMinutes}</p>
            </React.Fragment>
           )}
          </div>
        </div>
        <DialogueButtons
          showCancelButton={false}
          confirmFunction={this.close}
          confirmButtonText={resources.ok} />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
}
export {SavePresetDialogueCompletedContent as SavePresetDialogueCompletedContentNoRedux};

const ConnectedSavePresetDialogueCompletedContent = connect(mapStateToProps, mapDispatchToProps)(SavePresetDialogueCompletedContent);
export default ConnectedSavePresetDialogueCompletedContent;