// prop-types has been included as a devDependency but that is OK for us as we are not creating a library to be consumed by others.
import {PropTypes} from 'prop-types';
import Dygraph from 'utilities/dygraphHairlines';
import moment from 'moment';
import * as R from 'ramda';
import $ from 'jquery';
import graphDataApi from 'api/graphDataApi';
import aggregations from 'utilities/graphAggregations';
import queryStrings from 'utilities/queryStringUtilities';
import {getChannelColour, getChannelByShortName, copy} from 'utilities/graphSettings';
import * as hairlineTypes from 'constants/hairlineTypes';
import * as channelValueTypes from 'constants/channelValueTypes';
import {addToDygraphsArray, checkIfInDygraphsArray, removeFromDygraphsArray, redraw, underlayFunctions, mouseDown, mouseMove, mouseUp, generateSomeInitialChartData, getHairlinesByType} from 'utilities/dygraphsFunctions';
import {spliceRangeAndDetail} from 'utilities/graphZoom.js';
import * as distanceUtils from 'utilities/distanceUtilities'
import functions from 'utilities/functionUtilities';
import Resources from 'utilities/Resources';
import * as channelTypes from 'constants/channelTypes';
import * as digitalGraphSettings from 'constants/digitalGraphSettings';

//
// Contains code that is common to both <DigitalGraph> and <AnalogueGraph>.
//
// First the "common" object is declared and has properties and methods assigned to it.
// Those were copied across from the one of the individual graph components, with "this" intended to refer to the graph component.
//
// Towards the bottom of the file, the exported "graphComponentsCommon" is declared.
// Its generateBoundCommon() method will be called from the graph components and takes all the members of "common" and assigns them to "graphComponentsCommon",
//    also ensuring that "this" is bound to the specified parameter, which will be an instance of a graph component.
// The object does have a few directly-assigned properties and methods. This might have been a later oversight and most should have been assigned to "common"
//    instead but it doesn't do any harm as the meaning of "this" in those methods is not important.
//
// Any new methods where "this" needs to be bound to component itself should be assigned to "common".
//

const qs = queryStrings.parseFromCurrentLocation();
const common = {
  displayUtcTimes: qs.utctime,
};

common.getUnixTimeStamp = common.displayUtcTimes
                          ? rmdDateTime => moment.utc(rmdDateTime).utc().valueOf()
                          : rmdDateTime => moment.utc(rmdDateTime).local().valueOf()

//
// The code in these common methods should exactly match the code as it was when it was in the graph components so as to make their transfer into here as easy
// and as least error-prone as possible.
//
// The above means that, in any methods of common, "this" will refer to the graph component that the method is being used in.
//
// IMPORTANT: Define the methods using conventional "function" syntax so that "this" can be set via call, apply or bind.
//            DO NOT USE arrow function syntax as the meaning of "this" cannot be changed (arrow functions do not have their own this).
//

common.setPropertiesOfThis = function () {
  this.graphContainerId = "#graphdiv-" + this.props.graphId;
  this.isRangeSelectorActive = false;
  this.mouseDownElement = null;
  this.clickCount = 0;
  this.timer = null;
  this.hairlines = this.common.generateDygraphsHairlines();

  // DC: Is this.chartData now obsolete? The property is not now assigned to the file property of the Dygaph in getDataPointsFromApi()
  // FOR THE MOMENT: Not calling this is desirable but will currently result in at least one property used further along the chain not having a defined value,
  //                 and therefore a number of console errors and no contenet.
  this.chartData = generateSomeInitialChartData();
};

// http://dygraphs.com/options.html
common.getCommonDygraphOptions = function () {
  return {
    showLabelsOnHighlight: false,
    width: process.env.INITIAL_GRAPH_WIDTH || 1224,
    drawPoints: true,
    showRoller: false,
    labels: ['Time', 'Value'],
    connectSeparatedPoints: true,
    showRangeSelector: true,
    title: `${Resources.localizedString.graph} ${this.props.graphId}`,
    // true when hen showing Min, Value, Max.
    customBars: true,
    // Using this, even for analogue graphs, as diagonal lines joining two points look deceptive, in that the only points on the line that are real readings are the
    // two points it is joining.
    stepPlot: true,
    interactionModel: {
      'mousedown': mouseDown,
      'mousemove': mouseMove,
      'mouseup': mouseUp,
      'dblclick' : graphComponentsCommon.defaultDblclick,
    },
    plugins: [
      this.hairlines
    ],
    axes: {
      y: {
        axisLabelWidth: 70,
      }
    },
    drawCallback: redraw,
    clickCallback: this.common.clickCallback,
    highlightCallback: this.props.actions.updateSidebarValues,
    unhighlightCallback: this.props.actions.removeSidebarValues,
    zoomCallback: this.common.zoom,
  };
};

common.componentDidMount = async function ({isDigitalGraph}) {
  this.common.initialiseDygraph(isDigitalGraph);

  const qs = queryStrings.parseFromCurrentLocation();
  // Event, Trigger, Share, Journey is in query string and also loaded into the graph settings
  const eventReturnedFromApi = qs.eventid && this.props.graphSettings.event;
  const journeyReturnedFromApi = qs.journeyid && this.props.graphSettings.journeyId;
  const triggerProblemStateReturnedFromApi = qs.triggerproblemstateid && this.props.graphSettings.triggerProblemState;
  const shareSettingsReturnedFromApi = qs.shareid && this.props.graphSettings.shareId;

  // Needed to add the extra OR conditions to allow new graph components to load existing events, triggers etc. and new graph is added
  if (!qs.journeyid && !qs.eventid && !qs.triggerproblemstateid && !qs.shareid ||
      eventReturnedFromApi || journeyReturnedFromApi || triggerProblemStateReturnedFromApi || shareSettingsReturnedFromApi) {
    // REMOVED THIS CALL: The updated graph range was not setting the detail, resolution and detail dates correctly.
    //              Neither were the sharing, events, triggers etc. They now are, so the below call was commented out.
    // OBSERVATION: The asynchronous nature of the way the redux store doesn't seem to result in getDataPointsFromApi() operating with the previous store value of
    //              detailResolution. Maybe react-redux ensures that the thunk which the action method returns is called and ensure sthat the redux store has been
    //              updated before this.props.graphSettings is accessed.
    // HOWEVER: I am not sure if there is the odd occasion where the detailResolution may not have been changed (I changed the initialState value to OneHour).
    //          Wiil keep observing. A quick solution is to change the value in initialState back to FiveSeconds. This will be OK as long the default range
    //          when the page is first loaded is 6 hours.
    // this.props.actions.updateRangeDetailSettings(false, moment(this.props.graphSettings.rangeStart), moment(this.props.graphSettings.rangeEnd));
    if (!this.props.graphSettings.detailQuery) {
      await this.common.updateGraphData(this.props.graphSettings.rangeStart, this.props.graphSettings.rangeEnd); // Use this one to get data from the API straight away.
    } else {
      await this.common.updateGraphData(this.props.graphSettings.detailRangeStart, this.props.graphSettings.detailRangeEnd); // Use this one to get data from the API straight away.
    }
  }

  this.common.bindRangeSelectorMouseDown();
  this.common.bindRangeSelectorMouseUp();

  if (this.props.graphSettings.event) {
    this.common.addEventHairline(this.props.graphSettings.event);
  }

  if (this.props.graphSettings.triggerProblemState) {
    this.common.addTriggerHairline(this.props.graphSettings.triggerProblemState);
  }

  this.common.addAllDistanceHairlines();

  // Store reference to this as scope of this changes to hairlines inside function
  const _this = this;
  $(this.hairlines).on('hairlineDeleted', function (e, hairline, graph) {
    if (hairline.type === hairlineTypes.ADVANCED_SEARCH_HAIRLINE && _this.dygraph === graph) {
      const allGraphSearchPoints = [..._this.dygraph.advancedSearchPoints];
      const allPoints = [..._this.props.graphSettings.advancedSearchPoints];

      // Get graph search points at exact same time => these are the ones to remove
      let graphPointsAtSameTime = allGraphSearchPoints.filter(p => p.xval === hairline.xval);

      graphPointsAtSameTime.map((graphPoint) => {
        const point = {
          xval: graphPoint.xval,
          channelId: graphPoint.channelId
        };

        const graphPointIndex = R.indexOf(point, allGraphSearchPoints);
        if (graphPointIndex != -1) {
          allGraphSearchPoints.splice(graphPointIndex, 1);
        }

        const pointsIndex = R.indexOf(point, allPoints);
        if (pointsIndex != -1) {
          allPoints.splice(pointsIndex, 1);
        }
      })

      _this.props.actions.updateSearchPoints(allPoints);
      _this.dygraph.advancedSearchPoints = allGraphSearchPoints;
    }

    if (hairline.type === hairlineTypes.DISTANCE_HAIRLINE) {
      _this.props.actions.deleteDistanceHairline(hairline.xval);
    }
  });

  $(this.hairlines).on('hairlineCreated', function (e, hairline, graph, xval) {
  });

  $(this.hairlines).on('hairlineMoved', function (e, hairline, graph, oldXval, newXval) {
    if (hairline.type === hairlineTypes.DISTANCE_HAIRLINE && _this.dygraph === graph) {
      _this.props.actions.moveDistanceHairline(oldXval, newXval);
    }
  });

  $(this.hairlines).on('hairlinesChanged', function (e) {
  });
};

common.initialiseDygraph = function (isDigitalGraph) {
  this.dygraph = new Dygraph(
    document.getElementById(`graphdiv-${this.props.graphId}`),
    this.chartData,
    this.dygraphOptions);
  this.dygraph.graphId = this.props.graphId;
  this.dygraph.channels = this.state.graph.channels;
  this.dygraph.isDigital = isDigitalGraph;
  addToDygraphsArray(this.dygraph);
};

common.bindRangeSelectorMouseDown = function () {
  const rangeMouseElements = document.querySelectorAll(this.graphContainerId + ' .dygraph-rangesel-fgcanvas, ' + this.graphContainerId + ' .dygraph-rangesel-zoomhandle');
  rangeMouseElements.forEach((element) => {
    element.addEventListener("mousedown", (e) => {
    // element.addEventListener("mousedown", functions.debounce((e) => {
      this.isRangeSelectorActive = true;
      this.mouseDownElement = e.target;
    });
    // }));
  });
};

common.bindRangeSelectorMouseUp = function () {
  const mouseUpElement = document.getElementById("graphdiv-" + this.props.graphId);
  if (mouseUpElement) {
    mouseUpElement.addEventListener("mouseup", functions.debounce(() => {
      this.isRangeSelectorActive = false;
      if (this.dygraph.isZoomed('x') && this.mouseDownElement !== null) {
        const graphAxisX = this.dygraph.xAxisRange();
        // Load new detail data
        this.props.actions.updateRangeDetailSettings(true, moment(graphAxisX[0]), moment(graphAxisX[1]));
      }
      this.mouseDownElement = null;
    }));
  }
};

common.componentDidUpdate = function (prevProps) {
  const graphSettingsChanged = JSON.stringify(this.props.graphSettings) !== JSON.stringify(prevProps.graphSettings);

  if (graphSettingsChanged || this.props.graphHairlines !== prevProps.graphHairlines) {
    if (this.props.graphSettings.event !== prevProps.graphSettings.event && this.props.graphSettings.event) {
      this.common.resetHairlines(hairlineTypes.EVENT_HAIRLINE);
      this.common.addEventHairline(this.props.graphSettings.event);
    }

    if (this.props.graphSettings.triggerProblemState !== prevProps.graphSettings.triggerProblemState && this.props.graphSettings.triggerProblemState) {
      this.common.resetHairlines(hairlineTypes.TRIGGER_HAIRLINE);
      this.common.addTriggerHairline(this.props.graphSettings.triggerProblemState);
    }

    if (JSON.stringify(this.props.graphHairlines.distanceHairlines) !== JSON.stringify(prevProps.graphHairlines.distanceHairlines)) {
      this.common.resetHairlines(hairlineTypes.DISTANCE_HAIRLINE);
      this.common.addAllDistanceHairlines();
    }

    if (JSON.stringify(this.props.graphSettings.advancedSearchPoints) !== JSON.stringify(prevProps.graphSettings.advancedSearchPoints)) {
      this.common.resetHairlines(hairlineTypes.ADVANCED_SEARCH_HAIRLINE);
      this.common.addAdvancedSearchHairlines(this.props.graphSettings.advancedSearchPoints);
      return;
    }

    this.setState({
      graphSettings: this.props.graphSettings,
      graph: R.find(R.propEq('graphId', this.props.graphId))([...this.props.graphSettings.digitalGraphs, ...this.props.graphSettings.analogueGraphs])
    }, async () => {
      if (graphSettingsChanged) {
        // Change API request dates based on range or detail query
        if (this.props.graphSettings.detailQuery === true) {
          await this.common.updateGraphData(this.props.graphSettings.detailRangeStart, this.props.graphSettings.detailRangeEnd);
        } else {
          await this.common.updateGraphData(this.props.graphSettings.rangeStart, this.props.graphSettings.rangeEnd);
        }
      }
    });
  }

  // DC (17-3-2021): I can't remember if this was already commented out. I see no reason why we should reset to the range request if we have a detail interval
  //                 selected and just want to change the channels. However, I have left it in case any complaints are made.
  //                 Note that I have had to move it from just above the "this.setState()" to here to make it work.
  // Reset the range request, as the graph channels have changed.
  // // if (this.props.graphSettings.analogueGraphs !== prevProps.graphSettings.analogueGraphs ||
  // //      this.props.graphSettings.digitalGraphs !== prevProps.graphSettings.digitalGraphs) {
  // //   this.props.actions.updateRangeDetailSettings(false, moment(this.props.graphSettings.rangeStart), moment(this.props.graphSettings.rangeEnd));
  // //   return;
  // // }

  if (this.props.clearChannelHairlines) {
    this.common.resetHairlines(hairlineTypes.CHANNEL_HAIRLINE);
  }
};

common.componentWillUnmount = function () {
  removeFromDygraphsArray(this.dygraph);
};

common.updateGraphData = async function (startDateTime, endDateTime) {
  document.getElementById(this.graphContainerId + "-loading").style.display = "block";
  await this.getDataPointsFromApi(startDateTime, endDateTime);
  let graphLoadingPanel = document.getElementById(this.graphContainerId + "-loading");
  if (graphLoadingPanel) {
    graphLoadingPanel.style.display = "none";
  }
};

common.requestAndProcessDataPointsFromApi = async function (startDateTime, endDateTime, detailResolution) {
  const request = {
    vehicleCode: this.props.graphSettings.selectedVehicleCode,
    channelIds: R.pluck('channelId')(this.state.graph.channels),
    detailStartDatetime: startDateTime.toISOString(),
    detailEndDatetime: endDateTime.toISOString(),
    detailResolution,
    userAdjustedWheelDiameterMillimetres: this.props.graphSettings.userAdjustedWheelDiameterMillimetres,
  };

  let data = [];
  let api = {};
  let requestHasData = true;

  if (this.dygraph) {
    // DC: The lowest level data is now being fetched for digital graphs, no matter what granularity of data
    //     is being fetched for analogue graphs. Therefore it is not necessary to fetch any more data from
    //     the API if the user zooms in.
    if (this.dygraph.isDigital && this.props.graphSettings.detailQuery) {
      data = this.dygraph.range;
      // AT: Checks to see if the request coming in is the range query we store against the dygraph object
      //     so we don't have to make multiple api requests.
      // DC: The original condition must have been broken as the conditions were comparing a string with
      //     a complex object when I came to it. I fixed the problem to an extent so AT's comment is now
      //     correct again. However, I have to leave it commented out since the one problem it causes is,
      //     if new new channels are added to an existing  graph, either via the "Set Data Channels" dialogue
      //     or by selecting a different preset, data for the new channels is not fetched.
      // } else if (moment(startDateTime).utc().toISOString() === this.dygraph.originalRequestStart &&
      //              moment(endDateTime).utc().toISOString() === this.dygraph.originalRequestEnd) {
      //   data = this.dygraph.range;
      if (data) {
        const dataBetweenDates = data.filter(dp => moment(dp.rmdDateTime).valueOf() > startDateTime.valueOf() &&
                                                   moment(dp.rmdDateTime).valueOf() < endDateTime.valueOf());
        // Logic to check for points between the dates to show if we have data
        requestHasData = dataBetweenDates.length > 0;
      }
    }
    else {
      try {
        api = await graphDataApi.getDataPointsForChannels(request);
        data = api.data;
        if (api.status === 204) {
          requestHasData = false;
        }
      }
      catch (error) {
        api.error = error;
        if (api.error.response && api.error.response.status === 404) {
          this.common.handleVehicleDoesntExistError();
        }
        else {
          this.common.handleGetDataPointsFromApiError(error);
        }
      }
    }
  }

  let latLongData = [];

  // Converts time from utc to local unix millisecond
  if (data && data.length > 0) {
    data.forEach(point => {
      point.rmdDateTime = common.getUnixTimeStamp(point.rmdDateTime);
      if (point.points.length > 0) {
        latLongData.push({rmdDateTime: point.rmdDateTime, latitude: point.points[0].latitude, longitude: point.points[0].longitude});
      }
    });
  }
  else {
    data = [];
    data.push({rmdDateTime: startDateTime.valueOf(), points: []});
    data.push({rmdDateTime: endDateTime.valueOf(), points: []});
  }

  // Splices detail query API response into the original range window
  if (this.props.graphSettings.detailQuery) {
    if (this.dygraph.range) {
      data = [...spliceRangeAndDetail([...this.dygraph.range], [...data], startDateTime.valueOf(), endDateTime.valueOf())];
    }
    if (this.dygraph.latLongData) {
      latLongData = [...spliceRangeAndDetail([...this.dygraph.latLongData], [...latLongData], startDateTime.valueOf(), endDateTime.valueOf())];
    }
  }

  // dygraph.latLongData is used by getHairlineLatitudeLongitude() to generate "View on Map" links for hairlines.
  this.dygraph.latLongData = latLongData;

  let windowStartDate = this.dygraph.originalRequestStart;
  let windowEndDate = this.dygraph.originalRequestEnd;

  if (!windowStartDate || !windowEndDate || !this.props.graphSettings.detailQuery ) {
    if (!this.props.graphSettings.detailQuery) {
      windowStartDate = request.detailStartDatetime;
      windowEndDate = request.detailEndDatetime;
    }
    else {
      windowStartDate = this.props.graphSettings.rangeStart.toISOString();
      windowEndDate = this.props.graphSettings.rangeEnd.toISOString();
    }
  }

  const rawChartData = [...data];

  const transformToValueMinMax = aggregations.transformToValueMinMaxFromChannels(request.channelIds);
  const channelsChartData = transformToValueMinMax(data);
  const chartMissingStartDataInterval = aggregations.addDummyDataPointForStartTimeIfNotThere(channelsChartData, windowStartDate);
  const chartMissingEndDataInterval = aggregations.addDummyDataPointForEndTimeIfNotThere(channelsChartData, windowEndDate);

  return [request, channelsChartData, rawChartData, chartMissingStartDataInterval, chartMissingEndDataInterval, requestHasData];
};

common.updateDygraphAfterSuccessfullyFetchingDataPoints = function (request, data, dygraphOptionsToUpdate) {
  if (this.dygraph && checkIfInDygraphsArray(this.dygraph)) {
    // Stores range query each time the range start/end, or set of graph channels changes (Adam's comment, not mine).
    if (this.props.graphSettings.detailQuery === false) {
      this.dygraph.range = data;
      this.dygraph.originalRequestStart = request.detailStartDatetime;
      this.dygraph.originalRequestEnd = request.detailEndDatetime;
    }
    this.dygraph.channels = this.state.graph.channels;
    this.dygraph.updateOptions(dygraphOptionsToUpdate);

    if (this.dygraph.isDigital) {
      let height = Math.round((this.state.graph.channels.length * 35) + 120);
      ////let height = Math.round((this.state.graph.channels.length * 60) + 80);    // Original height calculation.
      if (height < digitalGraphSettings.DIGITAL_GRAPH_MIN_HEIGHT) {
        height = digitalGraphSettings.DIGITAL_GRAPH_MIN_HEIGHT;
      }
      this.dygraph.height = height;
      document.getElementById("graphdiv-" + this.props.graphId).style.height = this.dygraph.height + "px";
      this.dygraph.resize();
    }

    this.props.actions.updateSidebarValues(null, null, null, null);
  }
};

common.handleVehicleDoesntExistError = function () {
  // Shows error dialogue with associated erroring graphs
  this.props.showVehicleDoesntExistError();
};

common.handleGetDataPointsFromApiError = function (error) {
  // Shows error dialogue with associated erroring graphs
  this.props.showGraphDataFetchingError(this.props.graphId);
};

common.zoom = function (minDate, maxDate) {
  if (!this.dygraph) {
    return;
  }

  // Don't mke Api call if zooming on y axis
  if (this.dygraph.isZoomed('y')) {
    return;
  }

  //When zoom reset via double-click, there is no mouse-up event in chrome (maybe a bug?),
  //so we initiate data load directly
  if (!this.dygraph.isZoomed('x')) {
    this.props.actions.updateRangeDetailSettings(false, moment(this.props.graphSettings.rangeStart), moment(this.props.graphSettings.rangeEnd));
    return;
  }

  //The zoom callback is called when zooming via mouse drag on graph area, as well as when
  //dragging the range selector bars. We only want to initiate dataload when mouse-drag zooming. The mouse
  //up handler takes care of loading data when dragging range selector bars.
  var doDataLoad = !this.isRangeSelectorActive;
  if (doDataLoad === true) {
    this.props.actions.updateRangeDetailSettings(true, moment(minDate), moment(maxDate));
  }
};

common.generateDygraphsHairlines = function () {
  const hairlines = new Dygraph.Plugins.Hairlines({
    divFiller: function (div, data) {
      // This behavior is identical to what you'd get if you didn't set
      // this option. It illustrates how to write a 'divFiller'.
      let html = '';
      if (data.hairline.type == hairlineTypes.DISTANCE_HAIRLINE) {
        html = graphComponentsCommon.generateDistanceHairlineHtml(data, this.get(), this.dygraph_);
      } else if (data.hairline.type == hairlineTypes.EVENT_HAIRLINE) {
        html = graphComponentsCommon.generateEventHairlineHtml(data, this.dygraph_);
      } else if (data.hairline.type == hairlineTypes.TRIGGER_HAIRLINE) {
        html = graphComponentsCommon.generateTriggerHairlineHtml(data, this.dygraph_);
      } else if (data.hairline.type == hairlineTypes.ADVANCED_SEARCH_HAIRLINE) {
        html = graphComponentsCommon.generateAdvancedSearchHairlineHtml(data, this.dygraph_);
      } else {
        html = graphComponentsCommon.generateHairlineHtml(data, this.dygraph_);
      }
      $('.hairline-legend', div).html(html);
      $(div).data({xval: data.hairline.xval});  // see .hover() below.
    }
  });

  return hairlines;
};

common.clickCallback = function (e, x, pts) {
  const CLICK_DELAY_MS = 300;
  this.clickCount++;

  if (this.timer) {
    // Another click is in progress; ignore this one.
    return;
  }

  let h = this.hairlines.get();

  this.timer = setTimeout(() => {
    this.timer = null;
    if (this.clickCount < 2) {
      const xval = this.dygraph.toDataXCoord(e.offsetX);
      // ORIGINAL: They can only add 2 max. We may as well allow them to add as many distance hairlines as they want. Adam's existing code caters for this
      //           already, with the earliest hairline being used as the starting point for all the others.
      // const distanceHairlines = getHairlinesByType(hairlineTypes.DISTANCE_HAIRLINE)(h);
      // if (addingDistanceHairlines && distanceHairlines.length < 2) {
      if (this.props.clickingAddsDistanceHairline) {
        this.props.actions.addDistanceHairline(xval);
      }
      else if (this.props.clickingAddsChannelHairline) {
        h.push({xval: x, type: hairlineTypes.CHANNEL_HAIRLINE});
        this.hairlines.set(h);
        this.props.turnOffChannelHairlines();
      }
      // The user will always want to add at least two distance hairlines. If they want add more, they will have to click the button again.
      // ORIGINAL: Queried the distance hairlines stored against the graph, but we can count how many there are in the redux store.
      // if (h.filter(dh => dh.type == hairlineTypes.DISTANCE_HAIRLINE).length == 2) {
      if (this.props.graphHairlines.distanceHairlines.length >= 2) {
        this.props.turnOffDistanceHairlines();
      }
    }
    this.clickCount = 0;
    this.setState(this.state);
  }, CLICK_DELAY_MS);
};

common.addEventHairline = function (event) {
  this.dygraph.event = event;
  let h = this.hairlines.get();
  const xval = common.getUnixTimeStamp(event.rmdTimeStamp);
  h.push({xval: xval, type: hairlineTypes.EVENT_HAIRLINE});
  this.hairlines.set(h);
}

common.addDistanceHairline = function (xval) {
  let h = this.hairlines.get();
  h.push({xval: xval, type: hairlineTypes.DISTANCE_HAIRLINE});
  this.hairlines.set(h);
}

common.addAllDistanceHairlines = function () {
  for (const distanceHairline of this.props.graphHairlines.distanceHairlines) {
    this.common.addDistanceHairline(distanceHairline.xval);
  }
}

common.addTriggerHairline = function (triggerProblemState) {
  this.dygraph.triggerProblemState = triggerProblemState;
  let h = this.hairlines.get();

  const xval = common.getUnixTimeStamp(triggerProblemState.startDateTime);
  h.push({xval: xval, type: hairlineTypes.TRIGGER_HAIRLINE});

  this.hairlines.set(h);
}

common.addAdvancedSearchHairlines = function (searchPoints) {
  const channels = this.dygraph.channels;
  let h = this.hairlines.get();
  let graphSearchHairlines = [];

  if (searchPoints.length > 0) {
    searchPoints.map((searchPoint) => {
      const channel = channels.filter(c => c.channelId === searchPoint.channelId);
      // check to see if xval is already used for another search hairline
      const hairlinesAtSamePoint = h.filter(h => h.xval === searchPoint.xval && h.type == hairlineTypes.ADVANCED_SEARCH_HAIRLINE);
      if (channel.length > 0) {
        if (hairlinesAtSamePoint.length === 0) {
          h.push({xval: searchPoint.xval, type: hairlineTypes.ADVANCED_SEARCH_HAIRLINE, channelId: channel[0].channelId});
        }
        graphSearchHairlines.push(searchPoint);
      }
    })
  }

  this.dygraph.advancedSearchPoints = graphSearchHairlines;

  this.hairlines.set(h);
}

common.resetHairlines = function (hairlineType) {
  let h = this.hairlines.get()
  let selectedHairlines = copy(getHairlinesByType(hairlineType)(h));
  selectedHairlines.forEach((hairline) => {
    const hairlineIndex = R.indexOf(hairline, h);
    if (hairlineIndex !== -1) {
      h.splice(hairlineIndex, 1);
    }
  });
  this.hairlines.set(h);

  if (hairlineType === hairlineTypes.CHANNEL_HAIRLINE) {
    this.props.turnOffClearChannelHairlines();
  }
};

//
// The common object above is not exported as each graph component needs its own version of common but with the methods bound to that component.
//
// This graphComponentsCommon object CONTAINS NO functions that contain the keyword "this".
// Functions which were methods of a graph component should be defined against common.
//

const graphComponentsCommon = {};

graphComponentsCommon.generateBoundCommon = function (newThisValue) {
  const boundCommon = {};
  const methodsToBind = Object.getOwnPropertyNames(common).filter(x => typeof(common[x]) == 'function');

  for (let i = 0; i < methodsToBind.length; i++) {
    boundCommon[methodsToBind[i]] = common[methodsToBind[i]].bind(newThisValue);
  }

  return boundCommon;
};

// DC: Originally I created this as a function which returned the object. However, whereas the linter is capable of using the object below in places where it
//     is used, I don't think it is capable of doing such a thing via a function call, resulting in it flagging lots of "is missing in props validation" errors.
//     The code itself was not incorrect or badly formatted, just that the linter was not capable enough.
graphComponentsCommon.standardPropTypes = {
  actions: PropTypes.object.isRequired,
  clickingAddsDistanceHairline: PropTypes.bool,
  turnOffDistanceHairlines: PropTypes.func.isRequired,
  clickingAddsChannelHairline: PropTypes.bool,
  clearChannelHairlines: PropTypes.bool.isRequired,
  turnOffChannelHairlines: PropTypes.func.isRequired,
  turnOffClearChannelHairlines: PropTypes.func.isRequired,
  graphSettings: PropTypes.object.isRequired,
  graphId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showGraphDataFetchingError: PropTypes.func.isRequired,
  showVehicleDoesntExistError: PropTypes.func.isRequired,
  distances: PropTypes.array,
  distanceHairlines: PropTypes.array,
};

// graphComponentsCommon.defaultDblclick = functions.debounce(Dygraph.defaultInteractionModel.dblclick);
graphComponentsCommon.defaultDblclick = Dygraph.defaultInteractionModel.dblclick;

graphComponentsCommon.generateDistanceHairlineHtml = function (data, graphHairlines, dygraph) {
  let distanceValue = 0;

  const distanceHairlines = getHairlinesByType(hairlineTypes.DISTANCE_HAIRLINE)(graphHairlines);
  const timeOrderedHairlines = R.sortBy(R.prop('xval'))(distanceHairlines);
  const currentOrderedIndex = R.indexOf(data.hairline, timeOrderedHairlines);

  if (currentOrderedIndex > 0) {
    distanceValue = distanceUtils.getDistanceBetweenTwoPoints(data.hairline.xval, timeOrderedHairlines[0].xval);
  }

  let html = '<div>';
  html += `<div><span>${distanceValue.toLocaleString(undefined, {maximumFractionDigits: 2})}m</span></div>`;
  if (currentOrderedIndex > 0) {
    const distanceValueInMiles = distanceValue / distanceUtils.metresInMile;
    html += `<div><span>${distanceValueInMiles.toLocaleString(undefined, {maximumFractionDigits: 2})} miles</span></div>`;
  }
  html += "</div>";
  html += graphComponentsCommon.getHairlineLatitudeLongitude(data, dygraph);
  return html;
};

graphComponentsCommon.generateHairlineHtml = function (data, dygraph) {
  const res = Resources.localizedString;
  let html = '<div>';

  data.points.forEach((point) => {
    const channel = getChannelByShortName(point.name, dygraph.channels);
    //channel not added to dygraphs channel array yet
    if (channel) {
      const channelColour = getChannelColour(channel);
      let value = channelValueTypes.EMPTY_CHANNEL_VALUE;

      let graphChannelIndex = dygraph.indexFromSetName(point.name);
      let prevPointIndex = R.findLastIndex(x => x[0] <= point.xval && x[graphChannelIndex] != null)(dygraph.rawData_);

      if(prevPointIndex != -1) {
        let prevPoint = dygraph.rawData_[prevPointIndex];

        let previousPointValue = prevPoint[graphChannelIndex]

        if (previousPointValue) {
          if (channel.channelType === channelTypes.DIGITAL_CHANNEL) {
            const channelIndex = R.indexOf(channel, dygraph.channels);
            const unroundedValue = (previousPointValue[1] - (dygraph.channels.length - channelIndex - 1));
            const roundedValue = Math.round(unroundedValue);
            // return roundedValue > 0 ? 'ON' : 'OFF';
            value = roundedValue > 0
                    ? res.on.toUpperCase()
                    : res.off.toUpperCase();
          } else {
            value = parseFloat(previousPointValue[1]).toFixed(2);
          }
        }
      }

      html += `<div class="hairline__channel"><span class="hairline__channel-colour" style="background-color: ${channelColour};">${point.name}</span><span>${value}</span></div>`;
    }
  });
  html += "</div>";

  html += graphComponentsCommon.getHairlineLatitudeLongitude(data, dygraph);
  return html;
};

graphComponentsCommon.getHairlineLatitudeLongitude = (data, dygraph) => {
  let html = '';
  if (dygraph.latLongData && data.points && data.points.length) {
    const prevLatLongPointIndex = R.findLastIndex(x => x.rmdDateTime <= data.points[0].xval)(dygraph.latLongData);
    if (prevLatLongPointIndex != -1 && dygraph.latLongData[prevLatLongPointIndex].latitude != 0 && dygraph.latLongData[prevLatLongPointIndex].longitude != 0) {
      const prevPoint = dygraph.latLongData[prevLatLongPointIndex];
      html += `<a class="hairline-info__lat-long-link" href="https://www.google.com/maps/search/?api=1&query=${prevPoint.latitude},${prevPoint.longitude}" target="_blank" rel="noopener">${Resources.localizedString.viewOnMap}</a>`;
    }
  }
  return html;
}

graphComponentsCommon.generateEventHairlineHtml = function (data, dygraph) {
  let html = `<div><span>${dygraph.event.reference}</span></div>`;
  html += graphComponentsCommon.getHairlineLatitudeLongitude(data, dygraph);
  return html;
};

graphComponentsCommon.generateAdvancedSearchHairlineHtml = function (data, dygraph) {
  const res = Resources.localizedString;
  let value = channelValueTypes.EMPTY_CHANNEL_VALUE;
  let html = '<div>';
  html += `<div><span>${moment(data.hairline.xval).format(res.fullDateTimeFormat).toString()}</span></div>`;
  data.points.forEach((point) => {
    const channel = getChannelByShortName(point.name, dygraph.channels);

    // channel not added to dygraphs channel array yet
    // Need to check if in the array of search points
    if (channel && dygraph.advancedSearchPoints.filter(sp => sp.channelId === channel.channelId && sp.xval === point.xval).length > 0) {

      let graphChannelIndex = dygraph.indexFromSetName(point.name);
      let prevPointIndex = R.findLastIndex(x => x[0] <= point.xval && x[graphChannelIndex] != null)(dygraph.rawData_);

      if(prevPointIndex !== -1) {
        let prevPoint = dygraph.rawData_[prevPointIndex];
        let previousPointValue = prevPoint[graphChannelIndex]

        if (previousPointValue) {
          if (channel.channelType === channelTypes.DIGITAL_CHANNEL) {
            const channelIndex = R.indexOf(channel, dygraph.channels);
            const unroundedValue = (previousPointValue[1] - (dygraph.channels.length - channelIndex - 1));
            const roundedValue = Math.round(unroundedValue);
            value = roundedValue > 0
                    ? res.on.toUpperCase()
                    : res.off.toUpperCase();
          } else {
            value = parseFloat(previousPointValue[2]).toFixed(2);
          }
        }

        html += `<div><span>${channel.name}: ${value}</span></div>`;
      }
    }
  });

  html += "</div>";
  html += graphComponentsCommon.getHairlineLatitudeLongitude(data, dygraph);
  return html;
};

graphComponentsCommon.generateTriggerHairlineHtml = function (data, dygraph) {
  let html = `<div><span>${dygraph.triggerProblemState.name}</span></div>`;
  html += graphComponentsCommon.getHairlineLatitudeLongitude(data, dygraph);
  return html;
};

graphComponentsCommon.generateUnderlayCallback = function (chartMissingStartDataInterval, chartMissingEndDataInterval) {
  return (canvas, area, g) => {
    underlayFunctions.addUnderlayForTimeInterval(canvas, area, g, chartMissingStartDataInterval);
    underlayFunctions.addUnderlayForTimeInterval(canvas, area, g, chartMissingEndDataInterval);
  };
};

export default graphComponentsCommon;
