import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as graphSettingsActions from 'actions/graphSettingsActions';
import {PropTypes} from 'prop-types';
import {zoomButtons} from 'utilities/graphZoom';
import functions from 'utilities/functionUtilities';
import dateTime from 'utilities/dateTimeUtilities';
import 'styles/graph-page.scss';
import Resources from 'utilities/Resources';

const zoomInStepPercentage = 20;

class ZoomLevel extends React.Component {
  static propTypes = {
    graphSettingsActions: PropTypes.object.isRequired,
    graphSettings: PropTypes.object.isRequired,
    additionalClassName: PropTypes.string,
  };

  onZoomInClick = functions.debounce(() => {
    const newDetailRange = zoomButtons.getDetailRangeForNextLevelZoomIn(this.props.graphSettings, zoomInStepPercentage);
    this.props.graphSettingsActions.updateRangeDetailSettings(...newDetailRange);
  });

  onZoomOutClick = functions.debounce(() => {
    const newDetailRange = zoomButtons.getDetailRangeForNextLevelZoomOut(this.props.graphSettings, zoomInStepPercentage);
    this.props.graphSettingsActions.updateRangeDetailSettings(...newDetailRange);
  });
  
  render() {
    const resources = Resources.localizedString;

    const containerClassName = `zoom-play-area ${this.props.additionalClassName}`;

    let zoomOutCssClass = 'zoom-button zoom-out';
    let zoomOutOnClick = this.onZoomOutClick;

    if (this.detailRangeMatchesMainRange()) {
      zoomOutCssClass += ' disabled';
      zoomOutOnClick = undefined;
    }

    return (
      <div className={containerClassName}>
        <div className='zoom-area'>
          <button className={zoomOutCssClass} onClick={zoomOutOnClick}></button>
          <span className="detail-resolution">{resources.zoomLevelLabels[this.props.graphSettings.detailResolution]}</span>
          <button className="zoom-button zoom-in" onClick={this.onZoomInClick}></button>
        </div>
      </div>
    );
  }

  detailRangeMatchesMainRange = () =>
    dateTime.getToNearestSecond(this.props.graphSettings.detailRangeStart) <= dateTime.getToNearestSecond(this.props.graphSettings.rangeStart) &&
    dateTime.getToNearestSecond(this.props.graphSettings.detailRangeEnd) >= dateTime.getToNearestSecond(this.props.graphSettings.rangeEnd);
}

function mapStateToProps(state) {
  return {
    graphSettings: state.graphSettings,
  };
  // If do anything expensive in here, introduce memoization using a library like Reselect.
}

function mapDispatchToProps(dispatch) {
  return {
    graphSettingsActions: bindActionCreators(graphSettingsActions, dispatch)
  };
}

export {ZoomLevel as ZoomLevelNoRedux};

const ConnectedZoomLevel = connect(mapStateToProps, mapDispatchToProps)(ZoomLevel);
export default ConnectedZoomLevel;
