import React from 'react';
import {PropTypes} from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as dialogueActions from 'actions/dialogueActions';
import ExportDialogueContent from 'components/ExportDialogueContent';
import ExportDialogueContentCompleted from 'components/ExportDialogueContentCompleted';
import Modal from 'react-modal';
import 'styles/_dialogue.scss';

Modal.setAppElement('#app')

class ExportDialogue extends React.Component {
  static propTypes = {
    exportDialogueShow: PropTypes.bool.isRequired,
    dialogueActions: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.defaultModalHeight = 530;

    this.state = {
      pdfExportRequested: false,
      requested: false,
      exportType: null,
      modalHeight: this.defaultModalHeight
    };

    this.initialState = this.state;
  }

  onRequested = (requested, exportType, response) => {
    this.setState({
      exportType,
      requested,
      response
    });
  };

  onClose = () => {
    if (!this.state.pdfExportRequested) {
      this.props.dialogueActions.closeExportDialogue();
      this.setState(this.initialState);
    }
  }

  onExportTypeChange = (exportType) =>{
    let modalHeight = this.defaultModalHeight;

    if (exportType == 'pdf') {
      modalHeight = 450
    }

    this.setState({
      exportType,
      modalHeight
    });
  }

  setPdfExportRequested = (requested) =>{
    this.setState({
      pdfExportRequested: requested
    });
  }

  render() {
    let content;
    if (!this.state.requested) {
      content = <ExportDialogueContent onExportTypeChange={this.onExportTypeChange} onRequested={this.onRequested} setPdfExportRequested={this.setPdfExportRequested} onClose={this.onClose} />;
    } else {
      content = <ExportDialogueContentCompleted exportType={this.state.exportType} response={this.state.response} onClose={this.onClose} />;
    }

    return(
      <Modal
        className={"dialogue"}
        contentLabel="Export Modal"
        onRequestClose={this.onClose}
        onClose={this.onClose}
        isOpen={this.props.exportDialogueShow}
        style={{
          content: {
            width: 1150, 
            height: this.state.modalHeight
          }
        }}>
          {content}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    exportDialogueShow: state.exportDialogue.show
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dialogueActions: bindActionCreators(dialogueActions, dispatch)
  };
}

export {ExportDialogue as ExportDialogueNoRedux};

const ConnectedExportDialogue = connect(mapStateToProps, mapDispatchToProps)(ExportDialogue);
export default ConnectedExportDialogue;