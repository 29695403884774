import axios from 'axios';
import rddApiConfig from './rddApiConfig';
// "ReferenceError: regeneratorRuntime is not defined" when used async/await: https://github.com/babel/babel/issues/5085
// in the form "async => await () => ..."
// This page suggests it can be done without including the polyfill: https://www.valentinog.com/blog/how-async-await-in-react/
import '@babel/polyfill';

const distanceApi = {};

// https://medium.com/codingthesmartway-com-blog/getting-started-with-axios-166cb0035237
// https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index
distanceApi.getByVehicleCode = async (vehicleCode, startDate, endDate, resolution, baseDistanceValue, wheelDiameter) => {
  const response = await axios.get(`distance/${vehicleCode}?startDateTime=${startDate}&endDateTime=${endDate}&resolution=${resolution}&baseDistanceValue=${baseDistanceValue}&userAdjustedWheelDiameterMillimetres=${wheelDiameter}`, rddApiConfig);
  return response.data;
}

export default distanceApi;
