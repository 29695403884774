import axios from 'axios';
import rddApiConfig from './rddApiConfig';
// "ReferenceError: regeneratorRuntime is not defined" when used async/await: https://github.com/babel/babel/issues/5085
// in the form "async => await () => ..."
// This page suggests it can be done without including the polyfill: https://www.valentinog.com/blog/how-async-await-in-react/
import '@babel/polyfill';

const distributionListApi = {}

// https://medium.com/codingthesmartway-com-blog/getting-started-with-axios-166cb0035237
// https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index

// triggersApi.postTriggersGrid = async () => {
//     const response = await axios.put('trigger/getTriggersGrid', requestBody ,rddApiConfig);
//     return response.data;
//   }

distributionListApi.getAll = async () => {
    const response = await axios.get('distribution-list', rddApiConfig);
    return response.data;
}
  
export default distributionListApi;