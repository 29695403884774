import presetsApi from 'api/presetsApi';
import * as types from 'constants/actionTypes';

export function setSaveNew (saveNew) {
  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_PRESET_SAVE_FLAG,
      saveNew
    });
  };
}

export function setSaved (saved) {
  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_PRESET_SAVED_FLAG,
      saved
    });
  };
}

export function setSelectedId (presetId) {
  return function (dispatch) {
    return dispatch({
      type: types.UPDATE_SELECTED_PRESET_ID,
      selectedId: presetId
    });
  };
}

function fetchPresetsDataSuccess(presets) {
  return {type: types.FETCH_PRESETS_DATA_SUCCESS, presets};
}

export function setPresets (presets) {
  return function (dispatch) {
    return dispatch(fetchPresetsDataSuccess(presets));
  };
}

export function getAllPresets() {
  return async function (dispatch) {
    try {
      const presets = await presetsApi.get();
      return dispatch(fetchPresetsDataSuccess(presets));
    }
    catch (error) {
      // alert(error);
      throw error;
    }
  };
}