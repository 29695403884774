import * as React from 'react'
import {PropTypes} from 'prop-types';
import SelectedGraphChannel from './SelectedGraphChannel';
import * as dragDropTypes from 'constants/dragDropTypes';
import { DropTarget } from 'react-dnd';
import {copy} from 'utilities/graphSettings';

const graphTarget = {
  canDrop(props) {
    return (props.graph.channels.length < props.graph.maxChannels);
  },
  drop(props, monitor) {
    // Obtain the dragged item
    const item = monitor.getItem();

    // You can do something with it
    props.onDrop(item);
  }
};

/**
 * Specifies which props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}

class GraphSelected extends React.Component {
  static propTypes = {
    onChannelColourChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    onMove: PropTypes.func.isRequired,
    graph: PropTypes.object.isRequired,
    canDrop: PropTypes.bool.isRequired,
    connectDropTarget: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      channels: copy(this.props.graph.channels)
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.graph.channels) !== JSON.stringify(prevProps.graph.channels)) {
      this.setState({
        channels: copy(this.props.graph.channels)
      })
    }
  }
  
  showChannelData = () => {
    let channels = [];
    // Need to set the channels per graph as setting somewhere
    for (var i = 0; i < this.props.graph.maxChannels; i++) {
      if (this.state.channels[i])  {
        channels.push(
          <SelectedGraphChannel index={i} key={i} channel={this.state.channels[i]} moveChannel={this.moveChannel} onChannelColourChange={this.props.onChannelColourChange}
                                onRemove={this.props.onRemove} graphId={this.props.graph.graphId} />
        );
      } else {
        channels.push(<div key={i} className="selected-channels-drop__slot"></div>);
      }
    }
    return channels
  };

  moveChannel = (dragIndex, hoverIndex) => {
    let channels = this.state.channels;
    const dragChannel = channels[dragIndex]
    channels.splice(dragIndex, 1); // removing what you are dragging.
    channels.splice(hoverIndex, 0, dragChannel); // inserting it into hoverIndex.
    this.setState({
      channels
    }, () => {
      this.props.onMove(this.state.channels);
    });
  }

  clear = () => this.props.onClear(this.props.graph.graphId);

  render() {
    const connectDropTarget = this.props.connectDropTarget;
    const canDrop = this.props.canDrop;

    return (
      connectDropTarget &&
      connectDropTarget(
        <div className="selected-channels-drop__wrapper">
          <button className="link-button selected-channels-drop__clear-graph-channels right half-margin-top" onClick={this.clear}>Clear All</button>
          <span className="label">Graph {this.props.graph.graphId}</span>
          <div className={"selected-channels-drop selected-channels-drop--analogue" + (canDrop ? ' selected-channels-drop--highlight' : '')}>
            {this.showChannelData()}
          </div>
        </div>
      )
    )
  }
}

export default DropTarget([dragDropTypes.UNSELECTED_CHANNEL, dragDropTypes.SELECTED_CHANNEL], graphTarget, collect)(GraphSelected);
