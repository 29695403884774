
    import * as actionTypes from 'constants/actionTypes';
    import objectAssign from 'object-assign';
    import initialState from './initialState';
    
    // IMPORTANT: Note that with Redux, state should NEVER be changed.
    // State is considered immutable. Instead,
    // create a copy of the state passed and set new values on the copy.
    // Note that I'm using Object.assign to create a copy of current state
    // and update values on the copy.
    export default function triggersReducer(state = initialState.triggers, action) {
    switch (action.type) {

      case actionTypes.UPDATE_TRIGGERS_SAVED_FLAG:
        return objectAssign({}, state, {saved: action.saved});

      case actionTypes.FETCH_TRIGGERS_DATA_SUCCESS:
        return objectAssign({}, state, {triggers: action.triggers});    
      default:
        return state;
    }
}