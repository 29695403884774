import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dialogueActions from 'actions/dialogueActions';
import ShareDialogueContent from 'components/ShareDialogueContent'
import ShareDialogueContentFailed from 'components/ShareDialogueContentFailed'
import Modal from 'react-modal';
import 'styles/_dialogue.scss';

Modal.setAppElement('#app')

class ShareDialogue extends React.Component {
  static propTypes = {
    shareDialogueShow: PropTypes.bool.isRequired,
    dialogueActions: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
        response: null,
    };

    this.initialState = this.state;
  }

  onRequested = (response) => {
    this.setState({
        response
    });
  }

  onClose = () => {
    this.props.dialogueActions.closeShareDialogue();
    this.setState(this.initialState);
  }

  render = () => {
    let content = <ShareDialogueContent onClose={this.onClose} onRequested={this.onRequested} />;

    if (this.state.response && this.state.response.error) {
        content = <ShareDialogueContentFailed onClose={this.onClose} />;
    }

    return (
      <Modal
        className={"dialogue"}
        contentLabel="Share"
        onRequestClose={this.onClose}
        onClose={this.onClose}
        isOpen={this.props.shareDialogueShow}
        style={{
          content: {
            width: 950,
            height: 320,
          }
        }}>
        {this.props.shareDialogueShow ?
          content : null}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    shareDialogueShow: state.shareDialogue.show
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dialogueActions: bindActionCreators(dialogueActions, dispatch)
  }
}

export { ShareDialogue as ShareDialogueNoRedux }

const ConnectedShareDialogue = connect(mapStateToProps, mapDispatchToProps)(ShareDialogue);
export default ConnectedShareDialogue;
