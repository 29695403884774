import * as R from 'ramda';
import qs from 'query-string'

const queryStrings = {};

queryStrings.getPropertyArrays = location => {
  // Would have used queryStringPropertyArrays but that adds more trees to the wood. It is an array of arrays, with with child arrays having two entries.
  let qs = location.search.replace('?', '').split('&');

  // If there are no query string parameters we will have an array containing one empty string.
  if (qs[0] == '') {
    qs.shift();
  }

  for (let i = 0; i < qs.length; i++) {
    qs[i] = qs[i].split('=');
  }

  return qs;
};

queryStrings.getValue = (propertyName, queryStringPropertyArrays) => {
  const matchingArrayItem = R.find(x => x[0].toLowerCase() == propertyName.toLowerCase())(queryStringPropertyArrays);
  return matchingArrayItem && matchingArrayItem[1];
};

queryStrings.removeFromPropertyArrays = (propertyName, queryStringPropertyArrays) => {
  const propertyArraysWithoutProperty = R.reject(x => x[0].toLowerCase() == propertyName.toLowerCase())(queryStringPropertyArrays);
  return propertyArraysWithoutProperty;
};

queryStrings.generateQueryStringSegmentFromPropertyArrays = queryStringPropertyArrays => {
  if (queryStringPropertyArrays && queryStringPropertyArrays.length > 0) {
    return '?' + R.map(x => x.join('='), queryStringPropertyArrays).join('&');
  }

  return '';
};

// NOTE: The properties of the object returned will be COMPLETELY in lower case.
queryStrings.parseFromCurrentLocation = () => qs.parse(window.location.search.toLowerCase());

queryStrings.replaceUrlSegmentFromPropertyArrays = (location, queryStringPropertyArrays) => {
  // Remove the existing query string segment.
  const baseUrl = window.location.href.replace(location.search, '');
  const redirectUrl = baseUrl + queryStrings.generateQueryStringSegmentFromPropertyArrays(queryStringPropertyArrays);
  return redirectUrl;
};

export default queryStrings;
