import React from 'react';
import AdminNavigation from 'components/AdminNavigation';
import Header from 'components/Header';
import Footer from 'components/Footer';

const PageLayout = (WrappedComponent) => {

  const NewComponent = props => {
    const {...passThroughProps} = props;

    return (
      <React.Fragment>
        <Header />
        <AdminNavigation />
        <WrappedComponent {...passThroughProps} />
        <Footer />
      </React.Fragment>
    );
  };
  return NewComponent;
};

export default PageLayout;
