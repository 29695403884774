import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import TriggerGrid from 'components/TriggerGrid';
import PageLayout from 'components/PageLayout';
import {Link} from 'react-router-dom';
import {PropTypes} from 'prop-types';
import Resources from 'utilities/Resources';
import routes from 'routes';
import * as triggerActions from "actions/triggerActions";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/_toast-notifications.scss';
import {Slide} from 'utilities/reactToastifyHelpers';

class TriggerPage extends React.Component {
  static propTypes = {
    triggerActions: PropTypes.object.isRequired,
    savedTrigger: PropTypes.bool
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      requested: false,
      exportType: null,
      open: true
    };

    this.resources = Resources.localizedString;
    this.addEditTriggerUrl = routes.admin.triggers.addEditTrigger.replace('/:triggerId?', '');
    {this.props.savedTrigger && this.showTriggerSavedNotification()}
  }

  onClose = () => {
    this.setState({
      open: false
    });
  }

  showTriggerSavedNotification = () => {
    toast(this.resources.triggerSuccessfullySaved, {
      type: toast.TYPE.SUCCESS,
      onClose: this.props.triggerActions.setSaved(false)
    });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer autoClose={6000} hideProgressBar={true} transition={Slide} newestOnTop={true} />
        <div className="padded--double">
          <h1>
            <Link to={this.addEditTriggerUrl} className="button right">Create Trigger</Link>
            <span>{this.resources.triggers}</span>
          </h1>
          <TriggerGrid />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    savedTrigger: state.triggers.saved
  };
}

function mapDispatchToProps(dispatch) {
  return {
    triggerActions: bindActionCreators(triggerActions, dispatch)
  };
}

export {TriggerPage as TriggerPageNoRedux};

const ConnectedTriggerPage = connect(mapStateToProps, mapDispatchToProps)(TriggerPage);
export default PageLayout(ConnectedTriggerPage);
