import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as graphHairlinesActions from 'actions/graphHairlinesActions';
import * as graphSettingsActions from 'actions/graphSettingsActions';
import * as sidebarActions from  'actions/sidebarValuesActions';
import * as searchActions from 'actions/advancedSearchActions';
import {getChannelColours} from 'utilities/graphSettings';
import * as R from 'ramda';
import graphComponentsCommon from 'utilities/graphComponentsCommon';
import NoDataPanel from 'components/NoDataPanel';
import Resources from 'utilities/Resources';

class AnalogueGraph extends React.Component {
  static propTypes = graphComponentsCommon.standardPropTypes;

  constructor(props) {
    super(props);

    this.common = graphComponentsCommon.generateBoundCommon(this);

    this.common.setPropertiesOfThis();

    this.dygraphOptions = {
      ...this.common.getCommonDygraphOptions(),
      height: 255,
    };

    this.state = {
      graph: R.find(R.propEq('graphId', this.props.graphId))(this.props.graphSettings.analogueGraphs),
      requestHasData: true
    };
    this.requestHasData = true;
  }

  async componentDidMount() {
    await this.common.componentDidMount({isDigitalGraph: false});
  }

  componentDidUpdate(prevProps) {
    this.common.componentDidUpdate(prevProps);
  }

  componentWillUnmount() {
    this.common.componentWillUnmount();
  }

  async getDataPointsFromApi(startDateTime, endDateTime) {
    const [request, channelsChartData, rawChartData, chartMissingStartDataInterval, chartMissingEndDataInterval, requestHasData] =
      await this.common.requestAndProcessDataPointsFromApi(startDateTime, endDateTime, this.props.graphSettings.detailResolution);

    this.chartData = channelsChartData;
    this.setState({
      requestHasData
    });

    this.dygraphOptions = {
      ...this.dygraphOptions,
      title: `${Resources.localizedString.graph} ${this.props.graphId}`,
      labels: ['Time', ...R.pluck('shortName')(this.state.graph.channels)],
      colors: getChannelColours(this.state.graph.channels),
      file : channelsChartData,
      dateWindow: [startDateTime.valueOf(), endDateTime.valueOf()],
      underlayCallback: graphComponentsCommon.generateUnderlayCallback(chartMissingStartDataInterval, chartMissingEndDataInterval),
    };

    this.common.updateDygraphAfterSuccessfullyFetchingDataPoints(request, rawChartData, this.dygraphOptions);
  }

  render() {
    return (
      <div className="graph-container">
        <div id="hairline-template" className="hairline-info hairline-info--channel" style={{display: 'none'}}>
          <button className="hairline-kill-button">Remove</button>
          <div className="hairline-legend"></div>
        </div>
        <div id="hairline-template-distance" className="hairline-info hairline-info--distance" style={{display: 'none'}}>
          <button className="hairline-kill-button">Remove</button>
          <div className="hairline-legend"></div>
        </div>
        <div id="hairline-template-event" className="hairline-info hairline-info--event" style={{display: 'none'}}>
          <button className="hairline-kill-button">Remove</button>
          <div className="hairline-legend"></div>
        </div>
        <div id="hairline-template-trigger" className="hairline-info hairline-info--trigger" style={{display: 'none'}}>
          <button className="hairline-kill-button">Remove</button>
          <div className="hairline-legend"></div>
        </div>
        <div id="hairline-template-search" className="hairline-info hairline-info--search" style={{display: 'none'}}>
          <button className="hairline-kill-button">Remove</button>
          <div className="hairline-legend"></div>
        </div>
        <div id={"graphdiv-" + this.props.graphId} className="graph-div"></div>
        <div id={this.graphContainerId + "-loading"} className="graph-loading"></div>
        {!this.state.requestHasData ?
          <NoDataPanel />
        : null}
      </div>
    );
  }
}

// function mapStateToProps(state, ownProps) {
function mapStateToProps(state) {
  return {
    // DC: This will give the component the "this.props.graphSettings" property.
    graphSettings: state.graphSettings,
    graphHairlines: state.graphHairlines,
    distances: state.distances
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...graphSettingsActions, ...sidebarActions, ...searchActions, ...graphHairlinesActions}, dispatch)
  };
}

// DC: I think this is needed only if we want to write Jest tests for the component.
export {AnalogueGraph as AnalogueGraphNoRedux};

// These names used in redux docs but could use different ones.
export default connect(mapStateToProps, mapDispatchToProps)(AnalogueGraph);
